import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import { Pane, Heading, Label, TextInput, Button, TagInput, SelectMenu, toaster, Text, SelectMenuItem, SideSheet } from 'evergreen-ui'

import { Card, Icon, Spinner } from '@pearly/lib'
import { useLocation, useHistory } from 'react-router-dom'
import ReactQuill from 'react-quill';
import { useModal } from 'components/modal-provider'
import { useMutation, useQuery } from '@apollo/react-hooks'
import * as Types from 'types/graphql'
import { GET_USERS_TABLE } from 'graphql/_users-table'
import { EmailCategoryType } from 'types'
import { useGlobal } from 'components/global-provider'
import moment from 'moment'
import { GET_VIDEOS } from 'graphql/_video'
import { GET_FLYERS } from 'graphql/_flyer'
import cuid from 'cuid'
import { SEND_NEWSLETTER_EMAIL } from 'graphql/_newsletters'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  newsletterData: {
    id?: String;
    subject: String;
    body: String;
    status: String;
    to: Array<string>;
    files: string | null;
  }
}

const TOVARIABLES = [
  { label: 'Admins of All Practices', value: '#ALLPRACTICEADMINS#' },
  { label: 'Standard Users  of All Practices ', value: '#ALLPRACTICESTANDARDADMINS#' },
  { label: 'Admins of All Practices with ACTIVE Subscription', value: '#ALLACTIVESUBSCRIPTIONPRACTICEADMINS#' },
  { label: 'Standard Users of All Practices with ACTIVE Subscription', value: '#ALLACTIVESUBSCRIPTIONPRACTICESTANDARDADMINS#' },
  { label: 'Admins of All Practices with No Subscription/No ACTIVE Subscription', value: '#ALLNOSUBSCRIPTIONPRACTICEADMINS#' },
  { label: 'Standard Users of All Practices with No Subscription/No ACTIVE Subscription', value: '#ALLNOSUBSCRIPTIONPRACTICESTANDARDADMINS#' },
]

const EmailNewsletterSheet = ({ isShown, setIsShown, newsletterData }: Props) => {
  const location = useLocation()
  const history = useHistory()
  const quillref = useRef<any>(null)
  const emailHelpSheet = useModal('emailHelp')
  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM
  const isSuperAdmin = (global.meUser?.google.role === Types.UserRole.SUPERADMIN)

  const category = isSystemAccount ? location.pathname.split('/')[2].toLocaleUpperCase() : EmailCategoryType.EXTERNAL;

  const [body, setBody] = React.useState<any>('')
  const [isErrors, setIsErrors] = React.useState<any>({})
  const [emailData, setEmailData] = React.useState<any>({
    id: '',
    subject: "",
    description: "",
    body: "",
    status: "",
  })

  const [selectedTo, setSelectedTo] = React.useState<string[]>([])
  // const [selectedCC, setSelectedCC] = React.useState<string[]>([])
  // const [selectedBCC, setSelectedBCC] = React.useState<string[]>([])
  const [selectedAttachments, setSelectedAttachments] = React.useState<any[]>([])
  const [resourceVideos, setResourceVideos] = React.useState<any[]>([])
  const [resourceFlyers, setResourceFlyers] = React.useState<any[]>([])
  const [files, setFiles] = React.useState<any[]>([])
  const [isViewMode, setIsViewMode] = React.useState<boolean>(false)

  const { data: userData, error: userError, networkStatus: userNetworkStatus, loading: userLoading } = useQuery<Types.UsersTable>(GET_USERS_TABLE)

  const { data: videos } = useQuery<Types.GetVideos>(GET_VIDEOS, {
    variables: { status: Types.VideoStatus.ACTIVE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const { data: flyersData } = useQuery<Types.flyers>(GET_FLYERS, {
    variables: { status: Types.VideoStatus.ACTIVE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const [users, setUsers] = React.useState<any[]>([])

  useEffect(() => {
    if (newsletterData) {
      setEmailData(newsletterData)
      setSelectedTo(newsletterData.to)
      setBody(newsletterData.body)
      if (newsletterData.files) {
        const parsedFiles = JSON.parse(newsletterData.files)
        if (parsedFiles && parsedFiles.length) {
          setSelectedAttachments(parsedFiles.map((file: any) => ({
            label: file.name,
            value: file.url,
            status: file.status,
          })))
        }
      }
      if (newsletterData.id && newsletterData.status === Types.NewsletterStatus.SENT) {
        setIsViewMode(true)
      }
    }
  }, [newsletterData])

  useEffect(() => {
    if (userData && userData?.users) {
      setUsers(userData?.users?.map((user) => ({
        label: user?.firstName + " " + user?.lastName + " (" + user?.google?.email + ")",
        value: user?.google?.email
      })))
    }
  }, [userData])

  useEffect(() => {
    if (videos && videos.videos && videos.videos.length) {
      setResourceVideos(videos.videos.map((vid) => ({
        label: vid.title,
        value: vid.url,
        status: vid.status
      })))
    }
  }, [videos])

  useEffect(() => {
    if (flyersData && flyersData.flyers && flyersData.flyers.length) {
      setResourceFlyers(flyersData.flyers.map((flyer) => ({
        label: flyer.title,
        value: flyer.url,
        status: flyer.status
      })))
    }
  }, [flyersData])

  useEffect(() => {
    if (selectedAttachments.length) {
      setFiles(selectedAttachments.filter((attachment: any) => {
        if (attachment.status === Types.EmailAttachmentsStatus.ACTIVE) {
          return attachment
        }
      }).map((item: any) => ({
        id: item.id ? item.id : cuid(),
        url: item.value,
        name: item.label,
        status: item.status,
      })))
    }
  }, [selectedAttachments])

  const findSelectedCount = (data: any[]) => {
    const count = data.filter(x => users.map(x => x.value).indexOf(x) !== -1).length
    return count > 0 ? `${count} selected` : 'System Users'
  }

  const findSelectedToCount = (data: any[]) => {
    const count = data.filter(x => TOVARIABLES.map(x => x.value).indexOf(x) !== -1).length
    return count > 0 ? `${count} selected` : 'Recipients'
  }

  const findAttachmentSelectedCount = (data: any[]) => {
    const count = data.length
    return count > 0 ? `${count} selected` : 'Attachment'
  }

  const hendalChange = (value: any, name: any) => {
    setEmailData({ ...emailData, [name]: value })
  }

  const validation = () => {
    let flag = true;
    let errors: any = {}
    if (!selectedTo.length) {
      errors["to"] = "please enter To";
      flag = false
    }
    if (!emailData?.subject) {
      errors["subject"] = "please enter subject";
      flag = false
    }
    if (!body) {
      errors["body"] = "please enter body";
      flag = false
    }
    setIsErrors(errors)
    return flag
  }

  const [
    sendEmail,
    {
      data: sendEmailData,
      error: sendEmailError,
      loading: sendEmailLoading,
    }
  ] = useMutation<Types.SendNewsletterEmail, Types.SendNewsletterEmailVariables>(SEND_NEWSLETTER_EMAIL, {
    fetchPolicy: "no-cache",
    refetchQueries: ['GetNewsletters']
  })

  useEffect(() => {
    if (sendEmailError) toaster.danger(`Unable to send email`)
    else if (sendEmailData && !sendEmailLoading) {
      if(sendEmailData.sendNewsletterEmail?.status === Types.NewsletterStatus.DRAFT){
        toaster.success(`Email saved as a Draft!`)
      } else {
        toaster.success(`Email sent successfully!`)
      }
      setIsShown(false)
    }
  }, [sendEmailData, sendEmailError, sendEmailLoading])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={800} shouldCloseOnOverlayClick={false}>
      {userLoading ?
        <Pane height={84} paddingY={24}>
          < Spinner delay={0} />
        </Pane > :
        <SheetLayout>
          <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
            <Pane display="flex" alignItems="center" width="70%">
              <Pane marginLeft={16}>
                <Heading size={600}>
                  {newsletterData && newsletterData.id ? 'View Newsletter' : 'Compose Newsletter'}
                </Heading>
                {/* <Text size={400}>{emailData?.emailId}: {emailData?.action}</Text> */}
              </Pane>
            </Pane>
            {emailData.sentDate && <Text size={500}>
              <strong>Sent on: </strong>
              {moment(emailData.sentDate).format("M/D/YYYY")}
            </Text>}
          </Pane>
          <Pane gridArea="body" overflow="scroll" background="blueTint">
            <Card backgroundColor="white" elevation={0} margin={16} padding={20}>
              <>
                <Pane marginTop={15} width="100%">
                  <Label width="10%">To</Label>
                  <Pane display='flex'>
                  <TagInput
                    disabled={!isSuperAdmin || isViewMode}
                    width="100%"
                    tagProps={(value: any) => {
                      if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) return { color: 'orange' }
                      return {}
                    }}
                    inputProps={{ placeholder: 'To' }}
                    values={selectedTo}
                    onAdd={(newValues) => {
                      if (category === EmailCategoryType.INTERNAL && newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1)) || category !== EmailCategoryType.INTERNAL && newValues.some((val) => (val[0] !== "#" || val[val.length - 1] !== "#"))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedTo([...selectedTo, ...newValues]);
                    }}
                    onRemove={(_value, index) => {
                      setSelectedTo(selectedTo.filter(_item => _item !== _value))
                    }}
                  />
                  {!isViewMode && (
                    <SelectMenu
                      isMultiSelect
                      title="Select multiple recipients"
                      filterPlaceholder="Search"
                      options={TOVARIABLES}
                      selected={selectedTo}
                      onSelect={(item: any) => setSelectedTo([...selectedTo, item?.value])}
                      onDeselect={(item: SelectMenuItem) => setSelectedTo(selectedTo.filter(_item => _item !== item?.value))}
                      width={480}
                    >
                      <Button disabled={!isSuperAdmin} width="18%">{findSelectedToCount(selectedTo)}</Button>
                    </SelectMenu>
                  )}
                  </Pane>
                </Pane >
                {/* <Pane marginTop={15} display="flex" >
                  <Label width="5%">Cc</Label>
                  <TagInput
                    disabled={!isSuperAdmin || isViewMode}
                    width="100%"
                    tagProps={(value: any) => {
                      if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) return { color: 'orange' }
                      return {}
                    }}
                    inputProps={{ placeholder: 'Cc...' }}
                    values={selectedCC}
                    onAdd={(newValues) => {
                      if (category === EmailCategoryType.INTERNAL && newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1)) || category !== EmailCategoryType.INTERNAL && newValues.some((val) => (val[0] !== "#" || val[val.length - 1] !== "#"))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedCC([...selectedCC, ...newValues])
                    }}
                    onRemove={(_value, index) => {
                      setSelectedCC(selectedCC.filter(_item => _item !== _value))
                    }}
                  />
                  {!isViewMode && (
                    <SelectMenu
                      isMultiSelect
                      title="Select multiple names"
                      options={users}
                      selected={selectedCC}
                      onSelect={(item: any) => setSelectedCC([...selectedCC, item?.value])}
                      onDeselect={(item: SelectMenuItem) => setSelectedCC(selectedCC.filter(_item => _item !== item?.value))}
                    >
                      <Button disabled={!isSuperAdmin} width="18%">{findSelectedCount(selectedCC)}</Button>
                    </SelectMenu>
                  )}
                </Pane > */}
                {/* <Pane marginTop={15} display="flex" >
                  <Label width="5%">Bcc</Label>
                  <TagInput
                    disabled={!isSuperAdmin || isViewMode}
                    width="100%"
                    tagProps={(value: any) => {
                      if ((users.map(x => x.value).indexOf(value) === -1) && (value[0] !== "#" || value[value.length - 1] !== "#")) return { color: 'orange' }
                      return {}
                    }}
                    inputProps={{ placeholder: 'Bcc...' }}
                    values={selectedBCC}
                    onAdd={(newValues) => {
                      if (category === EmailCategoryType.INTERNAL && newValues.some((val) => (users.map(x => x.value).indexOf(val) === -1)) || category !== EmailCategoryType.INTERNAL && newValues.some((val) => (val[0] !== "#" || val[val.length - 1] !== "#"))) {
                        toaster.danger('Oops, you tried entering an invalid email. Try again.');
                        return;
                      }
                      setSelectedBCC([...selectedBCC, ...newValues])
                    }}
                    onRemove={(_value, index) => {
                      setSelectedBCC(selectedBCC.filter(_item => _item !== _value))
                    }}
                  />
                  {!isViewMode && (
                    <SelectMenu
                      isMultiSelect
                      title="Select multiple names"
                      options={users}
                      selected={selectedBCC}
                      onSelect={(item: any) => setSelectedBCC([...selectedBCC, item?.value])}
                      onDeselect={(item: SelectMenuItem) => setSelectedBCC(selectedBCC.filter(_item => _item !== item?.value))}
                    >
                      <Button disabled={!isSuperAdmin} width="18%">{findSelectedCount(selectedBCC)}</Button>
                    </SelectMenu>
                  )}
                </Pane> */}
              </>
              {/* } */}

              <Pane marginBottom={15} marginTop={15} width="100%">
                <Label width="10%">Subject</Label>
                <TextInput
                  disabled={!isSuperAdmin || isViewMode}
                  width="100%"
                  value={emailData?.subject}
                  onChange={(e: any) => hendalChange(e.target.value, "subject")}
                  name={'subject'}
                  placeholder='Subject'
                />
              </Pane >
              <Pane marginBottom={15}>
                <Label width="90px">Body</Label>
                <ReactQuill
                  ref={quillref}
                  style={{ width: '100%' }}
                  placeholder='Add description'
                  theme="snow" value={body}
                  onChange={(e) => {
                    setBody(e);
                  }}
                  readOnly={isViewMode}
                />
              </Pane >
              <Pane marginTop={15}>
                <Label width="90px">Attachment</Label>
                <Pane display='flex'>
                  <TagInput
                    width="100%"
                    inputProps={{ placeholder: 'attachment' }}
                    values={selectedAttachments.filter(ele => ele.status === Types.EmailAttachmentsStatus.ACTIVE).map(attachment => attachment?.label)}
                    onChange={(values) => {
                      setSelectedAttachments(selectedAttachments.map(ele => {
                        if (!values.includes(ele.label)) {
                          return { ...ele, status: Types.EmailAttachmentsStatus.INACTIVE }
                        }
                        return ele
                      }))
                    }}
                    disabled={isViewMode}
                  />
                  {!isViewMode && (
                    <SelectMenu
                      isMultiSelect
                      title="Select files"
                      filterPlaceholder="Search..."
                      options={[...resourceVideos, ...resourceFlyers]}
                      selected={selectedAttachments.filter(ele => ele.status === Types.EmailAttachmentsStatus.ACTIVE).map(item => item.value)}
                      onSelect={(item: SelectMenuItem) => {
                        const isExist = selectedAttachments.find(ele => ele.value === item.value);
                        if (!isExist) {
                          setSelectedAttachments([...selectedAttachments, item])
                        } else {
                          setSelectedAttachments(selectedAttachments.map(ele => {
                            if (ele.value === item.value) {
                              return { ...ele, status: Types.EmailAttachmentsStatus.ACTIVE }
                            }
                            return ele
                          }))
                        }
                      }}
                      onDeselect={(item: SelectMenuItem) => {
                        setSelectedAttachments(selectedAttachments.map(ele => {
                          if (ele.value === item.value) {
                            return { ...ele, status: Types.EmailAttachmentsStatus.INACTIVE }
                          }
                          return ele
                        }))
                      }}
                    >
                      <Button width="15%">{findAttachmentSelectedCount(selectedAttachments.filter(ele => ele.status === Types.EmailAttachmentsStatus.ACTIVE))}</Button>
                    </SelectMenu>
                  )}
                </Pane>
              </Pane>
              {Object.keys(isErrors).map(item =>
                <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{isErrors[item]}</Text></div>
              )}
            </Card>
          </Pane>

          {isSuperAdmin ?
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {(!newsletterData || (newsletterData?.status !== Types.NewsletterStatus.SENT)) && (
                <>
                  {/* {newsletterData?.status !== Types.NewsletterStatus.DRAFT && ( */}
                    <Button
                      isLoading={sendEmailLoading}
                      marginLeft={0}
                      appearance="primary"
                      justifyContent="center"
                      height={40}
                      onClick={() => {
                        if (validation()) {
                          setEmailData({ ...emailData, status: 'DRAFT' })
                          sendEmail({
                            variables: {
                              ...emailData,
                              status: Types.NewsletterStatus.DRAFT,
                              to: JSON.stringify(selectedTo),
                              files: files,
                              body: body?.includes("<ul>") ?
                                body?.replace(new RegExp("<p><br></p><ul>", 'gi'), "<ul>")
                                :
                                body?.includes("<ol>") ?
                                  body?.replace(new RegExp("<p><br></p><ol>", 'gi'), "<ol>")
                                  : body
                            },
                          })
                        }
                      }}
                      style={{ display: emailData.status === 'SENT' ? 'none' : '' }}
                    >
                      {newsletterData?.status === Types.NewsletterStatus.DRAFT ? "Save" : "Draft"}
                    </Button>
                  {/* )}  */}
                  <Button
                    isLoading={sendEmailLoading}
                    marginLeft={15}
                    appearance="primary"
                    justifyContent="center"
                    height={40}
                    onClick={() => {
                      if (validation()) {
                        setEmailData({ ...emailData, status: 'SENT' })
                        sendEmail({
                          variables: {
                            ...emailData,
                            status: Types.NewsletterStatus.SENT,
                            to: JSON.stringify(selectedTo),
                            files: files,
                            body: body?.includes("<ul>") ?
                              body?.replace(new RegExp("<p><br></p><ul>", 'gi'), "<ul>")
                              :
                              body?.includes("<ol>") ?
                                body?.replace(new RegExp("<p><br></p><ol>", 'gi'), "<ol>")
                                : body
                          },
                        })
                      }
                    }}
                    style={{ display: (sendEmailLoading && emailData.status === 'DRAFT') ? 'none' : '' }}
                  >
                    Send
                  </Button>
                </>
              )}
            </Pane>
            :
            <></>}
        </SheetLayout>
      }
    </SideSheet >
  )
}

export default EmailNewsletterSheet
const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
