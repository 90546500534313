import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { Existing_DSAccount, UPDATE_DSACCOUNT } from 'graphql/_ds-user-sheet'
import { GET_MY_DS_ACCOUNT } from 'graphql/_ds-user'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, FormError, Icon } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import DSExistUserFields from 'components/_fields/DSExistUser-fields'

import { TextInputField, FileInputField, RadioGroup } from '@pearly/lib'


export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id: string
    DSCustomerNumber?: number | null
    DSMasterCustomerNumber: number | null
    billingZip?: number | null
    firstName?: string
    lastName?: string
    email?: string
    isSureSmileRegistered: string
    interestedLearningAboutSureSmile: string
  }
}

const DSExistUserSheet = ({ isShown, setIsShown, user }: Props) => {
  const showConfirmDialog = useModal('confirm')
  // const global = useGlobal()

  const [DSCustomerNumberError, setDSCustomerNumberError] = useState("")

  const [addExistingDSAcc, addExistingStatus] = useMutation<Types.existingDSAccount, Types.existingDSAccountVariables>(Existing_DSAccount, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyDSAccount>({ query: GET_MY_DS_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_DS_ACCOUNT,
          data: { myDSAccount: data?.existingDSAccount }
        })
      }
    }
  })

  useEffect(() => {
    if (addExistingStatus.error) {
      toaster.danger('Unable to create account')
      addExistingStatus.error = undefined;
    }
    else if (addExistingStatus.data && !addExistingStatus.loading) {
      setIsShown(false)
      toaster.success(`Account successfully created!`)
    }
  }, [addExistingStatus, setIsShown])

  const [updateDSAcc, updateStatus] = useMutation<Types.updateDSAccount, Types.updateDSAccountVariables>(UPDATE_DSACCOUNT, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyDSAccount>({ query: GET_MY_DS_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_DS_ACCOUNT,
          data: { myDSAccount: data?.updateDSAccount }
        })
      }
    }
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update account details')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Account details successfully updated!`)
    }
  }, [updateStatus, setIsShown])



  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          user
            ? user
            : {
              DSCustomerNumber: '',
              DSMasterCustomerNumber: null,
              billingZip: '',
              firstName: '',
              lastName: '',
              email: '',
              isSureSmileRegistered: 'NO',
              interestedLearningAboutSureSmile: 'NO',
            }
        }
        onSubmit={
          ({ ...userFields }) => {
            if (userFields?.DSCustomerNumber && userFields?.DSCustomerNumber?.toString()?.length > 10) {
              setDSCustomerNumberError("Dentsply Sirona Customer Number must be less than or equal to 10");
              return;
            }
            if (!addExistingStatus.error) {
              showConfirmDialog({
                body: `Are you sure you want to submit this detail?`,
                confirm: () => {
                  if (user) {
                    updateDSAcc({
                      variables: {
                        id: user.id,
                        DSCustomerNumber: Number(userFields.DSCustomerNumber),
                        DSMasterCustomerNumber: userFields.DSMasterCustomerNumber,
                        billingZip: Number(userFields.billingZip),
                        firstName: userFields.firstName,
                        lastName: userFields.lastName,
                        email: userFields.email,
                        isSureSmileRegistered: userFields.isSureSmileRegistered == "YES" ? true : false,
                        interestedLearningAboutSureSmile: userFields.interestedLearningAboutSureSmile == "YES" ? true : false,
                        practiceName: null,
                        NPI: null,
                        stateLicenseID: null,
                        billingAddress1: null,
                        billingAddress2: null,
                        billingCity: null,
                        billingState: null,
                        businessPhone: null,
                        isDifferentShippingAddress: false,
                        shippingAddress1: null,
                        shippingAddress2: null,
                        shippingZip: null,
                        shippingCity: null,
                        shippingState: null
                      }
                    })
                    setDSCustomerNumberError("")
                  } else {
                    addExistingDSAcc({
                      variables: {
                        DSCustomerNumber: Number(userFields.DSCustomerNumber),
                        billingZip: Number(userFields.billingZip),
                        firstName: userFields.firstName,
                        lastName: userFields.lastName,
                        email: userFields.email,
                        isSureSmileRegistered: userFields.isSureSmileRegistered == "YES" ? true : false,
                        interestedLearningAboutSureSmile: userFields.interestedLearningAboutSureSmile == "YES" ? true : false
                      }
                    })
                    setDSCustomerNumberError("")
                  }
                }
              })
            }
          }}
        validationSchema={
          Yup.object({
            DSCustomerNumber: Yup.number().required('Dentsply Sirona Customer Number is required'),
            billingZip: Yup.number().required('Zip Code is required'),
            firstName: Yup.string().required('firstName is required'),
            lastName: Yup.string().required('lastName is required'),
            email: Yup.string().required('email is required'),
          })
        }
      >
        <Form style={{ height: '100%' }} onScroll={() => { var a: any = document.activeElement; a?.blur(); }}>
          <SheetLayout>
            <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
              <Heading size={600}>Existing customers</Heading>
              {/* <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph> */}
            </CardHeader>

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <DSExistUserFields />
                <FormError />
                {DSCustomerNumberError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{DSCustomerNumberError}</Text></div>}
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {user ? (
                <Button
                  autoFocus
                  isLoading={updateStatus.loading || !!updateStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                >
                  Accept & Submit
                </Button>
              ) : (
                <Button
                  isLoading={addExistingStatus.loading || !!addExistingStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Accept & Submit
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default DSExistUserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
