import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  candidUserFields: gql`
    fragment candidUserFields on CandidUser {
      id
      account {
        id
        code
        name
      }
      candidProCustomerNumber
      practiceName
      doctorFirstName
      doctorLastName
      doctorEmail
      allowLoginToDoctors
      shippingAddress1
      shippingAddress2
      shippingCity
      shippingState
      shippingZip
      shippingCountry
      industryGroup
      candidProProvider
      officePhone
      last6MonthCases
      enrolledOn
      submittedOn
      activatedOn
      endDate
      lastUpdated
      agreementURL
      status
      membership
      enrollmentOptionChosen
    }
  `
}

export const GET_MY_CANDID_ACCOUNT = gql`
query GetMyCandidAccount {
   myCandidAccount {
    ...candidUserFields
  }
}
${fragments.candidUserFields}
`

export const GET_CANDID_USERS = gql`
query GetCandidUsers {
  candidUsers {
    ...candidUserFields
  }
}
${fragments.candidUserFields}
`

export const GET_CANDID_USER_SEARCH_LIST = gql`
query GetCandidUserSearchList($search: String!, $enrollmentOptionChosen: Boolean!, $first: Int, $after: String) {
  CandidUserSearch(search: $search, enrollmentOptionChosen: $enrollmentOptionChosen, first: $first, after: $after) {
      edges {
        cursor
        node  {
          id
          account {
            id
            code
            name
          }
          candidProCustomerNumber
          practiceName
          doctorFirstName
          doctorLastName
          doctorEmail
          allowLoginToDoctors
          shippingAddress1
          shippingAddress2
          shippingCity
          shippingState
          shippingZip
          shippingCountry
          industryGroup
          candidProProvider
          officePhone
          last6MonthCases
          enrolledOn
          submittedOn
          activatedOn
          endDate
          lastUpdated
          agreementURL
          status
          membership
          enrollmentOptionChosen
        }
      }
      pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`

export const DELETE_CANDID_ACCOUNT = gql`
  mutation DeleteCandidAccount($id: ID!) {
    deleteCandidAccount(id: $id) {
      id
    }
  }
`

export const ACTIVATE_CANDID_ACCOUNT = gql`
  mutation ActivateCandidAccount($id: ID!, $candidProCustomerNumber: String!) {
    activateCandidAccount(id: $id, candidProCustomerNumber: $candidProCustomerNumber) {
      ...candidUserFields
    }
  }
  ${fragments.candidUserFields}
`
