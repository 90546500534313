import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import * as Types from 'types'

import {
    Card,
    CardHeader,
    Button,
} from '@pearly/lib'
import { SideSheet, Pane, Text, Heading, toaster, Checkbox } from 'evergreen-ui'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_USER_VENDOR } from 'graphql/_user-vendors-sheet'
import { useModal } from 'components/modal-provider'
import { GetVendors_vendors } from 'types'

export type Props = {
    vendors?: GetVendors_vendors[]
    practiceInfo?: any
    isShown: boolean
    setIsShown: (isShown: boolean) => void
}

const UserVendorsSheet = ({ vendors, practiceInfo, isShown, setIsShown }: Props) => {

    const showConfirmDialog = useModal('confirm')

    const [vendorChecks, setVendorChecks] = React.useState<any>([])

    const [upsert, upsertStatus] = useMutation<Types.updateUserVendor, Types.updateUserVendorVariables>(UPDATE_USER_VENDOR, {
        refetchQueries: ['OwnServicePlanSubscriptionsSearch']
    });

    useEffect(() => {
        if (upsertStatus.error) toaster.danger(`Unable to update`)
        else if (upsertStatus.data && !upsertStatus.loading) {
            setIsShown(false)
            toaster.success(`Updated successfully!`)
        }
    }, [upsertStatus, setIsShown])

    useEffect(() => {
        setVendorChecks(vendors?.map(vend => {
            return {
                ...vend,
                isActive: practiceInfo.userVendor.find((x: any) => x.accountId === practiceInfo.subscriberId && x.vendorId === vend.id) ? true : false
            }
        }) ?? []
        )
    }, [])

    const handleCheckEvent = (event: any, id: string) => {
        setVendorChecks(vendorChecks?.map((vend: any) => {
            if (id === 'all') {
                return {
                    ...vend,
                    isActive: event.target.checked
                }
            } else if (vend.id === id) {
                return {
                    ...vend,
                    isActive: event.target.checked
                }
            }
            return vend
        })
        )
    }

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={500}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
            >

                <SheetLayout>
                    <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
                        <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                            <Pane display="flex" alignItems="center">
                                <Pane marginLeft={16}>
                                    <Heading size={600}>
                                    </Heading>
                                    <Text size={400}>Service Utlization Overview</Text>
                                </Pane>
                            </Pane>
                        </Pane>
                    </CardHeader>

                    <Pane gridArea="body" overflow="hidden">
                        <SheetBodyLayout>
                            <Pane gridArea="body" background="blueTint" overflow="scroll">
                                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Practice's Vendor Service Utilization</Heading>
                                    </CardHeader>
                                    <Pane padding={16}>
                                        <Checkbox name={'all'} checked={!vendorChecks.find((x: any) => !x.isActive)} onChange={(e) => handleCheckEvent(e, 'all')} label={<Text size={500}>Select All</Text>} />
                                        {vendorChecks?.map((item: any) =>
                                            (<Checkbox name={item?.id} checked={item?.isActive} onChange={(e) => handleCheckEvent(e, item.id)} label={<Text size={500}>{item?.vendor}</Text>} />)
                                        )}

                                        <Button marginRight={8}
                                            type="button"
                                            intent="success"
                                            onClick={() => {
                                                showConfirmDialog({
                                                    body: `Are you sure you want to update?`,
                                                    confirm: () => {
                                                        const now = new Date();
                                                        upsert({
                                                            variables: {
                                                                accountId: practiceInfo?.subscriberId ?? '',
                                                                vendorIds: vendorChecks.filter((x: any) => x.isActive).map((y: any) => y.id).toString()
                                                            }
                                                        })
                                                    }
                                                })
                                            }}>
                                            Save
                                        </Button>

                                    </Pane>
                                </Card>
                            </Pane>

                        </SheetBodyLayout>
                    </Pane>
                </SheetLayout>

            </SideSheet>

        </>
    )
}

export default UserVendorsSheet

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`