import gql from 'graphql-tag'


export const CLEARENT_MERCHANT_SETTING = gql`
    query clearentMerchantSetting {
        clearentMerchantSetting {
            connected
            defaultEmail
            defaultName
            merchantNumber
            merchantAccount{
                businessID
                dbaName
                merchantNumber
                emailAddress
                phones {
                    phoneTypeCodeID
                    areaCode
                    phoneNumber
                    extension
                }
            }
        }
    }
`

export const CLEARENT_BOARDING_START = gql`
    mutation onboardingComplete {
        onboardingComplete
    }
`

export const CLEARENT_CREATE_MERCHANT = gql`
    mutation clearentCreateMerchant (
        $dbaName: String!, 
        $email: String!, 
        $phone_area: String!, 
        $phone_number: String!) {
        clearentCreateMerchant (
            dbaName: $dbaName, 
            email: $email, 
            phone_area: $phone_area, 
            phone_number: $phone_number) {
                businessID
                dbaName
                emailAddress
                merchantNumber
        }
    }
`

export const CLEARENT_UPDATE_MERCHANT = gql`
    mutation clearentUpdateMerchant (
        $dbaName: String!, 
        $email: String!, 
        $phone_area: String!, 
        $phone_number: String!) {
        clearentUpdateMerchant (
            dbaName: $dbaName, 
            email: $email, 
            phone_area: $phone_area, 
            phone_number: $phone_number) {
                businessID
                dbaName
                emailAddress
                merchantNumber
        }
    }
`

export const CLEARENT_UPDATE_ADDRESS = gql`
    mutation clearentUpdateAddress (
        $addressType: Int!, 
        $city: String!, 
        $countryCode: Int!, 
        $line1: String!, 
        $line2: String, 
        $line3: String, 
        $stateCode: String!, 
        $zip: String) {
        clearentUpdateAddress (
            addressType: $addressType, 
            city: $city, 
            countryCode: $countryCode, 
            line1: $line1, 
            line2: $line2, 
            line3: $line3, 
            stateCode: $stateCode, 
            zip: $zip) {
                city
                line1
                line2
                line3
                stateCode
                zip
                addressType
        }
    }
`

export const CLEARENT_ADDRESS = gql`
    query clearentAddress (
        $addressType: Int!) {
        clearentAddress (
            addressType: $addressType) {
                city
                line1
                line2
                line3
                stateCode
                zip
        }
    }
`

export const CLEARENT_UPDATE_TAXPAYER = gql`
mutation clearentUpdateTaxPayer ($businessLegalName: String!,
     $legalFirstName: String, 
     $legalLastName: String, 
     $stateIncorporatedCode: String!, 
     $tin: String!) {
    clearentUpdateTaxPayer (businessLegalName: $businessLegalName, legalFirstName: $legalFirstName, legalLastName: $legalLastName, stateIncorporatedCode: $stateIncorporatedCode, tin: $tin) {
        businessLegalName
        legalFirstName
        legalLastName
        stateIncorporatedCode
        tin
        tinTypeID
    }
}
`

export const CLEARENT_TAXPAYER = gql`
query clearentTaxPayer {
    clearentTaxPayer {
        businessLegalName
        legalFirstName
        legalLastName
        stateIncorporatedCode
        tin
        tinTypeID
    }
}
`

export const CLEARENT_ADD_BUSINESSCONTACT = gql`
mutation clearentAddBusinessContact (
    $contact: MerchantContactInput!, 
    $emailAddress: String, 
    $ownershipAmount: Float!,
    $phoneNumbers: [MerchantPhoneInput!], 
    $title: String!) {
    clearentAddBusinessContact (contact: $contact, emailAddress: $emailAddress, ownershipAmount: $ownershipAmount, phoneNumbers: $phoneNumbers, title: $title) {
        businessContactId
        emailAddress
        ownershipAmount
        title
    }
}
`

export const CLEARENT_DELETE_BUSINESSCONTACT = gql`
mutation clearentDeleteBusinessContact ($contactId: Int!) {
    clearentDeleteBusinessContact (contactId: $contactId)
}
`

export const CLEARENT_BUSINESS_CONTACTS = gql`
query clearentBusinessContacts {
    clearentBusinessContacts {
        businessContactId
        emailAddress
        ownershipAmount
        title
    }
}
`

export const CLEARENT_ADD_BANK = gql`
mutation clearentAddBank (
    $aba: String!, 
    $accountHolderFirstName: String!, 
    $accountHolderLastName: String!, 
    $accountNumber: String!, 
    $bankAccountNameTypeID: Int!, 
    $bankAccountTypeID: Int!, 
    $bankName: String!, 
    $nameOnAccount: String!) {
    clearentAddBank (aba: $aba, accountHolderFirstName: $accountHolderFirstName, accountHolderLastName: $accountHolderLastName, accountNumber: $accountNumber, bankAccountNameTypeID: $bankAccountNameTypeID, bankAccountTypeID: $bankAccountTypeID, bankName: $bankName, nameOnAccount: $nameOnAccount) {
        aba
        accountHolderFirstName
        accountHolderLastName
        accountNumber
        bankAccountID
        bankAccountNameTypeID
        bankAccountTypeID
        bankName
        hasChargebacks
        hasFees
        hasFunds
        isNameSameAsLegalOrDBAName
        nameOnAccount
        voidedCheckDocumentID
    }
}
`

export const CLEARENT_UPDATE_BANK = gql`
mutation clearentUpdateBank (
    $accountHolderFirstName: String,
    $accountHolderLastName: String, 
    $bankAccountID: Int!, 
    $bankName: String, 
    $nameOnAccount: String) {
    clearentUpdateBank (accountHolderFirstName: $accountHolderFirstName, accountHolderLastName: $accountHolderLastName, bankAccountID: $bankAccountID, bankName: $bankName, nameOnAccount: $nameOnAccount) {
        aba
        accountHolderFirstName
        accountHolderLastName
        accountNumber
        bankAccountID
        bankAccountNameTypeID
        bankAccountTypeID
        bankName
        hasChargebacks
        hasFees
        hasFunds
        isNameSameAsLegalOrDBAName
        nameOnAccount
        voidedCheckDocumentID
    }
}
`

export const CLEARENT_DELETE_BANK = gql`
mutation clearentDeleteBank ($bankAccountID: Int!) {
    clearentDeleteBank (bankAccountID: $bankAccountID)
}
`
export const CLEARENT_BANKS = gql`
query clearentBanks {
    clearentBanks {
        aba
        accountHolderFirstName
        accountHolderLastName
        accountNumber
        bankAccountID
        bankAccountNameTypeID
        bankAccountTypeID
        bankName
        hasChargebacks
        hasFees
        hasFunds
        isNameSameAsLegalOrDBAName
        nameOnAccount
        voidedCheckDocumentID
    }
}
`