import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Formik, Form } from 'formik'
import * as Types from 'types'

import {
    Card,
    CardHeader,
    Button,
    Checkbox,
    Tab,
    useToken,
    Textarea,
    ConfirmDialog
} from '@pearly/lib'
import { SideSheet, Pane, Label, Text, Heading, Avatar, Tablist, toaster } from 'evergreen-ui'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { DELETE_ACCOUNT, UPSERT_ONBOARDING_DASHBOARD } from 'graphql/_onboarding-dashboard'
import { useModal } from 'components/modal-provider'
import moment from 'moment'
import { SET_CURRENT_ACCOUNT_ID } from 'graphql/_header'

export type Props = {
    practiceDashboardInfo: any
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    initialPath?: 'practice'
}

const OboardDashboardSheet = ({ practiceDashboardInfo, isShown, setIsShown }: Props) => {

    const apolloClient = useApolloClient()
    const { refreshToken } = useToken()
    const [setAccount, setAccountStatus] = useMutation<Types.SetCurrentAccountId, Types.SetCurrentAccountIdVariables>(
        SET_CURRENT_ACCOUNT_ID
    )

    const [deleteAccount, deleteAccountStatus] = useMutation<Types.deleteAccount, Types.deleteAccountVariables>(
        DELETE_ACCOUNT,
        {
            refetchQueries: ['OnboardingDashboardTable', 'OnbordingDashbordTableSearchByName']
        }
    )

    const [isDeleteAccountDialogShown, setIsDeleteAccountDialogShown] = useState(false)

    useEffect(() => {
        if (setAccountStatus.error) {
            toaster.danger(`Unable to switch accounts`)
        } else if (setAccountStatus.data && !setAccountStatus.loading) {
            refreshToken()
            setTimeout(() => {
                toaster.success(`Switched Practice to ${setAccountStatus.data?.setCurrentAccountId}`)
            }, 500)
        }
    }, [setAccountStatus, refreshToken, apolloClient])

    const showConfirmDialog = useModal('confirm')

    const [upsertOnboarding, upsertStatus] = useMutation<Types.upsertOnboardingDashboard, Types.upsertOnboardingDashboardVariables>(
        UPSERT_ONBOARDING_DASHBOARD,
        {
            refetchQueries: ['OnboardingDashboardTable', 'OnbordingDashbordTableSearchByName']
        }
    );

    useEffect(() => {
        if (upsertStatus.error) toaster.danger(`Unable to update`)
        else if (upsertStatus.data && !upsertStatus.loading) {
            setIsShown(false)
            toaster.success(`Updated successfully!`)
        }
    }, [upsertStatus, practiceDashboardInfo, setIsShown])

    useEffect(() => {
        if (deleteAccountStatus.error) {
            toaster.danger(`Unable to delete account`)
        } else if (deleteAccountStatus.data) {
            console.log("deleteAccountStatus.data", deleteAccountStatus);
            toaster.success(`Account  deleted successfully`)
            setIsShown(false)
        }
    }, [deleteAccountStatus, setIsShown])

    var primaryUser = ''
    practiceDashboardInfo?.users?.find((user: any) => {
        if (user.google.role === 'ADMIN') {
            primaryUser = user.firstName + ' ' + user.lastName
            return true
        }
    })

    return (
        <>
            <ConfirmDialog
                isShown={isDeleteAccountDialogShown}
                setIsShown={setIsDeleteAccountDialogShown}
                confirm={() => deleteAccount({ variables: { id: practiceDashboardInfo.account.id } })}
                body="Are you sure you want to delete the account of this practice? It cannot be reversed."
                intent="danger"
            />
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={500}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
            >

                <SheetLayout>
                    <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
                        <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                            <Pane display="flex" alignItems="center">
                                <Avatar name={"Practice Overview"} size={40} />
                                <Pane marginLeft={16}>
                                    <Heading size={600}>
                                    </Heading>
                                    <Text size={400}>Practice Overview</Text>
                                </Pane>
                            </Pane>
                            <Text size={400}>
                                <strong>ID: </strong>
                                {practiceDashboardInfo.subscription?.acceptDate && practiceDashboardInfo?.account?.code}
                            </Text>
                        </Pane>
                        {/* <Pane display="flex" padding={8}>
                            <Tablist>
                                <Tab
                                    textTransform="uppercase"
                                    isSelected={true}
                                >
                                    Practice Live
                                </Tab>
                            </Tablist>
                        </Pane> */}
                    </CardHeader>

                    <Pane gridArea="body" overflow="hidden">
                        <SheetBodyLayout>
                            <Pane gridArea="body" background="blueTint" overflow="scroll">
                                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Practice Details</Heading>
                                    </CardHeader>
                                    <Pane padding={16}>
                                        <Pane padding={8} display="flex" flexDirection="column">
                                            <Text size={500} marginBottom={8}>
                                                <strong>Practice Name: </strong>
                                                {practiceDashboardInfo.account.name}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Primary User: </strong>
                                                {primaryUser}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Plan: </strong>
                                                {practiceDashboardInfo.plan?.name}
                                            </Text>

                                            <Text size={500} marginBottom={8}>
                                                <strong>Start Date: </strong>
                                                {practiceDashboardInfo.subscription?.startDate ? moment.utc(practiceDashboardInfo.subscription.startDate).format('M/D/YYYY') : ''}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>End Date: </strong>
                                                {practiceDashboardInfo.subscription?.endDate ? moment.utc(practiceDashboardInfo.subscription.endDate).format('M/D/YYYY') : ''}

                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Membership Dues: </strong>
                                                {practiceDashboardInfo.subscription?.fee ?? 0.00}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Status: </strong>
                                                {practiceDashboardInfo.subscription?.status}
                                                {/* {errorInfo.status} */}
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Card>

                                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Status Details</Heading>
                                    </CardHeader>
                                    <Formik initialValues={
                                        {
                                            practicefeeanalysis: practiceDashboardInfo.practiceFeeAnalysisComplete,
                                            practiceprogram: practiceDashboardInfo.practiceProgramApproved,
                                            marketingmaterial: practiceDashboardInfo.marketingMaterialApproved,
                                            softwaresetupguide: practiceDashboardInfo.softwareSetupGuideSent,
                                            practice: practiceDashboardInfo.practiceLive,
                                            note: practiceDashboardInfo.note
                                        }
                                    } onSubmit={({ practicefeeanalysis, practiceprogram, marketingmaterial, softwaresetupguide, practice, note }) => {
                                        showConfirmDialog({
                                            body: `Are you sure you want to update?`,
                                            confirm: () => {
                                                const now = new Date();
                                                upsertOnboarding({
                                                    variables: {
                                                        id: practiceDashboardInfo.id,
                                                        practiceName: practiceDashboardInfo.practiceName,
                                                        practiceFeeAnalysisComplete: practicefeeanalysis ? now : null,
                                                        practiceProgramApproved: practiceprogram ? now : null,
                                                        marketingMaterialApproved: marketingmaterial ? now : null,
                                                        softwareSetupGuideSent: softwaresetupguide ? now : null,
                                                        practiceLive: practice ? now : null,
                                                        note: note ?? ''
                                                    }
                                                })
                                            }
                                        })
                                    }}>
                                        <Form onScroll={() => { var a: any = document.activeElement; a?.blur(); }}>
                                            <Pane padding={16}>

                                                {/* <Checkbox disabled={practiceDashboardInfo.practiceFeeAnalysisComplete} name="practicefeeanalysis" label={<Text size={500}>Practice Fee Analysis</Text>} />
                                                <Checkbox disabled={practiceDashboardInfo.practiceProgramApproved} name="practiceprogram" label={<Text size={500}>Practice Program</Text>} />
                                                <Checkbox disabled={practiceDashboardInfo.marketingMaterialApproved} name="marketingmaterial" label={<Text size={500}>Marketing Material</Text>} />
                                                <Checkbox disabled={practiceDashboardInfo.softwareSetupGuideSent} name="softwaresetupguide" label={<Text size={500}>Software Setup Guide</Text>} /> */}
                                                <Checkbox disabled={true} name="practice" label={<Text size={500}>Practice Live</Text>} />
                                                <Label marginBottom={4} alignSelf="flex-start">User Notes</Label>
                                                <Textarea name="note" type="text" placeholder="Notes" />
                                                <br />


                                                <Button marginRight={8}
                                                    type="submit"
                                                    intent="success">
                                                    Save
                                                </Button>

                                            </Pane>
                                        </Form>
                                    </Formik>
                                </Card>
                            </Pane>
                            <Pane
                                gridArea="footer"
                                elevation={0}
                                padding={16}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Button marginLeft={0}
                                    appearance="primary"
                                    justifyContent="center"
                                    height={40}
                                    onClick={() => {
                                        setAccount({ variables: { accountId: practiceDashboardInfo?.accountId } })
                                    }}>
                                    Impersonate
                                </Button>

                                {!practiceDashboardInfo?.subscription?.acceptDate &&
                                    <Button marginLeft={0}
                                        appearance="primary"
                                        justifyContent="center"
                                        height={40}
                                        onClick={() => { setIsDeleteAccountDialogShown(true) }}>
                                        Delete
                                    </Button>
                                }
                            </Pane>

                        </SheetBodyLayout>
                    </Pane>
                </SheetLayout>

            </SideSheet>

        </>
    )
}

export default OboardDashboardSheet

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`