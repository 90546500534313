import React from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'
import moment from 'moment'

import Button from '../button'

import { ApolloClient } from 'apollo-client'
import { useQuery } from '@apollo/react-hooks'

import { GET_SERVICE_PLAN, GET_FIRST_OFFICE_SERVICE_PLAN } from '../graphql/_service-agreement'

import { SideSheet, Pane, Paragraph, Text, Heading } from 'evergreen-ui'
import CardHeader from '../card-header'
import Spinner from '../spinner'

import { effectiveDateRangeInDateTime, toDollars } from '../utility/formatters'
import { colors } from '../utility/theme'
import { useMediaQueryContext } from '../media-query-provider'
// import ServiceContract from '../contract/service-contract'
import ServiceExhibitA from '../contract/service-exhibit-a'
import ServiceContractSheetDentalLine from '../dentalLine/_sheets/service-contract-sheet'
import ServiceContractSheetTBDirect from '../TBDirect/_sheets/service-contract-sheet'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
  effectiveDate?: any
  endDate?: any
  planTerm?: Types.PlanTerm
  cancelled?: boolean
  apolloClient?: ApolloClient<any>
  dependent?: boolean
  serviceStatus?: any
  isLoading: any
  updateStatus?: any
  serviceFee?: any
  setupFee?: any
  practiceName?: any
  fullName?: any
  acceptDate?: any
  platformTarget?: string
  firstBilling?: any,
  usageFee?: any
  isSubscription?: boolean
}

const ServiceContractSheet = ({
  isShown,
  setIsShown,
  planId,
  effectiveDate,
  endDate,
  cancelled,
  apolloClient,
  serviceStatus,
  isLoading,
  updateStatus,
  serviceFee,
  setupFee,
  practiceName,
  fullName,
  acceptDate,
  platformTarget,
  firstBilling,
  usageFee,
  isSubscription
}: Props) => {
  const { isMobile } = useMediaQueryContext()
  const { loading, error, data } = useQuery<Types.ServicePlan, Types.ServicePlanVariables>(GET_SERVICE_PLAN, {
    variables: { id: planId! },
    skip: !isShown || !planId,
    client: apolloClient
  })

  const plan = data?.plan

  const { data: dataOffice } = useQuery<Types.FirstOfficeServicePlan>(GET_FIRST_OFFICE_SERVICE_PLAN)

  // NEED A QUERY TO GET THIS INFO FROM DB

  const currentSub = (dataOffice !== null) ? dataOffice?.firstOffice : null

  console.log("isSubscription", isSubscription);

  var contract = {
    customerName: practiceName,
    stateOfOrganization: currentSub?.state,
    entityType: 'Practice',
    address: `${currentSub?.address1} ${(currentSub?.address2)}, ${currentSub?.zip}`,
    setupFee: isSubscription ? setupFee ?? 0 : plan?.setupFee ?? 0,
    serviceFee: isSubscription ? serviceFee ?? 0 : plan?.monthlyPrice ?? 0
  }

  return (
    <>
      {platformTarget === "dentalLine" &&
        <ServiceContractSheetDentalLine
          isShown={isShown}
          setIsShown={setIsShown}
          planId={planId}
          cancelled={cancelled}
          effectiveDate={effectiveDate}
          endDate={endDate}
          serviceStatus={serviceStatus}
          setupFee={setupFee}
          serviceFee={serviceFee}
          isLoading={isLoading}
          updateStatus={updateStatus}
          practiceName={practiceName}
          fullName={fullName}
          acceptDate={acceptDate}
          firstBilling={firstBilling}
          usageFee={usageFee}
          isSubscription={isSubscription}
        />
      }

      {platformTarget === "TBDirect" &&
        <ServiceContractSheetTBDirect
          isShown={isShown}
          setIsShown={setIsShown}
          planId={planId}
          cancelled={cancelled}
          effectiveDate={effectiveDate}
          endDate={endDate}
          serviceStatus={serviceStatus}
          setupFee={setupFee}
          serviceFee={serviceFee}
          isLoading={isLoading}
          updateStatus={updateStatus}
          practiceName={practiceName}
          fullName={fullName}
          acceptDate={acceptDate}
          firstBilling={firstBilling}
          isSubscription={isSubscription}
        />
      }

      {(!platformTarget || platformTarget === "PFH") &&
        <SideSheet
          isShown={isShown}
          onCloseComplete={() => setIsShown(false)}
          width={600}
          shouldCloseOnOverlayClick={false}
          preventBodyScrolling={true}
          //@ts-ignore
          position={isMobile ? 'bottom' : 'right'}
        >
          {loading || error || !plan ? (
            <Pane
              display="flex"
              alignItems="center"
              background="blueTint"
              height={isMobile ? 'calc(100vh - 56px)' : '100%'}
            >
              <Spinner delay={0} />
            </Pane>
          ) : (
            <SheetLayout>
              <CardHeader gridArea="header">
                <Heading size={600}>{plan.name}</Heading>
                {/* <Button iconBefore={['fas', 'print']} onClick={() => window.print()}>
               Print
             </Button> */}
              </CardHeader>

              <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
                <Pane margin={24}>
                  <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                    Overview
                  </Heading>
                  <Paragraph marginBottom={24}>
                    We offer an in-house membership plan to our loyal patients that provides easy, comprehensive, and
                    affordable dental care benefits.
                  </Paragraph>
                  <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                    Plan Details
                  </Heading>
                  <Pane display="flex" flexDirection="column" marginBottom={24}>
                    <Text overflowWorp="break-word" wordWrap="break-word" marginBottom={4}>
                      <strong>Plan: </strong>
                      {plan.name}
                    </Text>
                    <Text marginBottom={4}>
                      <strong>Effective Dates: </strong>
                      {effectiveDateRangeInDateTime(effectiveDate, endDate)}
                    </Text>

                    <Text marginBottom={4}>
                      <strong>{cancelled ? 'Termination' : 'Renewal'} Date: </strong>
                      {`${(effectiveDate ? moment.utc(endDate).add(1, "days").format('M/D/YYYY') : moment().add(1, 'month').format('M/D/YYYY'))}`}
                    </Text>
                    <Text marginBottom={4}>
                      <strong>Renewal: </strong>
                      {cancelled ? 'Cancelled' : 'Monthly'}
                    </Text>

                    <Text marginBottom={4}>
                      <strong>Setup Fee: </strong> {toDollars(setupFee ?? data?.plan?.setupFee ?? 0, 2)}
                    </Text>
                    <Text marginBottom={4}>
                      <strong>Service Fee: </strong> {serviceFee != 0 ? `$${serviceFee}` : `$${data?.plan?.monthlyPrice}`}
                    </Text>
                  </Pane>
                  <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                    TruBlu Plan For Health, LLC Service Agreement
                  </Heading>
                  {/* <ServiceContract /> */}
                  <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                    Service Exhibit A
                  </Heading>
                  <ServiceExhibitA contractInfo={contract ?? ''} />
                  {(serviceStatus === 'PENDING') ?
                    <Button marginRight={8} marginTop={10} intent="success" onClick={() => updateStatus()} isLoading={isLoading}>
                      Accept the contract
                    </Button> : null}
                  {(serviceStatus !== 'PENDING') ?
                    <Text>Signed by: {fullName}   | Date: {moment.utc(acceptDate).format('M/D/YYYY')}</Text>
                    : null}
                </Pane>
                {/* <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  TruBlu Plan For Health, LLC Service Agreement
                </Heading>
                <ServiceContract />
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  Service Exhibit A
                </Heading>
                <ServiceExhibitA contractInfo={contract ?? ''} />
                {(serviceStatus === 'PENDING') ?
                  <Button marginRight={8} marginTop={10} intent="success" onClick={() => updateStatus()} isLoading={isLoading}>
                    Accept the contract
                  </Button> : null}
                {(serviceStatus !== 'PENDING') ?
                  <Text> Signed by: ${fullName}   | Date: ${moment.utc(acceptDate).format('M/D/YYYY')} </Text>
                  : null} */}
              </Pane>
            </SheetLayout>
          )}
        </SideSheet>
      }
    </>
  )
}

export default ServiceContractSheet

const SheetLayout = styled.div`
      height: 100%;
      display: grid;
      grid-template-areas:
      'header'
      'body';
      grid-template-rows: auto 1fr;
      `