import {
  createModalProvider,
  ContractSheet,
  EnrollMemberSheet,
  ViewMemberSheet,
  ViewPaymentsProblemsSheet,
  ViewPracticePaymentsProblemsSheet,
  ViewServicesSheet,
  ConfirmDialog,
  UpdatePlanDateSheet,
  PaymentRecipt
} from '@pearly/lib'

import DentistSheet from 'components/_sheets/dentist-sheet'
import OfficeSheet from 'components/_sheets/office-sheet'
import PlanSheet from 'components/_sheets/plan-sheet'
import UserSheet from 'components/_sheets/user-sheet'
import ClearentSheet from 'components/_sheets/clearent-sheet'
import BankSheet from './_sheets/bank-sheet'
import OwnerSheet from './_sheets/owner-sheet'
import OboardDashboardSheet from './_sheets/onboard-dashboard-sheet'
import PracticeSheet from './_sheets/practice-sheet'
import InvoiceSheet from './_sheets/invoice-sheet'
import InvoiceItemAddSheet from './_sheets/addInvoice-Items'
import VendorSheet from './_sheets/vendor-sheet'
import DSNewUserSheet from './_sheets/ds-new-user'
import DSExistUserSheet from './_sheets/ds-exist-user'
import UserVendorsSheet from './_sheets/user-vendor-sheet'
import ViewPracticeInvoice from './_sheets/view-practice-invoice'
import PDExistUserSheet from './_sheets/pd-exist-user'
import EmailHelpSheet from './_sheets/email-help'
import EmailResendSheet from './_sheets/email-resend-sheet'
import EmailAttachmentsSheet from './_sheets/email-attachments-sheet'
import VideoSheet from './_sheets/video-sheet'
import FlyerSheet from './_sheets/flyer-sheet'
import EmailNewsletterSheet from './_sheets/email-newsletter-sheet'
import CandidUserSheet from './_sheets/candid-user'
import CandidUserActivateSheet from './_sheets/candid-user-activate'

const sheetComponents = {
  enrollMember: EnrollMemberSheet,
  contract: ContractSheet,
  updatePlanDateSheet: UpdatePlanDateSheet,
  dentist: DentistSheet,
  office: OfficeSheet,
  plan: PlanSheet,
  clearent: ClearentSheet,
  bank: BankSheet,
  owner: OwnerSheet,
  dashboard: OboardDashboardSheet,
  practice: PracticeSheet,
  invoiceSheet: InvoiceSheet,
  user: UserSheet,
  viewMember: ViewMemberSheet,
  viewPaymentsProblems: ViewPaymentsProblemsSheet,
  viewPracticePaymentsProblemsSheet: ViewPracticePaymentsProblemsSheet,
  viewServices: ViewServicesSheet,
  invoiceItemAddSheet: InvoiceItemAddSheet,
  vendor: VendorSheet,
  DSNewUser: DSNewUserSheet,
  DSExistUser: DSExistUserSheet,
  PDExistUser: PDExistUserSheet,
  CandidUser: CandidUserSheet,
  CandidUserActivate: CandidUserActivateSheet,
  userVendors: UserVendorsSheet,
  viewPracticeInvoice: ViewPracticeInvoice,
  paymentRecipt: PaymentRecipt,
  emailHelp: EmailHelpSheet,
  emailResend: EmailResendSheet,
  emailAttachments: EmailAttachmentsSheet,
  video: VideoSheet,
  flyer: FlyerSheet,
  emailNewsletter: EmailNewsletterSheet,
  confirm: ConfirmDialog
}

export const { ModalProvider, useModal } = createModalProvider(sheetComponents)
