import React from 'react'
import { auth } from 'firebase'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { ApolloProvider as SourceApolloProvider } from '@apollo/react-hooks'

import { useToken } from '@pearly/lib'

export type Props = {
  children: React.ReactNode
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
})

const secureHttpLink = createHttpLink({
  uri: process.env.REACT_APP_SECURE_API_URL
})

const createApolloClient = () => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await auth().currentUser?.getIdToken()
    
    return token
      ? {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`
          }
        }
      : headers
  })

  return new ApolloClient({
    link: authLink.split(operation => !!operation.getContext().secure, secureHttpLink, httpLink),
    cache: new InMemoryCache(),
    name: 'Provider App'
  })
}

const ApolloProvider = ({ children }: Props) => {
  useToken()

  return <SourceApolloProvider client={createApolloClient()}>{children}</SourceApolloProvider>
}

export default ApolloProvider
