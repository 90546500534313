import React from 'react'

import { Label } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, RadioGroup } from '@pearly/lib'

import * as Types from 'types/graphql'

export type Props = {}

const SystemUserFields = ({ ...props }: Props) => (
  <>
    <Label marginBottom={4} alignSelf="flex-start">
      Name
    </Label>
    <DoubleTextInput name="firstName" half="top" width="100%" type="text" placeholder="First Name" />
    <DoubleTextInput name="lastName" half="bottom" width="100%" type="text" placeholder="Last Name" />

    <TextInputField name="email" label="Email" type="email" placeholder="john@planforhealth.com" />
    <RadioGroup
      name="role"
      label="Role"
      size={16}
      marginBottom={0}
      options={[
        { label: 'Standard (Restricted access)', value: Types.UserRole.STANDARD },
        {
          label: 'Super Admin (Manage the platform)',
          value: Types.UserRole.SUPERADMIN
        }
      ]}
    />
  </>
)

export default SystemUserFields
