import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ONBOARDING_DENTISTS, SUBMIT_ONBOARDING_DENTISTS } from 'graphql/_onboarding'
import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Text, Heading, Pill, toaster } from 'evergreen-ui'

import { Card, CardHeader, Icon, Button, FormError, Spinner, DentistCard, useToken } from '@pearly/lib'
import DentistFields from 'components/_fields/dentist-fields'
import { useModal } from 'components/modal-provider'

export type Props = {
  prev: string
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingDentists = ({ prev, next, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { tokenResult, refreshToken } = useToken()
  const isCurrentStep = tokenResult?.claims.onboardingStep === Types.OnboardingStep.DENTISTS

  const showDentistSheet = useModal('dentist')

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingDentists>(
    GET_ONBOARDING_DENTISTS,
    { skip: isCurrentStep || loading }
  )

  const [submit, submitStatus] = useMutation<Types.SubmitOnboardingDentists, Types.SubmitOnboardingDentistsVariables>(
    SUBMIT_ONBOARDING_DENTISTS
  )
  useEffect(() => {
    if (submitStatus.error) {
      toaster.danger('Unable to create dentist')
      setLoading(false)
    }

    if (submitStatus.data) {
      const moveForward = async () => {
        await refreshToken()
        setLoading(false)
      }
      moveForward()
    }
  }, [submitStatus, next, history, setIsForward, refreshToken])

  return (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up your Practice
        </Heading>
        <Pill color="blue">4/4</Pill>
      </CardHeader>

      {isCurrentStep ? (
        <Formik
          initialValues={{ salutation: '' as Types.Salutation, name: '', degree: '' as Types.Degree }}
          onSubmit={({ ...fields }) => {
            setLoading(true)
            submit({ variables: { ...fields } })
          }}
          validationSchema={Yup.object({
            salutation: Yup.string().required('Salutation is required'),
            name: Yup.string()
              .min(4, 'Name must be at least 4 characters')
              .required('Name is required'),
            degree: Yup.string().required('Degree is required')
          })}
        >
          <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
            <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
              <Icon icon={['fad', 'users']} color="primary" size="3x" marginBottom={16} />
              <Text size={500} marginBottom={16}>
                Add Dentist
              </Text>
              <DentistFields />
              <FormError />
            </Pane>

            <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="space-between">
              <Button
                appearance="minimal"
                height={48}
                justifyContent="center"
                iconBefore={['far', 'chevron-left']}
                onClick={() => {
                  setIsForward(false)
                  history.push(prev)
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
                iconAfter={['far', 'chevron-right']}
                isLoading={loading}
              >
                Next
              </Button>
            </Pane>
          </Form>
        </Formik>
      ) : queryLoading || queryError || !queryData ? (
        <Pane display="flex" justifyContent="center" alignItems="center" minHeight={350}>
          <Spinner />
        </Pane>
      ) : (
        <>
          <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
            <Icon icon={['fad', 'users']} color="primary" size="3x" marginBottom={16} />
            <Text size={500} marginBottom={16}>
              Your Dentist
            </Text>
            {queryData.dentists.map((dentist, i) => (
              <DentistCard
                dentist={dentist}
                key={i}
                width="100%"
                marginBottom={8}
                cursor="pointer"
                onClick={() => showDentistSheet({ dentist })}
              />
            ))}
            {/* <Button height={48} marginTop={16} iconBefore={['fas', 'plus']} onClick={() => showDentistSheet()}>
              Add Another Dentist
            </Button> */}
          </Pane>
          <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="space-between">
            <Button
              appearance="minimal"
              height={48}
              justifyContent="center"
              iconBefore={['far', 'chevron-left']}
              onClick={() => {
                setIsForward(false)
                history.push(prev)
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setIsForward(true)
                history.push(next)
              }}
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
              isLoading={loading}
            >
              Next
            </Button>
          </Pane>
        </>
      )}
    </Card>
  )
}

export default OnboardingDentists
