import React, { useEffect, useState, Suspense, lazy } from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Table, Text, Tooltip, Label, Switch } from 'evergreen-ui'
import { Button, Card, CardHeader, TextInput, SelectMenu, IconButton, Spinner } from '@pearly/lib'
import { useModal } from 'components/modal-provider'

import arrayMove from 'array-move'
import { pick } from 'lodash'

const TreatmentSelector = lazy(() => import('components/treatment-selector'))

type Props = {
  plan: Types.PlanSheet_plan
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

type Treatment = { name: string; code: string; quantity: number; value: number; editing?: boolean }

const PlanTreatments = ({ plan, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [treatments, setTreatments] = useState<Treatment[]>(
    plan.treatments.map(treatment => ({ ...treatment, editing: false }))
  )
  const anyEditing = treatments.map(treatment => treatment.editing).reduce((prev, curr) => prev || curr, false)
  const [reordering, setReordering] = useState(false)

  const totalValue = treatments.reduce((prev, curr) => prev + curr.value, 0)

  const [isValueShown, setIsValueShown] = useState(plan.showValue)
  useEffect(() => {
    setIsValueShown(plan.showValue)
  }, [plan.showValue])

  const update = (variables?: Partial<Types.UpdatePlanVariables>) => {
    updatePlan({
      variables: {
        id: plan.id,
        treatments: treatments.map(treatment => pick(treatment, ['name', 'code', 'quantity', 'value'])),
        ...variables
      }
    })
  }

  const updateTreatments = (updatedTreatments: Treatment[]) => {
    updatePlan({
      variables: {
        id: plan.id,
        treatments: updatedTreatments.map(treatment => pick(treatment, ['name', 'code', 'quantity', 'value']))
      }
    })
    setTreatments(updatedTreatments)
  }


  return (
    <SheetBodyLayout>
      <Pane gridArea="body" background="blueTint" overflow="scroll">
        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
          <Table borderRadius="5px" textAlign="left">
            <Table.Head borderTopRightRadius="5px" borderTopLeftRadius="5px" height={44} background="white">
              <Table.TextHeaderCell>Included Treatments</Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="center" flexBasis={108} flexShrink={0} flexGrow={0}>
                CDT Code
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="center" flexBasis={132} flexShrink={0} flexGrow={0}>
                Qty/Yr
              </Table.TextHeaderCell>
              <Table.TextHeaderCell textAlign="right" flexBasis={64} flexShrink={0} flexGrow={0}>
                Total Value
              </Table.TextHeaderCell>
              <Table.Cell flexBasis={82} flexShrink={0} flexGrow={0} justifyContent="flex-end">
                {reordering ? (
                  <IconButton
                    onClick={() => {
                      setReordering(false)
                      update()
                    }}
                    icon={['fas', 'check']}
                    appearance="minimal"
                    intent="success"
                  />
                ) : (
                  <Tooltip content="Reorder Treatments" position="top">
                    <IconButton
                      width={36}
                      isLoading={updatePlanStatus.called && updatePlanStatus.loading}
                      onClick={() => setReordering(true)}
                      icon={['fas', 'sort-circle']}
                      appearance="minimal"
                    />
                  </Tooltip>
                )}
              </Table.Cell>
            </Table.Head>
            <Table.Body>
              {treatments.map((treatment, i) =>
                treatment.editing ? (
                  <Formik
                    key={i}
                    validate={args => {
                      if (!dirty && args !== treatment) setDirty(true)
                    }}
                    initialValues={{ ...treatment }}
                    onSubmit={({ ...treatmentFields }) => {
                      updateTreatments(
                        treatments.map((currTreatment, j) =>
                          j === i ? { ...treatmentFields, editing: false } : currTreatment
                        )
                      )
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required('Name is required'),
                      code: Yup.string().required('Code is required'),
                      quantity: Yup.mixed(),
                      value: Yup.number().required('Total value is required')
                    })}
                  >
                    <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
                      <Table.Row height="auto" minHeight={48} paddingY={0}>
                        <Table.Cell paddingX={8}>
                          <TextInput name="name" width="100%" height={32} marginBottom={0} />
                        </Table.Cell>
                        <Table.TextCell paddingX={8} flexBasis={108} flexShrink={0} flexGrow={0}>
                          <SelectMenu
                            name="code"
                            options={[
                              { label: treatment.code, value: treatment.code },
                              ...(treatment.code !== 'Varies' ? [{ label: 'Varies', value: 'Varies' }] : [])
                            ]}
                            height={32}
                            width={88}
                            marginBottom={0}
                          />
                        </Table.TextCell>
                        <Table.Cell paddingX={8} flexBasis={132} flexShrink={0} flexGrow={0}>
                          <SelectMenu
                            name="quantity"
                            options={[
                              { label: '1', value: 1 },
                              { label: '2', value: 2 },
                              { label: '3', value: 3 },
                              { label: '4', value: 4 },
                              { label: '5', value: 5 },
                              { label: '6', value: 6 },
                              { label: '7', value: 7 },
                              { label: '8', value: 8 },
                              { label: '9', value: 9 },
                              { label: '10', value: 10 },
                              { label: 'N/A', value: -1 },
                              { label: 'As Needed', value: -2 }
                            ]}
                            height={32}
                            buttonWidth="100%"
                            marginBottom={0}
                          />
                        </Table.Cell>
                        <Table.Cell paddingX={8} flexBasis={64} flexShrink={0} flexGrow={0}>
                          <TextInput name="value" type="number" height={32} marginBottom={0} textAlign="right" />
                        </Table.Cell>
                        <Table.Cell flexBasis={82} flexShrink={0} flexGrow={0} justifyContent="flex-end">
                          <IconButton
                            type="button"
                            onClick={() => {
                              showConfirmDialog({
                                body: 'Are you sure you want to remove this treatment?',
                                confirm: () => {
                                  updateTreatments(treatments.filter((_treatment, j) => i !== j))
                                }
                              })
                            }}
                            icon={['fas', 'trash-alt']}
                            appearance="minimal"
                            intent="danger"
                          />
                          <IconButton type="submit" icon={['fas', 'check']} appearance="minimal" intent="success" />
                        </Table.Cell>
                      </Table.Row>
                    </Form>
                  </Formik>
                ) : (
                  <Table.Row key={i} height="auto" minHeight={48}>
                    <Table.TextCell>{treatment.name}</Table.TextCell>
                    <Table.TextCell textAlign="center" flexBasis={108} flexShrink={0} flexGrow={0}>
                      {treatment.code}
                    </Table.TextCell>
                    <Table.TextCell textAlign="center" flexBasis={132} flexShrink={0} flexGrow={0}>
                      {treatment.quantity > 0 ? treatment.quantity : treatment.quantity === -1 ? 'N/A' : 'As Needed'}
                    </Table.TextCell>
                    <Table.TextCell textAlign="right" flexBasis={64} flexShrink={0} flexGrow={0}>
                      ${treatment.value}
                    </Table.TextCell>
                    <Table.Cell flexBasis={82} flexShrink={0} flexGrow={0} justifyContent="flex-end">
                      {reordering ? (
                        <>
                          <IconButton
                            disabled={i < treatments.length - 1 ? false : true}
                            onClick={() => setTreatments(arrayMove(treatments, i, i + 1))}
                            icon={['fas', 'chevron-down']}
                            appearance="minimal"
                          />
                          <IconButton
                            disabled={i > 0 ? false : true}
                            onClick={() => setTreatments(arrayMove(treatments, i, i - 1))}
                            icon={['fas', 'chevron-up']}
                            appearance="minimal"
                          />
                        </>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setTreatments(
                              treatments.map((treatment, j) =>
                                j === i ? { ...treatment, editing: true } : { ...treatment, editing: false }
                              )
                            )
                          }}
                          disabled={updatePlanStatus.called && updatePlanStatus.loading}
                          icon={['fas', 'pencil-alt']}
                          appearance="minimal"
                          display={anyEditing ? 'none' : 'block'}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
              {treatments.length ? (
                <Table.Row height="auto" minHeight={48}>
                  <Table.Cell flexDirection="column" justifyContent="center" alignItems="flex-end">
                    <Text size={300}>
                      <i>{`Total Value: $${totalValue}`}</i>
                    </Text>
                    <Text size={300}>
                      <i>{`Annual Member Savings: $${totalValue -
                        (plan.annualPriceActive ? plan.annualPrice! : plan.monthlyPrice! * 12)}`}</i>
                    </Text>
                  </Table.Cell>
                  <Table.Cell
                    flexBasis={82}
                    flexShrink={0}
                    flexGrow={0}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Tooltip content="Show or hide the Annual Member Savings figure to patients" position="bottom">
                      <Pane>
                        <Switch
                          disabled={updatePlanStatus.loading}
                          marginY={2}
                          checked={isValueShown}
                          onChange={e => {
                            setIsValueShown(e.target.checked)
                            update({ showValue: e.target.checked })
                          }}
                        />
                        <Label size={300} display="block">
                          Show
                        </Label>
                      </Pane>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.TextCell textAlign="center">No treatments included, please add one below!</Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
          <CardHeader>
            <Heading size={500}>Add New Included Treatment</Heading>
          </CardHeader>
          <Pane padding={24}>
            <Suspense fallback={<Spinner />}>
              <TreatmentSelector
                addTreatment={newTreatment =>
                  setTreatments(
                    treatments.concat({
                      ...newTreatment,
                      quantity: -1,
                      value: 0,
                      editing: !anyEditing
                    })
                  )
                }
              />
            </Suspense>
          </Pane>
        </Card>
      </Pane>
      <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          disabled={anyEditing}
          onClick={() => {
            showConfirmDialog({
              body: 'Are you sure you want to save this plan?',
              confirm: () => {
                setReordering(false)
                update()
              }
            })
          }}
          isLoading={updatePlanStatus.called && updatePlanStatus.loading}
          appearance="primary"
          height={48}
          justifyContent="center"
        >
          Save
        </Button>
      </Pane>
    </SheetBodyLayout>
  )
}

export default PlanTreatments

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
