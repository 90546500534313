import React from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, Tooltip, Icon, toaster } from 'evergreen-ui'
import { Card, CardHeader } from '@pearly/lib'


export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    templateCode: string
}

const EmailHelpSheet = ({ isShown, setIsShown, templateCode }: Props) => {
    const variables = [

        // {
        //     name: "#SUPERADMIN#",
        //     description: "Email group of all the Super Admins",
        //     temps: ["l5cc", "hc0c", "ad95", "ga79", "ba90", "e7af", "q89d","q7ce","s475","mf57","nbe0"],
        //     usedFor: ["to", "cc", "bcc"]
        // },
        {
            name: "#PRACTICEADMIN#",
            description: "Email addresses of Admins of the concerned practice account.",
            temps: ["a6f2", "l5cc", "s807", "ad95", "pc67", "c0f0", "ba90", "e7af", "b4a3", "ga79", "hde0", "xb5b", "d1e1", "cbb2"],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        {
            name: "#PRACTICESTANDARDADMIN#",
            description: "Email addresses of Standard Admins of the concerned practice account.",
            temps: [],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        // {
        //     name: "#ENTEREMAILADDRESS#",
        //     description: "For entering email address to whom email is to be sent.",
        //     temps: ["efa3", "e28f"],
        //     usedFor: ["to", "cc", "bcc", "subject", "body"]
        // },
        // {
        //     name: "#ENTERUSERNAME#",
        //     description: "For entering name of the user to whom email will be sent.",
        //     temps: ["efa3"],
        //     usedFor: ["subject", "body"]
        // },
        // {
        //     name: "#USERWELCOMEINVITEURL#",
        //     description: "For giving Welcome URL for invitation.",
        //     temps: ["efa3"],
        //     usedFor: ["subject", "body"]
        // },
        // {
        //     name: "#RESETPASSWORDLINK#",
        //     description: "To share link to reset Password",
        //     temps: ["e28f"],
        //     usedFor: ["subject", "body"]
        // },
        {
            name: "#PRACTICENAME#",
            description: "Name of concerned practice account.",
            temps: ["l5cc", "hc0c", "ad95", "ba90", "efa3", "pc67", "s807", "ga79", "c0f0", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICECODE#",
            description: "Practice Code of the concerned practice account.",
            temps: ["s807", "hc0c", "ga79", "pc67", "ad95", "ba90", "c0f0", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DENTISTNAME#",
            description: "Dentist Name of concerned practice account.",
            temps: ["l5cc", "hc0c", "efa3", "ad95", "pc67", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEPHONE#",
            description: "Practice Office Phone Number of the concerned practice account.",
            temps: ["l5cc", "hc0c", "efa3", "ad95", "pc67", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEEMAIL#",
            description: "Practice Office Email of the concerned practice account.",
            temps: ["l5cc", "hc0c", "efa3", "ad95", "pc67", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["to", "cc", "bcc", "subject", "body"]
        },
        {
            name: "#PRACTICEOFFICEADDRESS#",
            description: "Practice Office Address of the concerned practice account.",
            temps: ["l5cc", "hc0c", "efa3", "pc67", "ad95", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#TDSUBSCRIPTIONACTIVATIONDATE#",
            description: "Trublu Direct subscription activation date.",
            temps: ["l5cc", "hc0c", "efa3", "pc67", "ad95", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#TRUBLUDIRECTACCEPTANCEDATE#",
            description: "Trublu Direct agreement acceptance date.",
            temps: ["l5cc", "hc0c", "efa3", "pc67", "ad95", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#TRUBLUDIRECTCANCELLATIONREQUESTDATE#",
            description: "Trublu Direct subscription cancellation Request date.",
            temps: ["l5cc", "hc0c", "efa3", "pc67", "ad95", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#TRUBLUDIRECTCANCELLATIONDATE#",
            description: "Trublu Direct subscription cancellation date.",
            temps: ["l5cc", "hc0c", "efa3", "pc67", "ad95", "s807", "ga79", "c0f0", "ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#TRUBLUDIRECTPLANNAME#",
            description: "Trublu Direct plan name.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSEREMAIL#",
            description: "DS User Email of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["pc67", "ga79", "c0f0", "hde0"],
            usedFor: ["subject", "body", "to", "cc", "bcc"]
        },
        {
            name: "#DSUSERPRACTICENAME#",
            description: "DS User Name of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["pc67", "ga79", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSERCUSTOMBERNUMBER#",
            description: "DS User Account Number of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["c0f0", "hde0", "q89d"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSERMASTERCUSTOMBERNUMBER#",
            description: "DS User Master Account Number of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["c0f0", "hde0", "q89d"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSERDOCTORNAME#",
            description: "DS User Doctor Name of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["ga79", "pc67", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSERPHONENUMBER#",
            description: "DS User Contact Number of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["pc67", "ga79", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSUSERADDRESS#",
            description: "DS User Addresses of the concerned practice, if practice is having subscription of Dentsply Sirona.",
            temps: ["pc67", "ga79", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSSUBSCRIPTIONACTIVATIONDATE#",
            description: "DS Account subscription activation date.",
            temps: ["pc67", "ga79", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DSSUBSCRIPTIONCANCELLATIONDATE#",
            description: "DS Account subscription cancellation date.",
            temps: ["pc67", "ga79", "c0f0"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERACCOUNTNUMBER#",
            description: "Patterson Account Number of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERMASTERCUSTOMERNUMBER#",
            description: "Patterson Account Master Customer Number of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERACCOUNTNAME#",
            description: "Patterson Account Name of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERCONTACTNUMBER#",
            description: "Patterson Account Contact Number of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERNPI#",
            description: "Patterson Account Master Customer Number of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDUSERADDRESS#",
            description: "Patterson Account Address of the concerned practice, if practice is having subscription of Patterson Dental.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDSUBSCRIPTIONACTIVATIONDATE#",
            description: "Patterson Account subscription activation date.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDCANCELLATIONREQUESTDATE#",
            description: "Patterson Account subscription cancellation request date.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDENROLLMENTDATE#",
            description: "Patterson Account enrollment date.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDCANCELLATIONDATE#",
            description: "Patterson Account subscription cancellation date.",
            temps: ["ba90", "e7af", "b4a3"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PDPLANNAME#",
            description: "Patterson Dental plan name.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDPROCUSTOMERNUMBER#",
            description: "Candid Pro Customer Number of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSERPRACTICENAME#",
            description: "Candid User Practice Name of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSERDOCTORNAME#",
            description: "Candid User Doctor Name of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSEREMAIL#",
            description: "Candid User Doctor Email of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSERADDRESS#",
            description: "Candid User Address of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSEROFFICEPHONENUMBER#",
            description: "Candid User Office Phone Number of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDENROLLMENTDATE#",
            description: "Candid Account subscription Enrollment date.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDSUBMITTEDDATE#",
            description: "Candid Account subscription Submitted date.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDACTIVATIONDATE#",
            description: "Candid Account subscription Activation date.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#CANDIDUSERMEMBERSHIP#",
            description: "Candid User Membership of the concerned practice, if practice is having subscription of Candid.",
            temps: ["p83r", "fw50", "rc1u"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#PLANNAME#",
            description: "Common plan name for payment failure.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#SUBSCRIPTIONACTIVATIONDATE#",
            description: "Common Subscription activation date for payment failure.",
            temps: [],
            usedFor: ["subject", "body"]
        },
        {
            name: "#FAILEDPAYMENTDATE#",
            description: "Failed Payment Date.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#INVOICEDATE#",
            description: "Failed Payment Invoice Date.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
        {
            name: "#DATE#",
            description: "Current date in MMDDYY format.",
            temps: ["hc0c", "c0f0", "ad95", "b4a3", "l5cc", "a6f2", "s807", "ga79", "e28f", "efa3", "pc67", "e7af", "ba90"],
            usedFor: ["subject", "body"]
        },
    ]

    const copyToClipboard = (TextToCopy: any) => {
        var TempText = document.createElement("input");
        TempText.value = TextToCopy;
        document.body.appendChild(TempText);
        TempText.select();

        document.execCommand("copy");
        document.body.removeChild(TempText);
        navigator.clipboard.writeText(TempText.value);
        toaster.success(`${TempText.value} is copied to clipboard`)
    }

    return (
        <>
            <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={733} shouldCloseOnOverlayClick={false}>
                <SheetLayout>
                    <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                        <Pane display="flex" alignItems="center">
                            <Pane marginLeft={16}>
                                <Heading size={600}>
                                    Email Help
                                </Heading>
                                <Text size={400}></Text>
                            </Pane>
                        </Pane>
                        <Text size={400}>
                            {/* <strong>ID: </strong>
                            kk */}
                        </Text>
                    </Pane>
                    <Pane gridArea="body" overflow="hidden" background="blueTint">
                        <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                            <CardHeader>
                                <Heading size={500}>Variables</Heading>
                            </CardHeader>
                            <Pane padding={16} overflow="scroll" height={'calc(100vh - 125px)'}>
                                <Text size={400}>These variable are customised/tailor made solutions for composing the email message  body and each variable can be clicked to copy and add into message body.</Text>
                                <Card backgroundColor="white" elevation={0} marginTop={10} marginBottom={20} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Recipients (To, Cc, and Bcc)</Heading>
                                    </CardHeader>
                                    <Pane padding={10}>

                                        {(variables.length && templateCode) ?
                                            variables
                                            // .filter(item => item.temps.includes(templateCode))
                                                .filter(item => item.usedFor.includes("to") || item.usedFor.includes("cc") || item.usedFor.includes("bcc"))
                                                .map((item, i) =>
                                                    <Text size={500} marginBottom={8} style={{ display: "flex" }}>
                                                        <strong>{i + 1}. </strong>
                                                        <strong style={{ marginLeft: '5px', cursor: "pointer" }} onClick={(e) => copyToClipboard(item.name)} >
                                                            {item.name}:
                                                        </strong>
                                                        <span style={{ marginLeft: '5px' }}>{item.description}</span>
                                                    </Text>
                                                ) :
                                            <></>
                                        }
                                    </Pane>
                                </Card>
                                <Card backgroundColor="white" elevation={0} margin={0} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Subject and Body</Heading>
                                    </CardHeader>
                                    <Pane padding={10}>

                                        {(variables.length && templateCode) ?
                                            variables
                                            // .filter(item => item.temps.includes(templateCode))
                                                .filter(item => item.usedFor.includes("subject") || item.usedFor.includes("body"))
                                                .map((item, i) =>
                                                    <Text size={500} marginBottom={8} style={{ display: "flex" }}>
                                                        <strong>{i + 1}. </strong>
                                                        <strong style={{ marginLeft: '5px', cursor: "pointer" }} onClick={(e) => copyToClipboard(item.name)} >
                                                            {item.name}:
                                                        </strong>
                                                        <span style={{ marginLeft: '5px' }}>{item.description}</span>
                                                    </Text>
                                                ) :
                                            <></>
                                        }
                                    </Pane>
                                </Card>
                            </Pane>
                        </Card>
                    </Pane>
                </SheetLayout>
            </SideSheet>
        </>
    )
}

export default EmailHelpSheet

const SheetLayout = styled.div`
            height: 100%;
            overflow: hidden;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-areas:
            'header'
            'body'
            'footer';
            `