import React from 'react'

import { Label, Pane } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, TextInput } from '@pearly/lib'

export type Props = {
  practice?: boolean
}

const OfficeFields = ({ practice }: Props) => (
  <>
    <TextInputField
      name="name"
      label={practice ? 'Practice Name' : 'Office Name'}
      width="100%"
      type="text"
      placeholder={practice ? `John's Dental` : 'Our Office'}
    />
    <Label marginBottom={4} alignSelf="flex-start">
      Address
    </Label>
    <DoubleTextInput name="address1" half="top" width="100%" type="text" placeholder="1728 Robbins Street" />
    <DoubleTextInput name="address2" half="bottom" width="100%" type="text" placeholder="Suite 10" />
    <TextInput name="city" width="100%" type="text" placeholder="Santa Barbara" />
    <Pane display="flex" justifyContent="space-between">
      <TextInput name="state" type="text" placeholder="CA" marginRight={8} />
      <TextInput name="zip" type="text" placeholder="93101" marginLeft={8} />
    </Pane>
    <TextInputField
      name="phone"
      label="Phone Number"
      width="100%"
      type="text"
      placeholder="8051234567"
      marginBottom={0}
    />
    <TextInputField
      name="email"
      label="Communication Email Address"
      width="100%"
      type="text"
      placeholder="email@address.com"
      marginBottom={0}
    />
  </>
)

export default OfficeFields
