import * as Types from 'types'

export const discountData = [
  {
    name: 'Diagnostic',
    codes: 'D0100-D0999',
    toggleField: 'toggleDiagnostic' as const,
    discountField: 'discountDiagnostic' as const,
    type: Types.DiscountCategory.DIAGNOSTIC
  },
  {
    name: 'Preventive',
    codes: 'D1000-D1999',
    toggleField: 'togglePreventive' as const,
    discountField: 'discountPreventive' as const,
    type: Types.DiscountCategory.PREVENTIVE
  },
  {
    name: 'Restorative',
    codes: 'D2000-D2999',
    toggleField: 'toggleRestorative' as const,
    discountField: 'discountRestorative' as const,
    type: Types.DiscountCategory.RESTORATIVE
  },
  {
    name: 'Endodontics',
    codes: 'D3000-D3999',
    toggleField: 'toggleEndodontics' as const,
    discountField: 'discountEndodontics' as const,
    type: Types.DiscountCategory.ENDODONTICS
  },
  {
    name: 'Periodontics',
    codes: 'D4000-D4999',
    toggleField: 'togglePeriodontics' as const,
    discountField: 'discountPeriodontics' as const,
    type: Types.DiscountCategory.PERIODONTICS
  },
  {
    name: 'Prosthodontics, removable',
    codes: 'D5000-D5899',
    toggleField: 'toggleProsthodonticsRemovable' as const,
    discountField: 'discountProsthodonticsRemovable' as const,
    type: Types.DiscountCategory.PROSTHODONTICS_REMOVABLE
  },
  {
    name: 'Maxillofacial Prosthetics',
    codes: 'D5900-D5999',
    toggleField: 'toggleMaxillofacialProsthetics' as const,
    discountField: 'discountMaxillofacialProsthetics' as const,
    type: Types.DiscountCategory.MAXILLOFACIAL_PROSTHETICS
  },
  {
    name: 'Implant Services',
    codes: 'D6000-D6199',
    toggleField: 'toggleImplantServices' as const,
    discountField: 'discountImplantServices' as const,
    type: Types.DiscountCategory.IMPLANT_SERVICES
  },
  {
    name: 'Prosthodontics, fixed',
    codes: 'D6200-D6999',
    toggleField: 'toggleProsthodonticsFixed' as const,
    discountField: 'discountProsthodonticsFixed' as const,
    type: Types.DiscountCategory.PROSTHODONTICS_FIXED
  },
  {
    name: 'Oral and Maxillofacial Surgery',
    codes: 'D7000-D7999',
    toggleField: 'toggleOralAndMaxillofacialSurgery' as const,
    discountField: 'discountOralAndMaxillofacialSurgery' as const,
    type: Types.DiscountCategory.ORAL_AND_MAXILLOFACIAL_SURGERY
  },
  {
    name: 'Orthodontics',
    codes: 'D8000-D8999',
    toggleField: 'toggleOrthodontics' as const,
    discountField: 'discountOrthodontics' as const,
    type: Types.DiscountCategory.ORTHODONTICS
  },
  {
    name: 'Adjunctive General Services',
    codes: 'D9000-D9999',
    toggleField: 'toggleAdjunctiveGeneralServices' as const,
    discountField: 'discountAdjunctiveGeneralServices' as const,
    type: Types.DiscountCategory.ADJUNCTIVE_GENERAL_SERVICES
  }
]
