
import React from 'react'

import { Text } from 'evergreen-ui'

export type Props = {
    isNewUser: boolean
}

const DSTerms = ({ isNewUser }: Props) => {

    return (
        <>
            {isNewUser
                ?
                <Text>
                    <p><strong>Acknowledgement and Additional Terms</strong></p>
                    <p>By clicking Accept & Submit,</p>

                    <ul>
                        <li>
                            You the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, do hereby consent to the collection, use, processing, and transfer of your personal data from TruBlu Direct, LLC to DENTSPLY SIRONA Inc. and its affiliates, subject to the terms and conditions of Dentsply Sirona’s privacy policy available at https://www.dentsplysirona.com/en/legal/privacy-policy.html for the purpose of facilitating your participation in this program.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, hereby consent to the collection, use, processing, and transfer of your personal data to DENTSPLY SIRONA Inc. and its affiliates, subject to the terms and conditions of Dentsply Sirona’s privacy policy available at https://www.dentsplysirona.com/en/legal/privacy-policy.html for the purpose of facilitating your participation in this program.
                        </li>
                        <br />
                        <li>
                            You the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that participation in Dentsply Sirona’s DS Points Plus program is subject to creation of an account on www.dentsplysirona.com, as well as acceptance of the DS Points Plus terms and conditions available at https://www.dentsplysirona.com/en-us/ds-points-plus-benefits/ds-points-plus-terms-and-conditions.html, which may change from time to time.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that by participating in this program, you will no longer be eligible to participate in Dentsply Sirona’s other rebate programs, with the exception of the One DS+ Points program.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge and understand in the event that if you are affiliated with any other special pricing agreement with Dentsply Sirona (e.g., dental service organization, group purchasing organization, etc.), this practice will be removed from that program and be placed exclusively in the TruBlu Direct, LLC program.
                        </li>
                        <br />
                    </ul>
                </Text>
                :
                <Text>
                    <p><strong>Acknowledgement and Additional Terms</strong></p>

                    <p>By clicking Accept & Submit,</p>

                    <ul>
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, do hereby consent to the collection, use, processing, and transfer of your personal data from TruBlu Direct, LLC to DENTSPLY SIRONA Inc. and its affiliates, subject to the terms and conditions of Dentsply Sirona’s privacy policy available at https://www.dentsplysirona.com/en/legal/privacy-policy.html for the purpose of facilitating your participation in this program.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, hereby consent to the collection, use, processing, and transfer of your personal data to DENTSPLY SIRONA Inc. and its affiliates, subject to the terms and conditions of Dentsply Sirona’s privacy policy available at https://www.dentsplysirona.com/en/legal/privacy-policy.html for the purpose of facilitating your participation in this program.
                        </li>
                        <br />
                        <li>
                            You, the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that participation in Dentsply Sirona’s DS Points Plus program is subject to updating your existing account on www.dentsplysirona.com, as well as acceptance of the DS Points Plus terms and conditions available at https://www.dentsplysirona.com/en-us/ds-points-plus-benefits/ds-points-plus-terms-and-conditions.html, which may change from time to time.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that by participating in this program, you will no longer be eligible to participate in Dentsply Sirona’s other rebate programs, with the exception of the One DS+ Points program.
                        </li>
                        <br />
                        <li>
                            You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge and understand in the event that if you are affiliated with any other special pricing agreement with Dentsply Sirona (e.g., dental service organization, group purchasing organization, etc.), this practice will be removed from that program and be placed exclusively in the TruBlu Direct, LLC program.
                        </li>
                        <br />
                    </ul>
                </Text>
            }
        </>
    )
}

export default DSTerms
