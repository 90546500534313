import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useMutation, useQuery } from '@apollo/react-hooks'
import * as Types from 'types/graphql'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SideSheet, Pane, Avatar, Text, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import CandidUserFields from 'components/_fields/CandidUser-fields'
import { New_CandidAccount, UPDATE_CANDID_ACCOUNT } from 'graphql/_candid-user-sheet'
import { GET_MY_CANDID_ACCOUNT } from 'graphql/_candid-user'
import { GET_SERVICE_SUBSCRIPTION_LIST } from 'graphql/_service_agreement'
import moment from 'moment';
import { useGlobal } from 'components/global-provider'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id?: string
    TBCustomerNumber?: string
    candidProCustomerNumber?: string
    practiceName?: string
    doctorFirstName?: string
    doctorLastName?: string
    doctorEmail?: string
    officePhone?: string
    shippingAddress1?: string
    shippingAddress2?: string
    shippingZip?: number
    shippingCity?: string
    shippingCountry?: string
    shippingState?: Types.State
    candidProProvider?: string
    allowLoginToDoctors?: string
    enrollmentOptionChosen: string
    last6MonthCases?: string
    status?: Types.CandidUserStatus
    membership?: Types.CandidMembership
  }
}

const initialValues = {
  id: '',
  practiceName: '',
  candidProCustomerNumber: '',
  doctorFirstName: '',
  doctorLastName: '',
  doctorEmail: '',
  officePhone: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingZip: '',
  shippingCity: '',
  shippingCountry: '',
  shippingState: null,
  candidProProvider: "NO",
  allowLoginToDoctors: "NO",
  last6MonthCases: '0-3',
  membership: ''
}

const getMembershipStatus = (TBSubDate: string) => {
  const enrolledBefore1stApril = moment(TBSubDate).isBefore(new Date("2024-04-02"));
  if (enrolledBefore1stApril) {
    return moment(new Date()) < moment(new Date("2024-08-02")) ? Types.CandidMembership.Diamond : Types.CandidMembership.Gold
  } else {
    return moment(new Date()).isBefore(moment(TBSubDate).add(92, 'days')) ? Types.CandidMembership.Diamond : Types.CandidMembership.Gold
  }
}

const CandidUserSheet = ({ isShown, setIsShown, user }: Props) => {

  const global = useGlobal()
  const isSuperAdmin = (global.meUser?.google.role === Types.UserRole.SUPERADMIN)
  const showConfirmDialog = useModal('confirm')
  const { loading: subLoading, data: subData } = useQuery<Types.ServiceSubscriptionLists>(GET_SERVICE_SUBSCRIPTION_LIST)
  const subs = subData?.ServicePlanSubscriptions
  const currentSub = (subs && subs.length > 0) ? subs[0] : null;
  const [addNewCandidAcc, addNewStatus] = useMutation<Types.upsertCandidUser, Types.upsertCandidUserVariables>(New_CandidAccount, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyCandidAccount>({ query: GET_MY_CANDID_ACCOUNT })
      console.log('data, cachedData', data, cachedData)
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_CANDID_ACCOUNT,
          data: { myCandidAccount: data?.upsertCandidUser }
        })
      }
    }
  })

  useEffect(() => {
    if (addNewStatus.error) {
      toaster.danger('Unable to create account')
      addNewStatus.error = undefined;
    }
    else if (addNewStatus.data && !addNewStatus.loading) {
      setIsShown(false)
      toaster.success(`Account successfully created!`)
    }
  }, [addNewStatus, setIsShown])

  const [updateCandidAcc, updateStatus] = useMutation<Types.updateCandidAccount, Types.updateCandidAccountVariables>(UPDATE_CANDID_ACCOUNT, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyCandidAccount>({ query: GET_MY_CANDID_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_CANDID_ACCOUNT,
          data: { myCandidAccount: data?.upsertCandidUser }
        })
      }
    }
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update account details')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Account details successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          user
            ? {
              ...initialValues,
              ...user,
            }
            : {
              ...initialValues
            }
        }
        onSubmit={({ ...userFields }) => {
          if (!addNewStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to submit this detail?`,
              confirm: () => {
                if (user) {
                  updateCandidAcc({
                    variables: {
                      id: user.id,
                      practiceName: userFields.practiceName,
                      candidProCustomerNumber: userFields.candidProCustomerNumber,
                      doctorFirstName: userFields.doctorFirstName,
                      doctorLastName: userFields.doctorLastName,
                      doctorEmail: userFields.doctorEmail,
                      shippingAddress1: userFields.shippingAddress1,
                      shippingAddress2: userFields.shippingAddress2,
                      shippingCity: userFields.shippingCity,
                      shippingState: userFields.shippingState,
                      shippingZip: Number(userFields.shippingZip),
                      shippingCountry: userFields.shippingCountry,
                      candidProProvider: userFields.candidProProvider == "YES" ? true : false,
                      officePhone: userFields.officePhone,
                      last6MonthCases: userFields.last6MonthCases,
                      allowLoginToDoctors: userFields.allowLoginToDoctors == "YES" ? true : false,
                      enrollmentOptionChosen: true,
                      industryGroup: 'TruBlu Direct',
                      membership: userFields.membership ? userFields.membership as Types.CandidMembership : getMembershipStatus(currentSub?.acceptDate)
                    }
                  })
                } else {
                  addNewCandidAcc({
                    variables: {
                      practiceName: userFields.practiceName,
                      doctorFirstName: userFields.doctorFirstName,
                      doctorLastName: userFields.doctorLastName,
                      doctorEmail: userFields.doctorEmail,
                      shippingAddress1: userFields.shippingAddress1,
                      shippingAddress2: userFields.shippingAddress2,
                      shippingCity: userFields.shippingCity,
                      shippingState: userFields.shippingState,
                      shippingZip: Number(userFields.shippingZip),
                      shippingCountry: userFields.shippingCountry,
                      candidProProvider: userFields.candidProProvider == "YES" ? true : false,
                      officePhone: userFields.officePhone,
                      last6MonthCases: userFields.last6MonthCases,
                      allowLoginToDoctors: userFields.allowLoginToDoctors == "YES" ? true : false,
                      enrollmentOptionChosen: true,
                      industryGroup: 'TruBlu Direct',
                      membership: getMembershipStatus(currentSub?.acceptDate)
                    }
                  })
                }
              }
            })
          }
        }}
        validationSchema={
          Yup.object({
            practiceName: Yup.string().required('Practice Name is required'),
            doctorFirstName: Yup.string().required('Doctor firstname is required'),
            doctorLastName: Yup.string().required('Doctor Lastname is required'),
            doctorEmail: Yup.string().required('Doctor Email is required'),
            shippingAddress1: Yup.string().required("Shipping Address 1 is required"),
            shippingZip: Yup.number().required("Zip is required"),
            shippingCity: Yup.string().required("Shipping City is required"),
            shippingCountry: Yup.string().required("Shipping Country is required"),
            shippingState: Yup.mixed()
              .required('State is required')
              .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
            officePhone: Yup.string()
              .required('Phone is required')
              .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
            ...(user && isSuperAdmin && { membership: Yup.string().required("Please select membership plan") })
          })
        }
      >

        <Form style={{ height: '100%' }} onScroll={() => { var a: any = document.activeElement; a?.blur(); }}>
          <SheetLayout>
            {user && user.enrollmentOptionChosen == "YES" ? (
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={user?.doctorFirstName! + user?.doctorLastName} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user?.doctorFirstName! + user?.doctorLastName}
                  </Heading>
                  <Text size={400}>Candid Account Details</Text>
                </Pane>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>New customer</Heading>
              </CardHeader>
            )}
            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <CandidUserFields user={user} TBCustomerNumber={user?.TBCustomerNumber} accountStatus={ user?.status == 'ACTIVE' ? true : false} />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {user ? (
                <Button
                  autoFocus
                  isLoading={updateStatus.loading || !!updateStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                >
                  Accept & Submit
                </Button>
              ) : (
                <Button
                  isLoading={addNewStatus.loading || !!addNewStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Accept & Submit
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>

      </Formik>
    </SideSheet >
  )
}

export default CandidUserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`