import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADD_VENDOR, UPDATE_VENDOR, DELETE_VENDOR } from 'graphql/_vendor-sheet'
import { GET_VENDORS, GET_VENDORS_SEARCH } from 'graphql/_vendor-table'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster, Switch, Select } from 'evergreen-ui'

import { Card, CardHeader, Button, FormError, Icon } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'
import VendorFields from 'components/_fields/vendor-fields'

import { TextInputField, FileInputField, RadioGroup, Textarea } from '@pearly/lib'

import { storage } from 'firebase/app'
import cuid from 'cuid'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  vendor?: {
    id: string
    logo: string
    vendor: string
    contactPerson: string
    offer: string
    file: string
    pricing: number | null
    vendorMapped: string
    code: string
    status: string
    vendorContact: {
      phone: string;
      mobile: string;
      fax: string;
      email: string;
      address1: string;
      address2: string;
      city: string;
      state: Types.State;
      zip: string;
      URL: string;
    }
  }
}

const VendorSheet = ({ isShown, setIsShown, vendor }: Props) => {
  const global = useGlobal()

  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM
  const showConfirmDialog = useModal('confirm')
  const [vendorMapped, setVendorMapped] = useState(vendor?.vendorMapped);

  const onVendorMappedSelect = (value: string) => {
    if(value){
      const vendorsMapped = vendors?.vendors.find((item) => item.vendorMapped === value)
      if (vendorsMapped) {
        toaster.danger('This vendor is already selected')
      } else {
        setVendorMapped(value)
      }
    }else{
      setVendorMapped('')
    }
  }

  const { data: vendors } = useQuery<Types.GetVendors>(GET_VENDORS)


  const [createVendor, createStatus] = useMutation<Types.AddVendor, Types.AddVendorVariables>(ADD_VENDOR, {
    refetchQueries: ['getVendorFilterAndSearch', 'vendors']
  })

  // const PaidvendorsId = process.env.REACT_APP_PAID_VENDORS_ID

  useEffect(() => {
    if (createStatus.error) {
      toaster.danger('Unable to create vendor')
      createStatus.error = undefined;
    }
    else if (createStatus.data && !createStatus.loading) {
      setIsShown(false)
      toaster.success(`Vendor successfully created!`)
    }
  }, [createStatus, setIsShown])

  const [updateVendor, updateStatus] = useMutation<Types.UpdateVendor, Types.UpdateVendorVariables>(UPDATE_VENDOR, {
    refetchQueries: ['getVendorFilterAndSearch', 'vendors']
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update user')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`User successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  const [deleteVendor, deleteStatus] = useMutation<Types.DeleteVendor, Types.DeleteVendorVariables>(DELETE_VENDOR, {
    variables: { id: vendor?.id ?? '' },
    refetchQueries: ['getVendorFilterAndSearch', 'vendors']
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Can not delete vendor which is attached with practice')
    if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.success(`Vendor successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  useEffect(() => {
    setLogoUrl(vendor?.logo ?? '')
    setPdfUrl(vendor?.file ?? '')
    if (vendor?.pricing && vendor?.pricing !== 0) {
      setIsPricing(true)
    }
    if (vendor?.status) {
      vendor?.status === 'ACTIVE' ? setStatus(true) : setStatus(false)
    }
  }, [vendor])

  const [logoUrl, setLogoUrl] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [invalidLogoError, setInvalidLogoError] = useState<string>('');
  const [requireLogoError, setRequireLogoError] = useState<string>('');
  const [requirePdfError, setRequirePdfError] = useState<string>('');
  const [isPricing, setIsPricing] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);
  const validateFile = (file: any, type: any) => {
    if (type === "logo") {
      if (["image/png", "image/jpg", "image/jpeg"].includes(file[0]?.type)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const uploadFile = (file: any, type: string) => {

    if (validateFile(file, type)) {
      const path = cuid()
      const fileName = `${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}/${'vendor-' + type}/${path}`

      storage()
        .ref(fileName)
        .put(file[0], { customMetadata: { path } })

      const URL = `https://firebasestorage.googleapis.com/v0/b/active-bolt-321014.appspot.com/o/${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}%2F${'vendor-' + type}%2F${path}?alt=media&token=6ecf6b69-040e-4a20-a32a-a4ec199b5610`;

      if (type === 'logo') {
        setLogoUrl(URL)
        setInvalidLogoError("")
      } else {
        setPdfUrl(URL)
      }
    } else {
      setInvalidLogoError("Please Select valid file format('png', 'jpg', 'jpeg')")
    }

  };

  useEffect(() => {
    if (logoUrl !== '') {
      setRequireLogoError("")
    }
    if (pdfUrl !== '') {
      setRequirePdfError("")
    }
  }, [logoUrl, pdfUrl])

  // useEffect(() => {setisPricing(isPricing)},[isPricing])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick= {false}>
      <Formik
        initialValues={
          vendor
            ? {
              ...vendor,
              phone: vendor.vendorContact.phone,
              mobile: vendor.vendorContact.mobile,
              fax: vendor.vendorContact.fax,
              email: vendor.vendorContact.email,
              address1: vendor.vendorContact.address1,
              address2: vendor.vendorContact.address2,
              city: vendor.vendorContact.city,
              state: vendor.vendorContact.state,
              zip: vendor.vendorContact.zip,
              URL: vendor.vendorContact.URL,
            }
            : {
              logo: '',
              vendor: '',
              contactPerson: '',
              offer: '',
              file: '',
              code: '',
              phone: '',
              mobile: '',
              fax: '',
              email: '',
              address1: '',
              address2: '',
              city: '',
              state: null,
              zip: '',
              URL: '',
              pricing: 0.00
            }
        }
        onSubmit={
          ({ ...vendorFields }) => {
            console.log("vendorFields");
            if (logoUrl !== '' && pdfUrl !== '' && invalidLogoError === "") {
              if (!createStatus.error) {
                showConfirmDialog({
                  body: `Are you sure you want to ${vendor ? 'update' : 'create'} this vendor?`,
                  confirm: () => {
                    console.log("save");

                    if (vendor) {
                      updateVendor({
                        variables: {
                          id: vendor.id,
                          logo: logoUrl ?? vendor.logo,
                          file: pdfUrl ?? vendor.file,
                          vendor: vendorFields.vendor,
                          contactPerson: vendorFields.contactPerson,
                          offer: vendorFields.offer,
                          code: vendorFields.code,
                          phone: vendorFields.phone,
                          mobile: vendorFields.mobile,
                          fax: vendorFields.fax,
                          email: vendorFields.email,
                          address1: vendorFields.address1,
                          address2: vendorFields.address2,
                          city: vendorFields.city,
                          pricing: isPricing ? vendorFields.pricing : 0.00,
                          vendorMapped: isPricing ? vendorMapped : '',
                          state: vendorFields.state,
                          zip: vendorFields.zip,
                          URL: vendorFields.URL,
                          status: status ? Types.VendorStatus.ACTIVE : Types.VendorStatus.INACTIVE
                        }
                      })
                    } else {
                      createVendor({
                        variables: {
                          ...vendorFields,
                          vendorMapped: isPricing ? vendorMapped : '',
                          pricing: isPricing ? vendorFields.pricing : 0.00,
                          logo: logoUrl,
                          file: pdfUrl,
                          status: status ? Types.VendorStatus.ACTIVE : Types.VendorStatus.INACTIVE
                        }
                      })
                    }
                  }
                })
              }
            } else {
              if (invalidLogoError === "") {
                if (isSystemAccount) {
                  if (logoUrl === '') {
                    setRequireLogoError("logo is required")
                  }
                  if (pdfUrl === '') {
                    setRequirePdfError("file is required")
                  }
                }
              }
            }
          }}
        validationSchema={isSystemAccount && Yup.object().shape({
          vendor: Yup.string().required('vendor is required'),
          contactPerson: Yup.string().required('contactPerson is required'),
          offer: Yup.string().max(500, 'offer contains maximum 500 character').required('offer is required'),
          code: Yup.string().required('code is required'),
          // logo: Yup.string().required('logo is required'),
          // file: Yup.string().required('file is required'),


          phone: Yup.string().required('Phone is required')
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),

          mobile: Yup.string().when("phone", {
            is: false,
            then:
              Yup.string().required('Mobile is required')
                .matches(/^[0-9]\d{9}$/, 'Please enter a valid mobile number')
          }),
          email: Yup.string().required('email is required'),
          state: Yup.mixed().required("state is required")
            .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
          pricing: isPricing ? Yup.number().required('pricing is required') : Yup.mixed(),
        })}
      >
        <Form style={{ height: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetLayout>
            {vendor ? (
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={vendor.contactPerson} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {vendor.contactPerson}
                  </Heading>
                  <Text size={400}>Vendor Details</Text>
                </Pane>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add Vendor</Heading>
                {/* <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph> */}
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                {/* <VendorFields /> */}
                <TextInputField name="vendor" label="Vendor" type="text" placeholder="Vendor" disabled={!isSystemAccount} />
                <TextInputField name="contactPerson" label="Contact Person" type="text" placeholder="Contact Person" disabled={!isSystemAccount} />
                <Textarea id="offer" name="offer" type="text" label="Offer" placeholder="Offer" disabled={!isSystemAccount} />
                <TextInputField name="code" type="text" label="Code" placeholder="Code" disabled={!isSystemAccount} />
                {isSystemAccount &&
                  <>
                    <FileInputField name="logo" label="Logo" onChange={(e: any) => { uploadFile(e, 'logo') }} placeholder="Logo" disabled={!isSystemAccount} />
                    {invalidLogoError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{invalidLogoError}</Text></div>}
                    <FileInputField name="file" label="File" onChange={(e: any) => { uploadFile(e, 'file') }} placeholder="File" disabled={!isSystemAccount} />
                  </>
                }

                <TextInputField name="phone" label="Phone" type="text" placeholder="Phone" disabled={!isSystemAccount} />
                <TextInputField name="mobile" label="Mobile" type="text" placeholder="Mobile" disabled={!isSystemAccount} />
                <TextInputField name="fax" label="Fax" type="text" placeholder="Fax" disabled={!isSystemAccount} />
                <TextInputField name="email" label="Email" type="text" placeholder="Email" disabled={!isSystemAccount} />
                <TextInputField name="address1" label="Address1" type="text" placeholder="Address1" disabled={!isSystemAccount} />
                <TextInputField name="address2" label="Address2" type="text" placeholder="Address2" disabled={!isSystemAccount} />
                <TextInputField name="city" label="City" type="text" placeholder="City" disabled={!isSystemAccount} />
                <TextInputField name="state" label="State" type="text" placeholder="State" disabled={!isSystemAccount} />
                <TextInputField name="zip" label="Zip" type="text" placeholder="Zip" disabled={!isSystemAccount} />
                <TextInputField name="URL" label="URL" type="text" placeholder="URL" disabled={!isSystemAccount} />
                {isSystemAccount && <Pane display="flex" justifyContent="start">
                  <Heading size={400} textAlign="start">Status</Heading>
                  <Switch
                    marginBottom={0}
                    marginLeft={22}
                    height={18}
                    checked={status}
                    onChange={(e: any) => {
                      setStatus(!status)
                    }}
                  />
                </Pane>
                }
                {isSystemAccount && <Pane display="flex" justifyContent="start" marginTop={16}>
                  <Heading size={400} textAlign="start">Pricing</Heading>

                  <Switch
                    marginBottom={10}
                    marginLeft={18}
                    height={18}
                    checked={isPricing}
                    onChange={(e: any) => {
                      setIsPricing(!isPricing)
                    }}

                  />
                </Pane>}
                {isSystemAccount && isPricing &&
                  <>
                    <TextInputField name="pricing" label="" type="number" placeholder="Pricing" />
                    <Select style={{ minHeight: '48px' }} height={34} width="100%" value={vendorMapped} onChange={e => onVendorMappedSelect(e.target.value)}>
                      <option value={''} >
                        none
                      </option>
                      <option value={'Patterson_Dental'} >
                        Patterson Dental Program
                      </option>
                    </Select>
                  </>}

                <FormError />
                {requireLogoError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{requireLogoError}</Text></div>}
                {requirePdfError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{requirePdfError}</Text></div>}


              </Card>
            </Pane>
            {isSystemAccount && <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {vendor ? (
                <Pane display="flex" justifyContent="space-between">
                  {!vendorMapped && <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    // visibility={updateStatus.loading || updateStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this user?',
                        confirm: () => {
                          deleteVendor()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>}
                  {/* {!PaidvendorsId?.includes(vendor?.id) && <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    // visibility={updateStatus.loading || updateStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this user?',
                        confirm: () => {
                          deleteVendor()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>} */}
                  <Button
                    autoFocus
                    // isLoading={updateStatus.loading || !!updateStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={createStatus.loading || !!createStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Add Vendor
                </Button>
              )}
            </Pane>}
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default VendorSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
