import gql from 'graphql-tag'

// export const GET_SERVICE_INVOICES_BY_ID = gql`
//   query ServiceInvoiceItemsById($id: ID!) {
//     serviceInvoiceItemsById(id: $id) {
//         id
//         type
//         invoiceDate
//         status
//         invoiceSequence
//         rangeEnd
//         amount
//         rangeStart
//         account{
//           code
//           name
//         }
//         subscriberAccount {
//           id
//           code
//           name
//         }
//     }
//   }
// `

export const GET_FILTERD_SERVICE_INVOICES = gql`
query FilterServiceInvoices($search: String!, $year: String,$month: String, $type: String!) {
  filterServiceInvoices(search: $search, year: $year, month: $month, type: $type) {
            id
            type
            invoiceDate
            status
            invoiceSequence
            rangeEnd
            amount
            rangeStart
            account{
              code
              name
            }
            subscriberAccount {
              id
              code
              name
            }
      }
}
`

export const GET_SERVICE_INVOICES_TABLE = gql`
  query ServiceInvoicesTable {
    serviceInvoices {
        id
        type
        invoiceDate
        status
        invoiceSequence
        rangeEnd
        amount
        rangeStart
        account{
          code
          name
        }
        subscriberAccount {
          id
          code
          name
        }
    }
  }
`
export const GET_SERVICE_INVOICE_EVENT_TABLE = gql`
query ServiceInvoiceEvent($first: Int, $after: String) {
  ServiceInvoiceEvent(first: $first, after: $after) {
        edges {
          cursor
          node {
            id
        type
        invoiceDate
        status
        invoiceSequence
        rangeEnd
        amount
        rangeStart
        account{
          code
          name
        }
        subscriberAccount {
          id
          code
          name
        }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
}
`

export const GET_SERVICE_INVOICE_SEARCH_NAME = gql`
query serviceInvoicesSearchByName($search: String!,$year: String,$month: String,$first: Int, $after: String, $type: String!) {
  serviceInvoicesSearchByName(search: $search, year: $year, month: $month, first: $first, after: $after, type: $type){
    edges {
          cursor
          node {
            id
            type
            invoiceDate
            status
            invoiceSequence
            rangeEnd
            amount
            rangeStart
            account{
              code
              name
            }
            subscriberAccount {
              id
              code
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
  }
}
`

export const GET_PRACTICE_SERVICE_INVOICE_EVENT_TABLE = gql`
query PracticeServiceInvoiceEvent($first: Int, $after: String) {
  PracticeServiceInvoiceEvent(first: $first, after: $after) {
        edges {
          cursor
          node {
            id
        type
        invoiceDate
        status
        invoiceSequence
        rangeEnd
        amount
        rangeStart
        account{
          code
          name
        }
        subscriberAccount {
          id
          code
          name
        }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
}
`

export const GET_PRACTICE_SERVICE_INVOICE_EVENT_BY_FILTER = gql`
query practiceServiceInvoiceEventByFilter($invoiceSequence: String,$status: String, $first: Int, $after: String) {
  practiceServiceInvoiceEventByFilter(invoiceSequence: $invoiceSequence, status: $status, first: $first, after: $after){
    edges {
          cursor
          node {
            id
            type
            invoiceDate
            status
            invoiceSequence
            rangeEnd
            amount
            rangeStart
            subscription {
              plan {
                name
              }
            }
            paymentDueDate
            account{
              code
              name
              offices {
                name
                email
                address1
                address2
                phone
                city
                state
                zip
              }
            }
            subscriberAccount {
              id
              code
              name
              offices {
                name
                email
                address1
                address2
                phone
                city
                state
                zip
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
  }
}
`