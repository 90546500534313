import gql from 'graphql-tag'

export const GET_RECIPT_BY_ID = gql`
    query MemberReceiptByIdQuery($paymentEventId: String!) {
        memberReceipt (paymentEventId: $paymentEventId) {
        amount
        collectingMethod
        contactId
        createdAt
        gatewayTransactionId
        id
        last4
        office {
            address1
            address2
            city
            id
            name
            phone
            state
            zip
        }
        paymentDate
        paymentNote
        paymentStatus
        plan
        secure
        sequenceNumber
        type
        paidVia
        practiceName
        invoiceNumber
        invoiceDate
        dateRangeStart
        dateRangeEnd
    }
}`

export const GET_SERVICE_RECIPT_BY_ID = gql`
    query ServiceReceiptByIdQuery($paymentEventId: String!) {
        serviceReceipt (paymentEventId: $paymentEventId) {
        collectingMethod
        createdAt
        id
        office {
            address1
            address2
            city
            id
            name
            phone
            state
            zip
        }
        paymentDate
        paymentNote
        paymentStatus
        plan
        sequenceNumber
        type
        gatewayTransactionId
        paidVia
        last4
        practiceName
        invoiceNumber
        invoiceDate
        dateRangeStart
        dateRangeEnd
        amount
        account{
            id
            name
        }
        subscriptionID
        serviceInvoiceItems{
            description
            quantity
            unitPrice
            itemType
        }
        refundStatus
        refundedAt
    }
}`

export const REFUND_AMOUNT_SERVICE_PAYMENT_EVENT = gql`
mutation RefundAmountServicePaymentEvent(
  $id: String!
  $paymentStatus: String!
){
  refundAmountServicePaymentEvent(
    id: $id
    paymentStatus :$paymentStatus
  ){
      id,
      paymentStatus,
      creditCard_message
      
  }
}
`

export const TRY_NEW_SERVICE_PAYMENT = gql`
mutation tryNewServicePayment(
    $id: ID!,
    $paymentId: ID!
){
    tryNewServicePayment(
        id: $id,
        paymentId: $paymentId
    ){
        id
        paymentStatus
    }
}`


export const CANCEL_SERVICE_PAYMENT_EVENT = gql`
mutation cancelServicePaymentEvent(
    $Id: ID!
    ){
    cancelServicePaymentEvent(
        id: $Id
    )
    {
        id,
        paymentStatus
    }
}
`

export const TRY_NEW_MEMBER_PAYMENT = gql`
mutation TryNewMemberPayment(
    $id: ID!,
    $paymentId: ID!
){
  tryNewMemberPayment(
        id: $id,
        paymentId: $paymentId
    ){
        id
        paymentStatus
    }
}
`

export const VOID_CONTACT_PAYMENT_EVENT = gql`
  mutation voidContactPaymentEvent($id: ID!, $note: String!) {
    voidContactPaymentEvent(id: $id, note: $note) {
        id
        paymentStatus
    }
  }
`

export const REVERT_CONTACT_PAYMENT_EVENT = gql`
  mutation revertContactPaymentEvent($id: ID!) {
    revertContactPaymentEvent(id: $id) {
        id
        paymentStatus
    }
  }
`