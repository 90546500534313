import React from 'react'
import styled from 'styled-components/macro'

//import * as Types from '../types'
//import { SideSheet } from 'evergreen-ui'
 import { SideSheet, Pane, Tablist, Tab, Avatar, Text, Heading } from 'evergreen-ui'
 import CardHeader from '../card-header'

// import { isJson } from '../utility/transforms'

// import Spinner from '../spinner'

// import { useQuery } from '@apollo/react-hooks'
// import { GET_VIEW_MEMBER_SHEET } from '../graphql/_view-member-sheet'

import ViewPracticeProblems from '../view-practice-payments'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  charge: any
  initialPath?: 'problems'
  errorInfo: any
  confirmSolvedFunctionUpdate: any
  tryNewPaymentFunctionUpdate: (errorInfo: any) => void
  }

const steps = [
  { path: 'problems' as const, component: ViewPracticeProblems.Problems }//,
  //{ path: 'profile' as const, component: ViewMember.Profile },
  //{ path: 'activity' as const, component: ViewMember.Activity }//,
  //{ path: 'dependents' as const, component: ViewMember.Dependents }
]

const ViewPracticePaymentsProblemsSheet = ({ isShown, setIsShown, charge, errorInfo, confirmSolvedFunctionUpdate, tryNewPaymentFunctionUpdate, initialPath = 'problems' }: Props) => {
  const currentPath = initialPath
  const CurrentView = steps.find(step => step.path === currentPath)!.component

  // const { loading, error, data } = useQuery<Types.ViewMemberSheet, Types.ViewMemberSheetVariables>(
  //   GET_VIEW_MEMBER_SHEET,
  //   {
  //     context: { secure: true },
  //     variables: { id: memberId },
  //     skip: !isShown
  //   }
  // )

  // const secureContact =
  // data?.contact?.secure && isJson(data.contact.secure) && (JSON.parse(data.contact.secure) as Types.SecureContact)

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={500}
      shouldCloseOnOverlayClick= {false}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }}
    >
      {console.log(1,charge)}
      <SheetLayout>
      <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
              <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                <Pane display="flex" alignItems="center">
                  <Avatar name={`${charge.subscriberAccount?.name}`} size={40} />
                  <Pane marginLeft={16}>
                    <Heading size={600}>
                    {charge.subscriberAccount?.name}
                  </Heading>
                    <Text size={400}>Payment Problem Overview</Text>
                  </Pane>
                </Pane>
                <Text size={400}>
                <strong>ID: </strong>
                {charge.subscriberAccount?.code}
              </Text>
              </Pane>
              <Pane display="flex" padding={8}>
              <Tablist>
                    <Tab
                      textTransform="uppercase"
                      isSelected={true}
                    >
                      Problems
                    </Tab>
              </Tablist>
              </Pane>
            </CardHeader>
            <Pane gridArea="body" overflow="hidden">
              <CurrentView errorInfo={errorInfo} confirmSolvedFunction={confirmSolvedFunctionUpdate} tryNewPaymentFunction={tryNewPaymentFunctionUpdate}/>
            </Pane>



          {/* 
            <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
              <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                <Pane display="flex" alignItems="center">
                  <Avatar name={`${secureContact.firstName} ${secureContact.lastName}`} size={40} />
                  <Pane marginLeft={16}>
                    <Heading size={600}>
                    {secureContact.firstName} {secureContact.lastName}
                  </Heading>
                    <Text size={400}>Payments Problems Overview</Text>
                  </Pane>
                </Pane>
                <Text size={400}>
                <strong>ID: </strong>
                {data.contact.code.toUpperCase()}
              </Text>
              </Pane>
              <Pane display="flex" padding={8}>
              <Tablist>
                    <Tab
                      textTransform="uppercase"
                      isSelected={true}
                    >
                      Problems
                    </Tab>
              </Tablist>
              </Pane>
            </CardHeader>
            <Pane gridArea="body" overflow="hidden">
              <CurrentView errorInfo={errorInfo} confirmSolvedFunction={confirmSolvedFunctionUpdate} tryNewPaymentFunction={tryNewPaymentFunctionUpdate}/>
            </Pane>*/}
          </SheetLayout>
    </SideSheet> 
  )
}

export default ViewPracticePaymentsProblemsSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
