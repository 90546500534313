import React, { createContext, useContext } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_GLOBAL } from 'graphql/_global'
import * as Types from 'types'

import { FullScreen, Spinner, useToken, CreditCardProvider } from '@pearly/lib'

const Context = createContext<Types.Global>({} as Types.Global)
export const useGlobal = () => useContext(Context)

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { tokenResult } = useToken()
  const { loading, data } = useQuery<Types.Global>(GET_GLOBAL, { skip: !tokenResult })

  const creditCardEngine = {
    engine: "Clearent",
    url: "",
    paymentKey: data?.account?.clearentPaymentKey??""
 }

  return loading || !data ? (
    <FullScreen display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </FullScreen>
  ) : (
    <CreditCardProvider engine={creditCardEngine}>
      <Context.Provider value={data}>
        {children}
      </Context.Provider>
    </CreditCardProvider>
  )
}
