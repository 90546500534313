import React from 'react'

import * as Types from 'types'

import { Label } from 'evergreen-ui'
import { TextInputField, SelectMenu } from '@pearly/lib'

const DentistFields = () => (
  <>
    <Label marginBottom={4} alignSelf="flex-start">
      Salutation
    </Label>
    <SelectMenu
      name="salutation"
      buttonWidth="100%"
      placeholder="Salutation"
      options={[
        { label: 'Dr.', value: Types.Salutation.Dr },
        { label: 'Mr.', value: Types.Salutation.Mr },
        { label: 'Mrs.', value: Types.Salutation.Mrs },
        { label: 'Ms.', value: Types.Salutation.Ms }
      ]}
    />
    <TextInputField name="name" label="Dentist Name" width="100%" type="text" placeholder="John Harris" />
    <Label marginBottom={4} alignSelf="flex-start">
      Degree
    </Label>
    <SelectMenu
      name="degree"
      buttonWidth="100%"
      marginBottom={0}
      placeholder="Degree"
      options={[
        { label: 'DDS', value: Types.Degree.DDS },
        { label: 'DMD', value: Types.Degree.DMD },
        { label: 'None', value: Types.Degree.NONE }
      ]}
    />
  </>
)

export default DentistFields
