
import React from 'react'

import { Text } from 'evergreen-ui'


export type Props = {
    isNewUser: boolean
}

const PDTerms = () => {

    return (
        <>
            <Text>
                <p><strong>Acknowledgement and Additional Terms</strong></p>
                <p>By clicking Accept & Submit,</p>

                <ul>
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, do hereby consent to the collection, use, processing, and transfer of your personal data from TruBlu Direct, LLC to Patterson Dental Supply, Inc.. and its affiliates (“Patterson”), subject to the terms and conditions of Patterson’s privacy policy available at<a href='https://www.pattersoncompanies.com/privacy-policy' target='_blank' > https://www.pattersoncompanies.com/privacy-policy</a> for the purpose of facilitating your participation in this program.
                    </li>
                    <br />
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, do hereby consent to the collection, use, processing, and transfer of your personal data to Patterson Dental Supply, Inc.. and its affiliates (“Patterson”), subject to the terms and conditions of Patterson’s privacy policy available at<a href='https://www.pattersoncompanies.com/privacy-policy' target='_blank' > https://www.pattersoncompanies.com/privacy-policy</a> for the purpose of facilitating your participation in this program.
                    </li>
                    <br />
                    <li>
                        You, the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that participation in the TruBlu Direct, LLC Patterson Program is subject to updating your existing account with Patterson or creating a new account with Patterson, as well as acceptance of the Patterson terms and conditions available as attached below, which may change from time to time.
                    </li>
                    <br />
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge and understand that in the event you are currently part of the Patterson AdvantageTM Program (e.g., Diamond, Sapphire, Emerald, or Preferred), your practice will be removed from that program and be placed exclusively in the TruBlu Direct, LLC (“Blue”) program.
                    </li>
                    <br />
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge and understand that in the event you are affiliated with any other special pricing agreement with Patterson (e.g., dental service organization, group purchasing organization, etc.), this practice will be removed from that program and be placed exclusively in the TruBlu Direct, LLC program.
                    </li>
                    <br />
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Service Agreement, are electing to enroll in the additional Patterson Program under the Terms and Conditions outlined in your TruBlu Direct, LLC Services Agreement in conjunction with the Patterson Addendum below.
                    </li>
                    <br />
                    <li>
                        You, the practice owner as identified in the TruBlu Direct, LLC Service Agreement, can cancel your subscription in the TruBlu Direct – Patterson Program by clicking “Cancel Subscription” in the Patterson Program section (which cancellation will be effective immediately) or by clicking “Cancel Subscription” in your TruBlu Direct Service Agreement (which will cancel your participation in any and all Vendor programs inclusive of the Patterson Program effective the end of that calendar month).
                    </li>
                </ul>

            </Text>

        </>
    )
}

export default PDTerms
