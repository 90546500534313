import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Heading, toaster, Text } from 'evergreen-ui'

import { TextInputField, RadioGroup, FileInputField, Card, CardHeader, Button, FormError, Icon } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { FlyerStatus } from 'types'
import { ADD_FLYER, DELETE_FLYER, UPDATE_FLYER } from 'graphql/_flyer'


import { storage } from 'firebase/app'
import cuid from 'cuid'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  flyer?: {
    id: string
    index: number
    title: string
    url: string
    status: FlyerStatus
  }
}

export interface InitialResource {
  index: number
  title: string
  url: string
  status: FlyerStatus
}

const FlyerSheet = ({ isShown, setIsShown, flyer }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [createFlyer, createStatus] = useMutation<Types.addFlyer, Types.addFlyerVariables>(ADD_FLYER, {
    refetchQueries:['flyerSearch']
  })

  useEffect(() => {
    if (createStatus.error) {
      toaster.danger('Unable to create Flyer')
      createStatus.error = undefined;
    }
    else if (createStatus.data && !createStatus.loading) {
      setIsShown(false)
      toaster.success(`Flyer successfully created!`)
    }
  }, [createStatus, setIsShown])

  const [updateFlyer, updateStatus] = useMutation<Types.updateFlyer, Types.updateFlyerVariables>(UPDATE_FLYER, {
    // refetchQueries:['flyerSearch']
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update Flyer')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Flyer successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  const [deleteFlyer, deleteStatus] = useMutation<Types.deleteFlyer, Types.deleteFlyerVariables>(DELETE_FLYER, {
    // refetchQueries:['flyerSearch']
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete Flyer')
    if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`Flyer successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])


  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [fileExtension, setFileExtension] = useState<string>('');
  const [initialValues, setInitialValues] = useState<InitialResource>({
    index: 0,
    title: '',
    url: '',
    status: FlyerStatus.ACTIVE
  });

  useEffect(() => {
    if(flyer){
      const title = flyer.title.split(".")
      const extension = title.pop()
      setInitialValues({
        ...flyer,
        title: title.join(".")
      })
      if(extension){
        setFileExtension(`.${extension}`)
      }
    }
  } ,[flyer])

  const upload = (file: any) => {

    // if (file[0]?.type.split('/')[0] === 'image') {
    const path = cuid()
    const fileName = `${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}/flyers/${path}`

    storage()
      .ref(fileName)
      .put(file[0], { customMetadata: { path } })

    const URL = `https://firebasestorage.googleapis.com/v0/b/active-bolt-321014.appspot.com/o/${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}%2Fflyers%2F${path}?alt=media`;

    setFileUrl(URL)
    setFileError("")
    if(file && file.length && file[0]?.name){
      let type = file[0].name.split(".").pop()
      setFileExtension(`.${type}`)
    }
    // } else {
    //   setFileError("Please Select valid image file")
    // }
  };

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={ initialValues
          // flyer
          //   ? flyer
          //   : {
          //     index: 0,
          //     title: '',
          //     url: '',
          //     status: FlyerStatus.ACTIVE
          //   }
        }
        onSubmit={({ ...fields }) => {

          if (!fields.url && !fileUrl) {
            setFileError("Please upload File")
            return
          }

          if (!createStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to ${flyer ? 'update' : 'create'} this Flyer?`,
              confirm: () => {
                if (flyer) {
                  updateFlyer({
                    variables: {
                      id: flyer.id,
                      index: fields.index,
                      title: fields.title + fileExtension,
                      url: fileUrl ? fileUrl : fields.url,
                      status: fields.status
                    }
                  })
                } else {
                  createFlyer({
                    variables: {
                      index: fields.index,
                      title: fields.title + fileExtension,
                      url: fileUrl,
                      status: fields.status
                    }
                  })
                }
              }
            })
          }
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          index: Yup.number().required('Index is required'),
          status: Yup.mixed().oneOf([FlyerStatus.ACTIVE, FlyerStatus.INACTIVE])
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {flyer ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Update Flyer</Heading>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add Flyer</Heading>
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <Pane display='flex'>
                  <TextInputField name="title" label="Title" type="text" placeholder="Title" width="80%" />
                  <TextInputField name="extension" label="Type" type="text" value={fileExtension} width="20%" disabled />
                </Pane>
                <FileInputField name="file" label="File" onChange={(e: any) => { upload(e) }} placeholder="File" marginBottom={7} />
                <Text size={300} marginBottom={16}> supported file types: JPEG, JPG, PNG, pdf, xls, ppt, doc</Text>
                {fileError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{fileError}</Text></div>}
                <TextInputField name="index" label="Index" type="number" placeholder="Index" />
                <RadioGroup
                  name="status"
                  label="Status"
                  size={16}
                  marginBottom={0}
                  options={[
                    { label: 'Active', value: FlyerStatus.ACTIVE },
                    { label: 'Inactive', value: FlyerStatus.INACTIVE }
                  ]}
                />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {flyer ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={updateStatus.loading ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this flyer?',
                        confirm: () => {
                          deleteFlyer({ variables: { id: flyer.id } })
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={updateStatus.loading}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={createStatus.loading || !!createStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                >
                  Add Flyer
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default FlyerSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`