import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useMutation, useQuery } from '@apollo/react-hooks'
import * as Types from 'types/graphql'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SideSheet, Pane, Avatar, Text, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import CandidUserActivateFields from 'components/_fields/CandidUser-activate-fields'
import moment from 'moment'
import { ACTIVATE_CANDID_ACCOUNT, GET_MY_CANDID_ACCOUNT } from 'graphql/_candid-user'




export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user:{
    id: string,
    doctorFirstName: string | null
    doctorLastName: string | null
  }
}



const CandidUserActivateSheet = ({ isShown, setIsShown, user }: Props) => {

  const showConfirmDialog = useModal('confirm')
  const [activateAccount, activateAccountStatus] = useMutation<Types.ActivateCandidAccount, Types.ActivateCandidAccountVariables>(ACTIVATE_CANDID_ACCOUNT, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyCandidAccount>({ query: GET_MY_CANDID_ACCOUNT })
      console.log('data, cachedData', data, cachedData)
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_CANDID_ACCOUNT,
          data: { myCandidAccount: data?.activateCandidAccount }
        })
      }
    }
  });

  useEffect(() => {
    if (activateAccountStatus.error) {
      toaster.danger('Unable to update account details')
    }
    else if (activateAccountStatus.data && !activateAccountStatus.loading) {
      setIsShown(false)
      toaster.success(`Account successfully activated!`)
    }
  }, [activateAccountStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
           {
                candidProCustomerNumber: ''
           }
        }
        onSubmit={({ ...userFields }) => {
          if (!activateAccountStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to submit this detail?`,
              confirm: () => {
                  activateAccount({
                    variables: {
                      id: user.id,
                      candidProCustomerNumber: userFields.candidProCustomerNumber
                    }
                  })
                }
              
            })
        }}
      }
        validationSchema={
            Yup.object({
                candidProCustomerNumber: Yup.string().required('CandidPro Customer Number is required'),
            })
        }
      >

        <Form style={{ height: '100%' }} onScroll={() => { var a: any = document.activeElement; a?.blur(); }}>
          <SheetLayout>
              <CardHeader gridArea="header">
                <Avatar name={user?.doctorFirstName! + user?.doctorLastName} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user?.doctorFirstName! + user?.doctorLastName}
                  </Heading>
                </Pane>
              </CardHeader>
            
            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <CandidUserActivateFields />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                <Button
                  isLoading={activateAccountStatus.loading || !!activateAccountStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Activate
                </Button>
            </Pane>
          </SheetLayout>
        </Form>

      </Formik>
    </SideSheet >
  )
}

export default CandidUserActivateSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`