import React from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, Tooltip, toaster } from 'evergreen-ui'
import { Card, CardHeader, Icon } from '@pearly/lib'
import { EmailAttachmentInput, EmailAttachmentsStatus } from 'types'


export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    files: EmailAttachmentInput[]
}

const EmailAttachmentsSheet = ({ isShown, setIsShown, files }: Props) => {

    const viewAttachment = (url: any) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={733} shouldCloseOnOverlayClick={false}>
                <SheetLayout>
                    <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                        <Pane display="flex" alignItems="center">
                            <Pane marginLeft={16}>
                                <Heading size={600}>
                                    Email Attachments
                                </Heading>
                                <Text size={400}></Text>
                            </Pane>
                        </Pane>
                        <Text size={400}>
                            {/* <strong>ID: </strong>
                            kk */}
                        </Text>
                    </Pane>
                    <Pane gridArea="body" overflow="hidden" background="blueTint">

                        <Pane padding={10}>
                            {(files.length) ?
                                files.sort((a: EmailAttachmentInput, b: EmailAttachmentInput) => {
                                    if (a.status === EmailAttachmentsStatus.ACTIVE && b.status === EmailAttachmentsStatus.INACTIVE) {
                                        return -1; // "active" comes before "inactive"
                                    }
                                    if (a.status === EmailAttachmentsStatus.INACTIVE && b.status === EmailAttachmentsStatus.ACTIVE) {
                                        return 1; // "inactive" comes after "active"
                                    }
                                    return 0;
                                }).map((item, i) =>
                                    <Card backgroundColor={item.status === EmailAttachmentsStatus.ACTIVE ? "#d4eee2" : '#ddebf7'} elevation={0} margin={16} padding={0}>
                                        <CardHeader withButton>

                                            <Heading size={400}>{item.name}</Heading>
                                            <Icon onClick={() => viewAttachment(item.url)} color="black" icon={['fad', 'files-medical']} marginRight={4} />
                                        </CardHeader>
                                    </Card>
                                ) :
                                <></>}
                        </Pane>
                    </Pane>
                </SheetLayout>
            </SideSheet>
        </>
    )
}

export default EmailAttachmentsSheet

const SheetLayout = styled.div`
            height: 100%;
            overflow: hidden;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-areas:
            'header'
            'body'
            'footer';
            `