import React, { useState } from 'react'

import { Label, Text, Pane } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, RadioGroup, DSTerms } from '@pearly/lib'
import { useGlobal } from 'components/global-provider'
import * as Types from 'types/graphql'

export type Props = {
  isDifShipAdd: string,
  setIsDifShipAdd: (event: string) => void
}

const DSNewUserFields = ({ isDifShipAdd, setIsDifShipAdd, ...props }: Props) => {

  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM

  return (
    <>
      <Label marginBottom={4} alignSelf="flex-start">
        Name
      </Label>
      <DoubleTextInput name="firstName" half="top" width="100%" type="text" placeholder="User First Name" />
      <DoubleTextInput name="lastName" half="bottom" width="100%" type="text" placeholder="User Last Name" />
      {isSystemAccount &&
        <>
          <Label marginBottom={4} alignSelf="flex-start">Dentsply Sirona Customer Number</Label>
          <TextInputField name="DSCustomerNumber" type="number" placeholder="Customer Number" />
          <Label marginBottom={4} alignSelf="flex-start">Dentsply Sirona Master Customer Number</Label>
          <TextInputField name="DSMasterCustomerNumber" type="number" placeholder="Master Customer Number" />
        </>
      }
      <TextInputField name="email" type="email" placeholder="User Email ID" />
      <Label marginBottom={4} alignSelf="flex-start">Practice Name</Label>
      <TextInputField name="practiceName" type="text" placeholder="Practice Name" />
      <Label marginBottom={4} alignSelf="flex-start">NPI</Label>
      <TextInputField name="NPI" type="text" placeholder="NPI Number" />
      <Label marginBottom={4} alignSelf="flex-start">State License ID</Label>
      <TextInputField name="stateLicenseID" type="number" placeholder="State License ID" />
      <Label marginBottom={4} alignSelf="flex-start">Billing Address</Label>
      <TextInputField name="billingAddress1" type="text" placeholder="Billing Address-(Line 1)" />
      <TextInputField name="billingAddress2" type="text" placeholder="Billing Address-(Line 2) optional" />
      <TextInputField name="billingZip" type="number" placeholder="Billing Address-Zip Code" />
      <TextInputField name="billingCity" type="text" placeholder="Billing Address City" />
      <TextInputField name="billingState" type="text" placeholder="Billing Adddress-State" />
      <TextInputField name="businessPhone" type="text" placeholder="Business Phone" />
      <RadioGroup
        name="isDifferentShippingAddress"
        label="Is Shipping Address different than Billing Address?"
        size={16}
        marginBottom={0}
        value={isDifShipAdd}
        onChange={(event: any) => { setIsDifShipAdd(event) }}
        options={[
          { label: 'Yes', value: 'YES' },
          {
            label: 'No',
            value: 'NO'
          }
        ]}
      />

      {isDifShipAdd === 'YES' &&
        <>
          <Label marginBottom={4} alignSelf="flex-start">Shipping Address</Label>
          <TextInputField name="shippingAddress1" type="text" placeholder="Shipping Address-(Line 1)" />
          <TextInputField name="shippingAddress2" type="text" placeholder="Shipping Address-(Line 2) optional" />
          <TextInputField name="shippingZip" type="number" placeholder="Shipping Address-ZIP Code" />
          <TextInputField name="shippingCity" type="text" placeholder="Shipping Address City" />
          <TextInputField name="shippingState" type="text" placeholder="Shipping Address-State" />
        </>
      }
      <RadioGroup
        name="isSureSmileRegistered"
        label="Are you registered with a SureSmile account?"
        size={16}
        marginBottom={0}
        options={[
          { label: 'Yes', value: 'YES' },
          {
            label: 'No',
            value: 'NO'
          }
        ]}
      />
      <RadioGroup
        name="interestedLearningAboutSureSmile"
        label="Are you interested in learning more about SureSmile?"
        size={16}
        marginBottom={12}
        options={[
          { label: 'Yes', value: 'YES' },
          {
            label: 'No',
            value: 'NO'
          }
        ]}
      />

      <DSTerms isNewUser={true} />
    </>
  )
}

export default DSNewUserFields
