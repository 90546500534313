import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { New_DSAccount, UPDATE_DSACCOUNT } from 'graphql/_ds-user-sheet'
import { GET_MY_DS_ACCOUNT } from 'graphql/_ds-user'

import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, FormError } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import DSNewUserFields from 'components/_fields/DSNewUser-fields'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id: string
    practiceName: string
    DSCustomerNumber: number | null
    DSMasterCustomerNumber: number | null
    NPI: string
    stateLicenseID: number | null
    billingAddress1: string
    billingAddress2: string
    billingZip: number | null
    billingCity: string
    billingState?: Types.State
    businessPhone: string
    isDifferentShippingAddress: string
    shippingAddress1: string
    shippingAddress2: string
    shippingZip: number | null
    shippingCity: string
    shippingState: Types.State
    firstName: string
    lastName: string
    email: string
    isSureSmileRegistered: string
    interestedLearningAboutSureSmile: string
  }
}

const DSNewUserSheet = ({ isShown, setIsShown, user }: Props) => {
  
  const showConfirmDialog = useModal('confirm')

  const [isDifShipAdd, setIsDifShipAdd] = React.useState(user?.isDifferentShippingAddress ?? "NO");

  const [addNewDSAcc, addNewStatus] = useMutation<Types.newDSAccount, Types.newDSAccountVariables>(New_DSAccount, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyDSAccount>({ query: GET_MY_DS_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_DS_ACCOUNT,
          data: { myDSAccount: data?.newDSAccount }
        })
      }
    }
  })

  useEffect(() => {
    if (addNewStatus.error) {
      toaster.danger('Unable to create account')
      addNewStatus.error = undefined;
    }
    else if (addNewStatus.data && !addNewStatus.loading) {
      setIsShown(false)
      toaster.success(`Account successfully created!`)
    }
  }, [addNewStatus, setIsShown])

  const [updateDSAcc, updateStatus] = useMutation<Types.updateDSAccount, Types.updateDSAccountVariables>(UPDATE_DSACCOUNT, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyDSAccount>({ query: GET_MY_DS_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_DS_ACCOUNT,
          data: { myDSAccount: data?.updateDSAccount }
        })
      }
    }
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update account details')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Account details successfully updated!`)
    }
  }, [updateStatus, setIsShown])


  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick= {false}>
      <Formik
        initialValues={
          user
            ? user
            : {
              practiceName: '',
              DSCustomerNumber: null,
              DSMasterCustomerNumber: null,
              NPI: '',
              stateLicenseID: null,
              billingAddress1: '',
              billingAddress2: '',
              billingZip: null,
              billingCity: '',
              billingState: Types.State.AK,
              businessPhone: '',
              isDifferentShippingAddress: 'YES',
              shippingAddress1: '',
              shippingAddress2: '',
              shippingZip: null,
              shippingCity: '',
              shippingState: Types.State.AK,
              firstName: '',
              lastName: '',
              email: '',
              isSureSmileRegistered: 'NO',
              interestedLearningAboutSureSmile: 'NO',
            }
        }
        onSubmit={
          ({ ...userFields }) => {
            if (!addNewStatus.error) {
              showConfirmDialog({
                body: `Are you sure you want to submit this detail?`,
                confirm: () => {
                  if (user) {
                    updateDSAcc({
                      variables: {
                        id: user.id,
                        DSCustomerNumber: userFields.DSCustomerNumber,
                        DSMasterCustomerNumber: userFields.DSMasterCustomerNumber,
                        firstName: userFields.firstName,
                        lastName: userFields.lastName,
                        email: userFields.email,
                        practiceName: userFields.practiceName,
                        NPI: userFields.NPI,
                        stateLicenseID: userFields.stateLicenseID,
                        billingAddress1: userFields.billingAddress1,
                        billingAddress2: userFields.billingAddress2,
                        billingZip: userFields.billingZip,
                        billingCity: userFields.billingCity,
                        billingState: userFields.billingState,
                        businessPhone: userFields.businessPhone,
                        isDifferentShippingAddress: isDifShipAdd == "YES" ? true : false,
                        shippingAddress1: userFields.shippingAddress1,
                        shippingAddress2: userFields.shippingAddress2,
                        shippingZip: userFields.shippingZip,
                        shippingCity: userFields.shippingCity,
                        shippingState: userFields.shippingState,
                        isSureSmileRegistered: userFields.isSureSmileRegistered == "YES" ? true : false,
                        interestedLearningAboutSureSmile: userFields.interestedLearningAboutSureSmile == "YES" ? true : false,
                      }
                    })
                  } else {
                    addNewDSAcc({
                      variables: {
                        practiceName: userFields.practiceName,
                        NPI: userFields.NPI,
                        stateLicenseID: userFields.stateLicenseID,
                        billingAddress1: userFields.billingAddress1,
                        billingAddress2: userFields.billingAddress2,
                        billingZip: userFields.billingZip,
                        billingCity: userFields.billingCity,
                        billingState: userFields.billingState,
                        businessPhone: userFields.businessPhone,
                        isDifferentShippingAddress: isDifShipAdd == "YES" ? true : false,
                        shippingAddress1: userFields.shippingAddress1,
                        shippingAddress2: userFields.shippingAddress2,
                        shippingZip: userFields.shippingZip,
                        shippingCity: userFields.shippingCity,
                        shippingState: userFields.shippingState,
                        firstName: userFields.firstName,
                        lastName: userFields.lastName,
                        email: userFields.email,
                        isSureSmileRegistered: userFields.isSureSmileRegistered == "YES" ? true : false,
                        interestedLearningAboutSureSmile: userFields.interestedLearningAboutSureSmile == "YES" ? true : false
                      }
                    })
                  }
                }
              })
            }
          }}
        validationSchema={
          Yup.object({
            firstName: Yup.string().required('firstName is required'),
            lastName: Yup.string().required('lastName is required'),
            email: Yup.string().required('email is required'),
            billingState: Yup.mixed()
              .required('State is required')
              .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
            businessPhone: Yup.string()
              .required('Phone is required')
              .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
            shippingState: Yup.mixed()
              .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
            stateLicenseID: Yup.number().max(999999999, 'State License ID must be less than 10 digits long').nullable(),
          })
        }
      >
        <Form style={{ height: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetLayout>
            {user ? (
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={user.firstName + user.lastName} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user.firstName + user.lastName}
                  </Heading>
                  <Text size={400}>DS Account Details</Text>
                </Pane>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>New customers</Heading>
                {/* <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph> */}
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <DSNewUserFields isDifShipAdd={isDifShipAdd} setIsDifShipAdd={setIsDifShipAdd} />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {user ? (
                <Button
                  autoFocus
                  isLoading={updateStatus.loading || !!updateStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                >
                  Accept & Submit
                </Button>
              ) : (
                <Button
                  isLoading={addNewStatus.loading || !!addNewStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'user-plus']}
                >
                  Accept & Submit
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default DSNewUserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
