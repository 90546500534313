import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  dsUserFields: gql`
    fragment dsUserFields on DSUser {
      id
      account {
        id
        code
        name
      }
      isNew
      practiceName
      DSCustomerNumber
      DSMasterCustomerNumber
      NPI
      stateLicenseID
      billingAddress1
      billingAddress2
      billingZip
      billingCity
      billingState
      businessPhone
      isDifferentShippingAddress
      shippingAddress1
      shippingAddress2
      shippingZip
      shippingCity
      shippingState
      firstName
      lastName
      email
      isSureSmileRegistered
      interestedLearningAboutSureSmile
      createdAt
      lastUpdated
      status
      inactivateAt
      sentToDS        
      receivedFromDS
      agreementURL
    }
  `
}


export const GET_MY_DS_ACCOUNT = gql`
query GetMyDSAccount {
  myDSAccount {
    ...dsUserFields
  }
}
${fragments.dsUserFields}
`

export const GET_DS_USERS = gql`
query GetDSUsers {
  dsUsers {
    ...dsUserFields
  }
}
${fragments.dsUserFields}
`


export const GET_DS_USER_SEARCH_LIST = gql`
query GetDSUserSearchList($search: String!, $isNew: Boolean!, $first: Int, $after: String) {
  DSUserSearch(search: $search,isNew: $isNew, first: $first, after: $after) {
      edges {
        cursor
        node  {
          id
          account {
          id
          code
          name
          }
          practiceName
          DSCustomerNumber
          DSMasterCustomerNumber
          NPI
          stateLicenseID
          billingAddress1
          billingAddress2
          billingZip
          billingCity
          billingState
          businessPhone
          isDifferentShippingAddress
          shippingAddress1
          shippingAddress2
          shippingZip
          shippingCity
          shippingState
          firstName
          lastName
          email
          isSureSmileRegistered
          interestedLearningAboutSureSmile
          createdAt
          status
          inactivateAt
          sentToDS        
          receivedFromDS
          agreementURL
        }
      }
      pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`

export const DELETE_DS_Account = gql`
  mutation DeleteDSAccount($id: ID!) {
    deleteDSAccount(id: $id) {
      id
    }
  }
`

export const ACTIVATE_DS_Account = gql`
  mutation ActivateDSAccount($id: ID!) {
    activateDSAccount(id: $id) {
    ...dsUserFields
    }
  }
  ${fragments.dsUserFields}
`

export const INACTIVATE_DS_Account = gql`
  mutation InActivateDSAccount($id: ID!) {
    inActivateDSAccount(id: $id) {
    ...dsUserFields
    }
  }
  ${fragments.dsUserFields}
`