import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, toaster, Table } from 'evergreen-ui'
import { Card, CardHeader, Button } from '@pearly/lib'
import { useQuery } from '@apollo/react-hooks'
import { SERVICE_INVOICE_ITEM_BY_SERVICE_INVOICE_ID } from '../../graphql/_serviceInvoiceItem'
import * as Types from 'types'
import moment from 'moment'
import { useModal } from 'components/modal-provider'

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    invoice: any
}

const InvoiceSheet = ({ isShown, setIsShown, invoice }: Props) => {

    
    const { data: itemData } = useQuery<Types.ServiceInvoiceItemByServiceInvoiceId, Types.ServiceInvoiceItemByServiceInvoiceIdVariables>(SERVICE_INVOICE_ITEM_BY_SERVICE_INVOICE_ID, {
        variables: {
            id: invoice.id
        },
        fetchPolicy: 'network-only'
    })
    const AddInvoiceItem = useModal('invoiceItemAddSheet');

    
    return (
        <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={500} shouldCloseOnOverlayClick= {false}>
            <SheetLayout>
                <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                    <Pane display="flex" alignItems="center">
                        <Pane marginLeft={16}>
                            <Heading size={600}>
                                {invoice?.subscriberAccount?.name}
                            </Heading>
                            <Text size={400}>Invoice Overview</Text>
                        </Pane>
                    </Pane>
                    
                    <Text size={400}>
                        {/* <strong>ID: </strong>
                            kk */}
                    </Text>
                </Pane>
                <Pane gridArea="body" overflow="hidden" background="blueTint">
                    <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                        <CardHeader>
                            <Heading size={500}>Invoice Details</Heading>
                        </CardHeader>
                        <Pane padding={16} display="flex" flexDirection="column">
                            <Text size={500} marginBottom={8}>
                                <strong>Name: </strong>
                                {invoice?.subscriberAccount?.name}
                            </Text>

                            <Text size={500} marginBottom={8}>
                                <strong>Type: </strong>
                                {invoice?.type}
                            </Text>
                            <Text size={500} marginBottom={8}>
                                <strong>Status: </strong>
                                {invoice.status}
                            </Text>
                            <Text size={500} marginBottom={8}>
                                <strong>Invoice Date: </strong>
                                {invoice.invoiceDate ? moment.utc(invoice.invoiceDate).format('M/D/YYYY') : ''}
                            </Text>
                            <Text size={500} marginBottom={8}>
                                <strong>Amount: </strong>
                                {invoice.amount}
                            </Text>
                        

                        </Pane>
                    </Card>
                    <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                        <CardHeader withButton>
                            <Heading size={500}>Invoice Items</Heading>
                            <Button
                                appearance="primary"
                                justifyContent="center"
                                iconBefore={['fas', 'plus']}
                                onClick={() => AddInvoiceItem({ invoice: invoice})}
                                
                            >Add Invoice Item</Button>
                        </CardHeader>
                        <Pane padding={16}  display="flex" flexDirection="column">
                            <Table>
                                <Table.Head>
                                    <Table.TextHeaderCell>Quantity</Table.TextHeaderCell>
                                    <Table.TextHeaderCell>Unit Price</Table.TextHeaderCell>
                                    <Table.TextHeaderCell>Description</Table.TextHeaderCell>
                                </Table.Head>
                                <Table.Body height={140}>
                                    {itemData && itemData.serviceInvoiceItemsByServiceInvoiceId.map((item)=>{
                                        return(
                                            <Table.Row key={item.id} >
                                                <Table.TextCell>{item.quantity}</Table.TextCell>
                                                <Table.TextCell>${item.unitPrice}</Table.TextCell>
                                                <Table.TextCell >{item.description}</Table.TextCell>
                                            </Table.Row>
                                        )
                                    })}
                                    
                                </Table.Body>
                            </Table>
                        </Pane>
                    </Card>
                </Pane>
                {/* <Pane
                    gridArea="footer"
                    elevation={0}
                    padding={16}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button marginLeft={0}
                        appearance="primary"
                        justifyContent="center"
                        height={40}
                    >
                        example
                    </Button>
                </Pane> */}
                
            </SheetLayout>
        </SideSheet>
    )
}

export default InvoiceSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body'
    'footer';
`