import gql from 'graphql-tag'

const fragments = {
  planSheetFields: gql`
    fragment planSheetFields on Plan {
      id
      status
      name
      minAge
      maxAge
      perio
      annualPriceActive
      annualPrice
      monthlyPriceActive
      monthlyPrice
      dependentDiscount
      allTimeMemberCount
      setupFee
      isDefault
      treatments {
        id
        name
        code
        quantity
        value
      }
      showValue
      globalDiscountActive
      globalDiscount
      discounts {
        id
        category
        value
      }
      additionalTerms
      stripe {
        createdAt
      }
    }
  `
}



export const GET_PLAN_SHEET = gql`
  query PlanSheet($id: ID!) {
    plan(id: $id) {
      ...planSheetFields
    }
  }
  ${fragments.planSheetFields}
`

export const UPDATE_PLAN = gql`
  mutation UpdatePlan(
    $id: ID!
    $name: String
    $minAgeToggle: Boolean
    $minAge: Int
    $maxAgeToggle: Boolean
    $maxAge: Int
    $dependentDiscountToggle: Boolean
    $dependentDiscount: Int
    $perio: Boolean
    $annualPriceActive: Boolean
    $annualPrice: Float
    $monthlyPriceActive: Boolean
    $monthlyPrice: Float
    $treatments: [TreatmentInput!]
    $showValue: Boolean
    $globalDiscountActive: Boolean
    $globalDiscount: Int
    $discounts: [DiscountInput!]
    $additionalTerms: String
    $setupFee:Float
  ) {
    updatePlan(
      id: $id
      name: $name
      minAgeToggle: $minAgeToggle
      minAge: $minAge
      maxAgeToggle: $maxAgeToggle
      maxAge: $maxAge
      dependentDiscountToggle: $dependentDiscountToggle
      dependentDiscount: $dependentDiscount
      perio: $perio
      annualPriceActive: $annualPriceActive
      annualPrice: $annualPrice
      monthlyPrice: $monthlyPrice
      monthlyPriceActive: $monthlyPriceActive
      treatments: $treatments
      showValue: $showValue
      globalDiscountActive: $globalDiscountActive
      globalDiscount: $globalDiscount
      discounts: $discounts
      additionalTerms: $additionalTerms
      setupFee: $setupFee
    ) {
      ...planSheetFields
    }
  }
  ${fragments.planSheetFields}
`

export const SET_DEFAULT_PLAN = gql`
  mutation SetDefaultPlan(
    $id: ID!
    $isDefault: Boolean!
  ) {
    setDefaultPlan(
      id: $id
      isDefault: $isDefault
    ) {
      ...planSheetFields
    }
  }
  ${fragments.planSheetFields}
`
