import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  dsUserFields: gql`
    fragment dsUserFields on DSUser {
      id
      account {
        id
        code
        name
      }
      isNew
      practiceName
      DSCustomerNumber
      DSMasterCustomerNumber
      NPI
      stateLicenseID
      billingAddress1
      billingAddress2
      billingZip
      billingCity
      billingState
      businessPhone
      isDifferentShippingAddress
      shippingAddress1
      shippingAddress2
      shippingZip
      shippingCity
      shippingState
      firstName
      lastName
      email
      isSureSmileRegistered
      interestedLearningAboutSureSmile
      createdAt
      lastUpdated
      status
      inactivateAt
      sentToDS        
      receivedFromDS
      agreementURL
    }
  `
}

export const Existing_DSAccount = gql`
  mutation existingDSAccount(
    $DSCustomerNumber: Int, $billingZip: Int, $firstName: String, $lastName: String, $email: String,
    $isSureSmileRegistered: Boolean!, $interestedLearningAboutSureSmile: Boolean!
    ) {
      existingDSAccount(
      DSCustomerNumber: $DSCustomerNumber, billingZip: $billingZip, firstName: $firstName, lastName: $lastName, email: $email, 
      isSureSmileRegistered: $isSureSmileRegistered, interestedLearningAboutSureSmile: $interestedLearningAboutSureSmile
      ) {
      ...dsUserFields
    }
  }
  ${fragments.dsUserFields}
`


export const New_DSAccount = gql`
  mutation newDSAccount(
    $practiceName: String, $NPI: String, $stateLicenseID: Int, $billingAddress1: String, $billingAddress2: String,
    $billingZip: Int, $billingCity: String, $billingState: State, $businessPhone: String, $isDifferentShippingAddress: Boolean!,
    $shippingAddress1: String, $shippingAddress2: String,
    $shippingZip: Int, $shippingCity: String, $shippingState: State,
    $firstName: String, $lastName: String, $email: String,
    $isSureSmileRegistered: Boolean, $interestedLearningAboutSureSmile: Boolean
    ) {
      newDSAccount(
        practiceName: $practiceName,NPI: $NPI, stateLicenseID: $stateLicenseID, billingAddress1:$billingAddress1, billingAddress2: $billingAddress2,
        billingZip: $billingZip, billingCity: $billingCity, billingState: $billingState, businessPhone: $businessPhone, isDifferentShippingAddress: $isDifferentShippingAddress,
        shippingAddress1: $shippingAddress1, shippingAddress2: $shippingAddress2,
        shippingZip: $shippingZip, shippingCity: $shippingCity, shippingState: $shippingState,
        firstName: $firstName, lastName: $lastName, email: $email, 
        isSureSmileRegistered: $isSureSmileRegistered, interestedLearningAboutSureSmile: $interestedLearningAboutSureSmile
      ) {
      ...dsUserFields
    }
  }
  ${fragments.dsUserFields}
`

export const UPDATE_DSACCOUNT = gql`
  mutation updateDSAccount(
    $id: ID!, $DSCustomerNumber: Int, $DSMasterCustomerNumber:Int, $practiceName: String, $NPI: String, $stateLicenseID: Int, $billingAddress1: String, $billingAddress2: String,
    $billingZip: Int, $billingCity: String, $billingState: State, $businessPhone: String, $isDifferentShippingAddress: Boolean!,
    $shippingAddress1: String, $shippingAddress2: String,
    $shippingZip: Int, $shippingCity: String, $shippingState: State,
    $firstName: String, $lastName: String, $email: String,
    $isSureSmileRegistered: Boolean, $interestedLearningAboutSureSmile: Boolean
    ) {
      updateDSAccount(
        id: $id, DSCustomerNumber: $DSCustomerNumber, DSMasterCustomerNumber: $DSMasterCustomerNumber ,practiceName: $practiceName,NPI: $NPI, stateLicenseID: $stateLicenseID, billingAddress1:$billingAddress1, billingAddress2: $billingAddress2,
        billingZip: $billingZip, billingCity: $billingCity, billingState: $billingState, businessPhone: $businessPhone, isDifferentShippingAddress: $isDifferentShippingAddress,
        shippingAddress1: $shippingAddress1, shippingAddress2: $shippingAddress2,
        shippingZip: $shippingZip, shippingCity: $shippingCity, shippingState: $shippingState,
        firstName: $firstName, lastName: $lastName, email: $email, 
        isSureSmileRegistered: $isSureSmileRegistered, interestedLearningAboutSureSmile: $interestedLearningAboutSureSmile
      ) {
      ...dsUserFields
    }
  }
  ${fragments.dsUserFields}
`