import React from 'react'

import { Label, Text, Pane } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, RadioGroup, DSTerms } from '@pearly/lib'

import * as Types from 'types/graphql'
import { useGlobal } from 'components/global-provider'

export type Props = {}

const DSExistUserFields = ({ ...props }: Props) => {
  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM

  return (<>
    <Label marginBottom={4} alignSelf="flex-start">Dentsply Sirona Customer Number</Label>
    <TextInputField name="DSCustomerNumber" type="number" placeholder="Customer Number" />
    {isSystemAccount &&
      <>
        <Label marginBottom={4} alignSelf="flex-start">Dentsply Sirona Master Customer Number</Label>
        <TextInputField name="DSMasterCustomerNumber" type="number" placeholder="Master Customer Number" />
      </>
    }
    <Label marginBottom={4} alignSelf="flex-start">Zip Code</Label>
    <TextInputField name="billingZip" type="number" placeholder="Zip Code" />
    <Label marginBottom={4} alignSelf="flex-start">Name</Label>
    <DoubleTextInput name="firstName" half="top" width="100%" type="text" placeholder="User First Name" />
    <DoubleTextInput name="lastName" half="bottom" width="100%" type="text" placeholder="User Last Name" />
    <TextInputField name="email" type="email" placeholder="User Email ID" />
    <RadioGroup
      name="isSureSmileRegistered"
      label="Are you registered with a SureSmile account?"
      size={16}
      marginBottom={0}
      options={[
        { label: 'Yes', value: 'YES' },
        {
          label: 'No',
          value: 'NO'
        }
      ]}
    />
    <RadioGroup
      name="interestedLearningAboutSureSmile"
      label="Are you interested in learning more about SureSmile?"
      size={16}
      marginBottom={12}
      options={[
        { label: 'Yes', value: 'YES' },
        {
          label: 'No',
          value: 'NO'
        }
      ]}
    />

    <DSTerms isNewUser={true} />

  </>)
}

export default DSExistUserFields
