import React from 'react'
import { Text } from 'evergreen-ui'

const CandidTerms = () => {
    return (
        <>
            <Text>
                <p><strong>Acknowledgement and Additional Terms</strong></p>
                <p>By clicking Accept & Submit,</p>
                <ul>
                    <li>
                    You the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, do hereby consent to the collection, use, processing, and transfer of your personal data from TruBlu Direct, LLC to CANDID and its affiliates, subject to the terms and conditions of Candid’s privacy policy available at<a href='https://www.candidco.com/legal/privacy-policy' target='_blank' > https://www.candidco.com/legal/privacy-policy</a> for the purpose of facilitating your participation in this program.
                    </li>
                    <br />
                    <li>
                    You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, hereby consent to the collection, use, processing, and transfer of your personal data to CANDID and its affiliates, subject to the terms and conditions of Candid’s privacy policy available at<a href='https://www.candidco.com/legal/privacy-policy' target='_blank' > https://www.candidco.com/legal/privacy-policy</a> for the purpose of facilitating your participation in this program.
                    </li>
                    <br />
                    <li>
                    You the practice owner, as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that participation in Candid program is subject to creation of an account on<a href='https://www.candidco.com' target='_blank' > https://www.candidco.com</a>, as well as acceptance of the Candid terms and conditions available at<a href='https://www.candidco.com/legal/terms-of-service' target='_blank' > https://www.candidco.com/legal/terms-of-service</a>, which may change from time to time.
                    </li>
                    <br />
                    <li>
                    You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge that by participating in this program, you will no longer be eligible to participate in Candid’s other rebate programs.
                    </li>
                    <br />
                    <li>
                    You, the practice owner as identified in the TruBlu Direct, LLC Services Agreement, acknowledge and understand in the event that if you are affiliated with any other special pricing agreement with Candid, this practice will be removed from that program and be placed exclusively in the TruBlu Direct, LLC program.
                    </li>
                    <br />
                </ul>
            </Text>
        </>
    )
}

export default CandidTerms
