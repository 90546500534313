import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  candidUserFields: gql`
    fragment candidUserFields on CandidUser {
      id
      account {
        id
        code
        name
      }
      candidProCustomerNumber
      practiceName
      doctorFirstName
      doctorLastName
      doctorEmail
      allowLoginToDoctors
      shippingAddress1
      shippingAddress2
      shippingCity
      shippingState
      shippingZip
      shippingCountry
      industryGroup
      candidProProvider
      officePhone
      last6MonthCases
      enrolledOn
      submittedOn
      activatedOn
      endDate
      lastUpdated
      agreementURL
      status
      membership
      enrollmentOptionChosen
    }
  `
}

export const New_CandidAccount = gql`
  mutation upsertCandidUser(
    $id: ID, $practiceName: String, $doctorFirstName: String, $doctorLastName: String, $doctorEmail: String,
    $shippingAddress1: String, $shippingAddress2: String, $shippingCity: String, $shippingState: State, $allowLoginToDoctors: Boolean!,
    $shippingZip: Int, $shippingCountry: String, $industryGroup: String, $candidProProvider: Boolean!, $officePhone: String,
    $last6MonthCases: String, $enrollmentOptionChosen: Boolean!, $membership: CandidMembership
    ) {
      upsertCandidUser(
        id: $id, practiceName: $practiceName, doctorFirstName: $doctorFirstName, doctorLastName:$doctorLastName, doctorEmail: $doctorEmail,
        shippingAddress1: $shippingAddress1, shippingAddress2: $shippingAddress2, shippingCity: $shippingCity, shippingState: $shippingState, allowLoginToDoctors: $allowLoginToDoctors,
        shippingZip: $shippingZip, shippingCountry: $shippingCountry, industryGroup: $industryGroup, candidProProvider: $candidProProvider, officePhone: $officePhone, 
        last6MonthCases: $last6MonthCases, enrollmentOptionChosen: $enrollmentOptionChosen, membership: $membership, 
      ) {
      ...candidUserFields
    }
  }
  ${fragments.candidUserFields}
`

export const UPDATE_CANDID_ACCOUNT = gql`
  mutation updateCandidAccount(
    $id: ID, $candidProCustomerNumber: String, $practiceName: String, $doctorFirstName: String, $doctorLastName: String, $doctorEmail: String,
    $shippingAddress1: String, $shippingAddress2: String, $shippingCity: String, $shippingState: State, $allowLoginToDoctors: Boolean!,
    $shippingZip: Int, $shippingCountry: String, $industryGroup: String, $candidProProvider: Boolean!, $officePhone: String,
    $last6MonthCases: String, $enrollmentOptionChosen: Boolean!, $membership: CandidMembership
    ) {
      upsertCandidUser(
        id: $id, candidProCustomerNumber: $candidProCustomerNumber, practiceName: $practiceName, doctorFirstName: $doctorFirstName, doctorLastName:$doctorLastName, doctorEmail: $doctorEmail,
        shippingAddress1: $shippingAddress1, shippingAddress2: $shippingAddress2, shippingCity: $shippingCity, shippingState: $shippingState, allowLoginToDoctors: $allowLoginToDoctors,
        shippingZip: $shippingZip, shippingCountry: $shippingCountry, industryGroup: $industryGroup, candidProProvider: $candidProProvider, officePhone: $officePhone, 
        last6MonthCases: $last6MonthCases, enrollmentOptionChosen: $enrollmentOptionChosen, membership: $membership, 
      ) {
      ...candidUserFields
    }
  }
  ${fragments.candidUserFields}
`