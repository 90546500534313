
import gql from 'graphql-tag'

export const GET_SERVICE_PLANS = gql`
  query ServicePlans {
    servicePlans {
      id
      name
      annualPriceActive
      annualPrice
      monthlyPriceActive
      monthlyPrice
      additionalServiceFeeValue
      setupFee
      status
    }
  }
`

export const GET_RECIPT = gql`
query ServiceSubscriptions {
  Receipt {
    id
    plan {
        id
        name
        annualPriceActive
    }
    account {
        id
        name
        programName
        clearentPaymentKey
    }
    startDate
    endDate
    firstBilling
    setupFee
    fee
    status
    lastPaymentDate
    isEnabled
    isCancelled
    acceptDate
    creditCard_desc
    creditCard_exp
    creditCard_lastfour
    creditCard_token
    creditCard_type
    fullName
    acceptDate
    practice {
      practiceName
      accountId
    }
    subscriberId
    paymentEvent {
      gatewayResponse
      sequenceNumber
      paymentAmount
    }
  }
}
`

export const GET_SERVICE_PLAN = gql`
  query ServicePlan($id: ID!) {
    plan(id: $id) {
      id
      name
      annualPriceActive
      annualPrice
      monthlyPriceActive
      monthlyPrice
      setupFee
      additionalServiceFeeValue
    }
  }
`

export const UPDATE_SERVICE_SUBSCRIPTION_CARD = gql`
  mutation UpdateServicePlanSubscriptionCard($id: ID!
    $creditCard_token: String!
    $creditCard_type: String!
    $creditCard_desc: String!
    $creditCard_lastfour: String!
    $creditCard_exp: String!
   ) {
    updateServicePlanSubscriptionCard(id: $id,  creditCard_token: $creditCard_token, creditCard_type: $creditCard_type,
      creditCard_desc: $creditCard_desc, creditCard_lastfour: $creditCard_lastfour, creditCard_exp: $creditCard_exp
    ) {
      id
      creditCard_type
      creditCard_token
      creditCard_lastfour
      creditCard_exp
    }
  }
`

export const DELETE_SERVICE_PLAN = gql`
  mutation DeleteServicePlan($id: ID!) {
    deleteServicePlan(id: $id) {
      id
    }
  }
`

export const GET_FIRST_OFFICE_SERVICE_PLAN = gql`
  query FirstOfficeServicePlan {
    firstOffice {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
    }
  }
`