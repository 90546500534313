/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateContactClearentPlanID
// ====================================================

export interface updateContactClearentPlanID_updateContactClearentPlanID {
  __typename: "Contact";
  code: string;
  id: string;
  planTerm: PlanTerm | null;
  secure: string;
  secureNotes: string | null;
}

export interface updateContactClearentPlanID {
  updateContactClearentPlanID: updateContactClearentPlanID_updateContactClearentPlanID | null;
}

export interface updateContactClearentPlanIDVariables {
  clearent_planid: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewServicePayment
// ====================================================

export interface NewServicePayment_newServicePayment {
  __typename: "ServicePaymentEvent";
  id: string;
}

export interface NewServicePayment {
  newServicePayment: NewServicePayment_newServicePayment;
}

export interface NewServicePaymentVariables {
  type: paymentType;
  subscriptionId?: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_token?: string | null;
  creditCard_type?: string | null;
  creditCard_lastfour?: string | null;
  creditCard_message?: string | null;
  creditCard_transStatus?: string | null;
  creditCard_exp?: string | null;
  paymentAmount: number;
  paymentStatus: paymentStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContractSheet
// ====================================================

export interface ContractSheet_plan_account {
  __typename: "Account";
  name: string;
}

export interface ContractSheet_plan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface ContractSheet_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface ContractSheet_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  annualDependentPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyDependentPrice: number | null;
  dependentDiscount: number | null;
  setupFee: number | null;
  additionalServiceFeeValue: number | null;
  account: ContractSheet_plan_account | null;
  treatments: ContractSheet_plan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: ContractSheet_plan_discounts[];
  additionalTerms: string | null;
}

export interface ContractSheet {
  plan: ContractSheet_plan | null;
}

export interface ContractSheetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DependentCardList
// ====================================================

export interface DependentCardList_dependents_subscriptions_plan {
  __typename: "Plan";
  id: string;
  name: string;
  dependentDiscount: number | null;
  annualDependentPrice: number | null;
  monthlyDependentPrice: number | null;
}

export interface DependentCardList_dependents_subscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  status: PlanSubscriptionStatus;
  creditCard_type: string | null;
  plan: DependentCardList_dependents_subscriptions_plan | null;
  planId: string | null;
  planTerm: PlanTerm | null;
  collectingMethod: collectingMethod;
  renewalSubscriptionId: string | null;
}

export interface DependentCardList_dependents {
  __typename: "Contact";
  id: string;
  code: string;
  secure: string;
  planTerm: PlanTerm | null;
  subscriptions: DependentCardList_dependents_subscriptions[] | null;
}

export interface DependentCardList {
  dependents: DependentCardList_dependents[];
}

export interface DependentCardListVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnrollMemberIdentity
// ====================================================

export interface EnrollMemberIdentity {
  enrollContactIdentity: string;
}

export interface EnrollMemberIdentityVariables {
  idToken: string;
  firstName: string;
  lastName: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteMember
// ====================================================

export interface InviteMember {
  inviteMember: boolean;
}

export interface InviteMemberVariables {
  emailOrPhone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendMemberWelcomeEmail
// ====================================================

export interface resendMemberWelcomeEmail {
  resendMemberWelcomeEmail: boolean;
}

export interface resendMemberWelcomeEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContactPlanSubscriptions
// ====================================================

export interface GetContactPlanSubscriptions_getContactPlanSubscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  creditCard_lastfour: string | null;
}

export interface GetContactPlanSubscriptions {
  getContactPlanSubscriptions: GetContactPlanSubscriptions_getContactPlanSubscriptions | null;
}

export interface GetContactPlanSubscriptionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsTable
// ====================================================

export interface PaymentsTable_chargesConnection_edges_node {
  __typename: "Payment";
  id: string;
  accountId: string;
  memberId: string;
  secure: string;
  createdAt: number;
  grossAmount: number;
  processingFees: number;
  netAmount: number;
  status: string;
  paymentErrorMessage: string | null;
  creditCard_lastfour: string | null;
  creditCard_type: string | null;
  creditCard_exp: string | null;
  paymentDate: number | null;
  collectingMethod: string | null;
}

export interface PaymentsTable_chargesConnection_edges {
  __typename: "PaymentEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PaymentsTable_chargesConnection_edges_node;
}

export interface PaymentsTable_chargesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PaymentsTable_chargesConnection {
  __typename: "PaymentConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (PaymentsTable_chargesConnection_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PaymentsTable_chargesConnection_pageInfo;
}

export interface PaymentsTable {
  chargesConnection: PaymentsTable_chargesConnection;
}

export interface PaymentsTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePaymentFailedEvents
// ====================================================

export interface ServicePaymentFailedEvents_ServicePaymentFailedEvents_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
}

export interface ServicePaymentFailedEvents_ServicePaymentFailedEvents {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: ServicePaymentFailedEvents_ServicePaymentFailedEvents_subscriberAccount | null;
}

export interface ServicePaymentFailedEvents {
  ServicePaymentFailedEvents: ServicePaymentFailedEvents_ServicePaymentFailedEvents[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelServicePaymentEvent
// ====================================================

export interface CancelServicePaymentEvent_cancelServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
}

export interface CancelServicePaymentEvent {
  cancelServicePaymentEvent: CancelServicePaymentEvent_cancelServicePaymentEvent;
}

export interface CancelServicePaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RetryServicePaymentEvent
// ====================================================

export interface RetryServicePaymentEvent_retryServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
  creditCard_message: string | null;
}

export interface RetryServicePaymentEvent {
  retryServicePaymentEvent: RetryServicePaymentEvent_retryServicePaymentEvent;
}

export interface RetryServicePaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContactPaymentsEventsGroupLast
// ====================================================

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges_node {
  __typename: "PaymentProblems";
  id: string;
  accountId: string;
  memberId: string;
  secure: string;
  createdAt: number;
  grossAmount: number;
  processingFees: number;
  netAmount: number;
  status: string;
  paymentErrorMessage: string | null;
  creditCard_lastfour: string | null;
  creditCard_type: string | null;
  creditCard_exp: string | null;
  subscriptionId: string | null;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges {
  __typename: "PaymentProblemsEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges_node;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast {
  __typename: "PaymentProblemsConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_pageInfo;
}

export interface GetContactPaymentsEventsGroupLast {
  getContactPaymentsEventsGroupLast: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast;
}

export interface GetContactPaymentsEventsGroupLastVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelContactPaymentEvent
// ====================================================

export interface CancelContactPaymentEvent_cancelContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface CancelContactPaymentEvent {
  cancelContactPaymentEvent: CancelContactPaymentEvent_cancelContactPaymentEvent;
}

export interface CancelContactPaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanCardList
// ====================================================

export interface PlanCardList_livePlans_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  quantity: number;
}

export interface PlanCardList_livePlans_discounts {
  __typename: "Discount";
  id: string;
}

export interface PlanCardList_livePlans {
  __typename: "Plan";
  id: string;
  name: string;
  perio: boolean | null;
  minAge: number | null;
  maxAge: number | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  annualDependentPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyDependentPrice: number | null;
  dependentDiscount: number | null;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  treatments: PlanCardList_livePlans_treatments[];
  discounts: PlanCardList_livePlans_discounts[];
}

export interface PlanCardList {
  livePlans: PlanCardList_livePlans[];
}

export interface PlanCardListVariables {
  accountId?: string | null;
  age?: number | null;
  perio?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MemberReceiptByIdQuery
// ====================================================

export interface MemberReceiptByIdQuery_memberReceipt_office {
  __typename: "Office";
  address1: string;
  address2: string | null;
  city: string;
  id: string;
  name: string;
  phone: string;
  state: State;
  zip: string;
}

export interface MemberReceiptByIdQuery_memberReceipt {
  __typename: "MemberReceipt";
  amount: number | null;
  collectingMethod: collectingMethod;
  contactId: string;
  createdAt: string | null;
  gatewayTransactionId: string | null;
  id: string | null;
  last4: string | null;
  office: MemberReceiptByIdQuery_memberReceipt_office;
  paymentDate: string | null;
  paymentNote: string | null;
  paymentStatus: paymentStatus;
  plan: string | null;
  secure: string | null;
  sequenceNumber: number | null;
  type: paymentType;
  paidVia: string | null;
  practiceName: string | null;
  invoiceNumber: string | null;
  invoiceDate: string | null;
  dateRangeStart: string | null;
  dateRangeEnd: string | null;
}

export interface MemberReceiptByIdQuery {
  memberReceipt: MemberReceiptByIdQuery_memberReceipt | null;
}

export interface MemberReceiptByIdQueryVariables {
  paymentEventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServiceReceiptByIdQuery
// ====================================================

export interface ServiceReceiptByIdQuery_serviceReceipt_office {
  __typename: "Office";
  address1: string;
  address2: string | null;
  city: string;
  id: string;
  name: string;
  phone: string;
  state: State;
  zip: string;
}

export interface ServiceReceiptByIdQuery_serviceReceipt_account {
  __typename: "Account";
  id: string;
  name: string;
}

export interface ServiceReceiptByIdQuery_serviceReceipt_serviceInvoiceItems {
  __typename: "ServiceInvoiceItem";
  description: string | null;
  quantity: number | null;
  unitPrice: number | null;
  itemType: ItemType;
}

export interface ServiceReceiptByIdQuery_serviceReceipt {
  __typename: "ServiceReceipt";
  collectingMethod: collectingMethod;
  createdAt: string | null;
  id: string | null;
  office: ServiceReceiptByIdQuery_serviceReceipt_office;
  paymentDate: string | null;
  paymentNote: string | null;
  paymentStatus: paymentStatus;
  plan: string | null;
  sequenceNumber: number | null;
  type: paymentType;
  gatewayTransactionId: string | null;
  paidVia: string | null;
  last4: string | null;
  practiceName: string | null;
  invoiceNumber: string | null;
  invoiceDate: string | null;
  dateRangeStart: string | null;
  dateRangeEnd: string | null;
  amount: number | null;
  account: ServiceReceiptByIdQuery_serviceReceipt_account;
  subscriptionID: string | null;
  serviceInvoiceItems: ServiceReceiptByIdQuery_serviceReceipt_serviceInvoiceItems[] | null;
  refundStatus: string | null;
  refundedAt: string | null;
}

export interface ServiceReceiptByIdQuery {
  serviceReceipt: ServiceReceiptByIdQuery_serviceReceipt | null;
}

export interface ServiceReceiptByIdQueryVariables {
  paymentEventId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefundAmountServicePaymentEvent
// ====================================================

export interface RefundAmountServicePaymentEvent_refundAmountServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
  creditCard_message: string | null;
}

export interface RefundAmountServicePaymentEvent {
  refundAmountServicePaymentEvent: RefundAmountServicePaymentEvent_refundAmountServicePaymentEvent;
}

export interface RefundAmountServicePaymentEventVariables {
  id: string;
  paymentStatus: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: tryNewServicePayment
// ====================================================

export interface tryNewServicePayment_tryNewServicePayment {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
}

export interface tryNewServicePayment {
  tryNewServicePayment: tryNewServicePayment_tryNewServicePayment;
}

export interface tryNewServicePaymentVariables {
  id: string;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelServicePaymentEvent
// ====================================================

export interface cancelServicePaymentEvent_cancelServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
}

export interface cancelServicePaymentEvent {
  cancelServicePaymentEvent: cancelServicePaymentEvent_cancelServicePaymentEvent;
}

export interface cancelServicePaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TryNewMemberPayment
// ====================================================

export interface TryNewMemberPayment_tryNewMemberPayment {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface TryNewMemberPayment {
  tryNewMemberPayment: TryNewMemberPayment_tryNewMemberPayment;
}

export interface TryNewMemberPaymentVariables {
  id: string;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: voidContactPaymentEvent
// ====================================================

export interface voidContactPaymentEvent_voidContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface voidContactPaymentEvent {
  voidContactPaymentEvent: voidContactPaymentEvent_voidContactPaymentEvent;
}

export interface voidContactPaymentEventVariables {
  id: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: revertContactPaymentEvent
// ====================================================

export interface revertContactPaymentEvent_revertContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface revertContactPaymentEvent {
  revertContactPaymentEvent: revertContactPaymentEvent_revertContactPaymentEvent;
}

export interface revertContactPaymentEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePlans
// ====================================================

export interface ServicePlans_servicePlans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  additionalServiceFeeValue: number | null;
  setupFee: number | null;
  status: PlanStatus;
}

export interface ServicePlans {
  servicePlans: ServicePlans_servicePlans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServiceSubscriptions
// ====================================================

export interface ServiceSubscriptions_Receipt_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
}

export interface ServiceSubscriptions_Receipt_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface ServiceSubscriptions_Receipt_practice {
  __typename: "prac";
  practiceName: string;
  accountId: string;
}

export interface ServiceSubscriptions_Receipt_paymentEvent {
  __typename: "payment";
  gatewayResponse: string;
  sequenceNumber: string;
  paymentAmount: number;
}

export interface ServiceSubscriptions_Receipt {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: ServiceSubscriptions_Receipt_plan | null;
  account: ServiceSubscriptions_Receipt_account;
  startDate: any | null;
  endDate: any | null;
  firstBilling: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  lastPaymentDate: any | null;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  practice: ServiceSubscriptions_Receipt_practice[] | null;
  subscriberId: string;
  paymentEvent: ServiceSubscriptions_Receipt_paymentEvent[] | null;
}

export interface ServiceSubscriptions {
  Receipt: ServiceSubscriptions_Receipt[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePlan
// ====================================================

export interface ServicePlan_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  setupFee: number | null;
  additionalServiceFeeValue: number | null;
}

export interface ServicePlan {
  plan: ServicePlan_plan | null;
}

export interface ServicePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateServicePlanSubscriptionCard
// ====================================================

export interface UpdateServicePlanSubscriptionCard_updateServicePlanSubscriptionCard {
  __typename: "ServicePlanSubscription";
  id: string;
  creditCard_type: string | null;
  creditCard_token: string | null;
  creditCard_lastfour: string | null;
  creditCard_exp: string | null;
}

export interface UpdateServicePlanSubscriptionCard {
  updateServicePlanSubscriptionCard: UpdateServicePlanSubscriptionCard_updateServicePlanSubscriptionCard;
}

export interface UpdateServicePlanSubscriptionCardVariables {
  id: string;
  creditCard_token: string;
  creditCard_type: string;
  creditCard_desc: string;
  creditCard_lastfour: string;
  creditCard_exp: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteServicePlan
// ====================================================

export interface DeleteServicePlan_deleteServicePlan {
  __typename: "ServicePlanSubscription";
  id: string;
}

export interface DeleteServicePlan {
  deleteServicePlan: DeleteServicePlan_deleteServicePlan;
}

export interface DeleteServicePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FirstOfficeServicePlan
// ====================================================

export interface FirstOfficeServicePlan_firstOffice {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
}

export interface FirstOfficeServicePlan {
  firstOffice: FirstOfficeServicePlan_firstOffice;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewMemberSheet
// ====================================================

export interface ViewMemberSheet_contact_account {
  __typename: "Account";
  clearentPaymentKey: string | null;
}

export interface ViewMemberSheet_contact_plan {
  __typename: "Plan";
  id: string;
  name: string;
  status: PlanStatus;
  annualPrice: number | null;
  annualDependentPrice: number | null;
  monthlyPrice: number | null;
  monthlyDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface ViewMemberSheet_contact_subscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  creditCard_token: string | null;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_exp: string | null;
  creditCard_desc: string | null;
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  status: PlanSubscriptionStatus;
  collectingMethod: collectingMethod;
  renewalSubscriptionId: string | null;
}

export interface ViewMemberSheet_contact_parent {
  __typename: "Contact";
  id: string;
}

export interface ViewMemberSheet_contact {
  __typename: "Contact";
  id: string;
  code: string;
  secure: string;
  account: ViewMemberSheet_contact_account;
  planTerm: PlanTerm | null;
  plan: ViewMemberSheet_contact_plan | null;
  subscriptions: ViewMemberSheet_contact_subscriptions[] | null;
  parent: ViewMemberSheet_contact_parent | null;
}

export interface ViewMemberSheet {
  contact: ViewMemberSheet_contact | null;
}

export interface ViewMemberSheetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewMemberSheetActivity
// ====================================================

export interface ViewMemberSheetActivity_contact_stripe_charges {
  __typename: "StripeCharges";
  amount: number;
  createdAt: number;
  status: string;
  last4: string;
  plan: string;
}

export interface ViewMemberSheetActivity_contact_stripe {
  __typename: "StripeContactFields";
  charges: ViewMemberSheetActivity_contact_stripe_charges[] | null;
}

export interface ViewMemberSheetActivity_contact {
  __typename: "Contact";
  id: string;
  secureNotes: string | null;
  stripe: ViewMemberSheetActivity_contact_stripe;
}

export interface ViewMemberSheetActivity {
  contact: ViewMemberSheetActivity_contact | null;
}

export interface ViewMemberSheetActivityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewMemberContactPaymentAuditLog
// ====================================================

export interface ViewMemberContactPaymentAuditLog_PaymentAuditLogsForContact {
  __typename: "ContactPaymentAuditLog";
  accountId: string;
  contactId: string;
  createdAt: any;
  id: string;
  paymentEventId: string | null;
  type: contactPaymentAuditLogType;
  user: string | null;
  log: string;
}

export interface ViewMemberContactPaymentAuditLog {
  PaymentAuditLogsForContact: ViewMemberContactPaymentAuditLog_PaymentAuditLogsForContact[];
}

export interface ViewMemberContactPaymentAuditLogVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: memberBillingHistory
// ====================================================

export interface memberBillingHistory_memberBillingHistory {
  __typename: "MemberBillingHistory";
  amount: number | null;
  createdAt: string | null;
  id: string | null;
  last4: string | null;
  cardExp: string | null;
  plan: string | null;
  status: string | null;
  isDependent: boolean;
  paymentDate: string | null;
  paymentNote: string | null;
  sequenceNumber: number | null;
  collectionMethod: string | null;
  invoiceNumber: string | null;
  paymentErrorMessage: string | null;
}

export interface memberBillingHistory {
  memberBillingHistory: memberBillingHistory_memberBillingHistory[] | null;
}

export interface memberBillingHistoryVariables {
  contactId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactSubscription
// ====================================================

export interface UpdateContactSubscription_updateContactSubscription {
  __typename: "ContactPlanSubscription";
  id: string;
  isCancelled: boolean;
  isEnabled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
}

export interface UpdateContactSubscription {
  updateContactSubscription: UpdateContactSubscription_updateContactSubscription | null;
}

export interface UpdateContactSubscriptionVariables {
  id: string;
  cancel?: boolean | null;
  reactivate?: boolean | null;
  accept?: boolean | null;
  isManual?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContactPlanSubscriptionsFull
// ====================================================

export interface GetContactPlanSubscriptionsFull_getContactPlanSubscriptions_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPrice: number | null;
  annualPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  status: PlanStatus;
  monthlyDependentPrice: number | null;
  annualDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface GetContactPlanSubscriptionsFull_getContactPlanSubscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  accountId: string;
  fee: number;
  startDate: any;
  endDate: any;
  planTerm: PlanTerm | null;
  status: PlanSubscriptionStatus;
  isCancelled: boolean;
  isUpComing: boolean;
  isCurrent: boolean;
  isArchived: boolean;
  plan: GetContactPlanSubscriptionsFull_getContactPlanSubscriptions_plan | null;
}

export interface GetContactPlanSubscriptionsFull {
  getContactPlanSubscriptions: GetContactPlanSubscriptionsFull_getContactPlanSubscriptions | null;
}

export interface GetContactPlanSubscriptionsFullVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactIdentity
// ====================================================

export interface UpdateContactIdentity {
  updateContactIdentity: string | null;
}

export interface UpdateContactIdentityVariables {
  currentEmail: string;
  newEmail: string;
  phone: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactPlanSubscriptionCard
// ====================================================

export interface UpdateContactPlanSubscriptionCard_updateContactPlanSubscriptionCard {
  __typename: "ContactPlanSubscription";
  id: string;
}

export interface UpdateContactPlanSubscriptionCard {
  updateContactPlanSubscriptionCard: UpdateContactPlanSubscriptionCard_updateContactPlanSubscriptionCard;
}

export interface UpdateContactPlanSubscriptionCardVariables {
  id: string;
  creditCard_token: string;
  creditCard_type: string;
  creditCard_desc: string;
  creditCard_lastfour: string;
  creditCard_exp: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeContactPlanSubscriptionPlan
// ====================================================

export interface changeContactPlanSubscriptionPlan_changeContactPlanSubscriptionPlan_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPrice: number | null;
  annualPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  status: PlanStatus;
  monthlyDependentPrice: number | null;
  annualDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface changeContactPlanSubscriptionPlan_changeContactPlanSubscriptionPlan {
  __typename: "ContactPlanSubscription";
  id: string;
  accountId: string;
  fee: number;
  plan: changeContactPlanSubscriptionPlan_changeContactPlanSubscriptionPlan_plan | null;
}

export interface changeContactPlanSubscriptionPlan {
  changeContactPlanSubscriptionPlan: changeContactPlanSubscriptionPlan_changeContactPlanSubscriptionPlan;
}

export interface changeContactPlanSubscriptionPlanVariables {
  id: string;
  planId: string;
  planTerm: PlanTerm;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: renewContactPlanSubscription
// ====================================================

export interface renewContactPlanSubscription_renewContactPlanSubscription_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPrice: number | null;
  annualPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  status: PlanStatus;
  monthlyDependentPrice: number | null;
  annualDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface renewContactPlanSubscription_renewContactPlanSubscription {
  __typename: "ContactPlanSubscription";
  id: string;
  accountId: string;
  fee: number;
  plan: renewContactPlanSubscription_renewContactPlanSubscription_plan | null;
}

export interface renewContactPlanSubscription {
  renewContactPlanSubscription: renewContactPlanSubscription_renewContactPlanSubscription;
}

export interface renewContactPlanSubscriptionVariables {
  id: string;
  planId: string;
  planTerm: PlanTerm;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cloneContactPlanSubscription
// ====================================================

export interface cloneContactPlanSubscription_cloneContactPlanSubscription_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPrice: number | null;
  annualPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  status: PlanStatus;
  monthlyDependentPrice: number | null;
  annualDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface cloneContactPlanSubscription_cloneContactPlanSubscription {
  __typename: "ContactPlanSubscription";
  id: string;
  accountId: string;
  fee: number;
  plan: cloneContactPlanSubscription_cloneContactPlanSubscription_plan | null;
}

export interface cloneContactPlanSubscription {
  cloneContactPlanSubscription: cloneContactPlanSubscription_cloneContactPlanSubscription;
}

export interface cloneContactPlanSubscriptionVariables {
  id: string;
  planId: string;
  planTerm: PlanTerm;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateStartEndDate
// ====================================================

export interface updateStartEndDate_updateStartEndDate {
  __typename: "ContactPlanSubscription";
  accountId: string;
  autoRenewal: boolean;
  contactId: string;
  createdAt: any;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  endDate: any;
  fee: number;
  id: string;
  isCancelled: boolean;
  isEnabled: boolean;
  lastPaymentDate: any | null;
  lastUpdated: any;
  nextIteration: number;
  nextPaymentDate: any | null;
  planId: string | null;
  startDate: any;
  totalCollected: number;
}

export interface updateStartEndDate {
  updateStartEndDate: updateStartEndDate_updateStartEndDate;
}

export interface updateStartEndDateVariables {
  endDate?: any | null;
  id?: string | null;
  startDate?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactPaymentEvent
// ====================================================

export interface CreateContactPaymentEvent_createContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
}

export interface CreateContactPaymentEvent {
  createContactPaymentEvent: CreateContactPaymentEvent_createContactPaymentEvent;
}

export interface CreateContactPaymentEventVariables {
  contactId: string;
  subscriptionId: string;
  creditCard_lastfour?: string | null;
  creditCard_transStatus?: string | null;
  paymentAmount: number;
  creditCard_type?: string | null;
  type: paymentType;
  paymentStatus: paymentStatus;
  creditCard_message?: string | null;
  creditCard_exp?: string | null;
  paymentDate: string;
  paymentNote?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountCategory {
  ADJUNCTIVE_GENERAL_SERVICES = "ADJUNCTIVE_GENERAL_SERVICES",
  DIAGNOSTIC = "DIAGNOSTIC",
  ENDODONTICS = "ENDODONTICS",
  IMPLANT_SERVICES = "IMPLANT_SERVICES",
  MAXILLOFACIAL_PROSTHETICS = "MAXILLOFACIAL_PROSTHETICS",
  ORAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_AND_MAXILLOFACIAL_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  PERIODONTICS = "PERIODONTICS",
  PREVENTIVE = "PREVENTIVE",
  PROSTHODONTICS_FIXED = "PROSTHODONTICS_FIXED",
  PROSTHODONTICS_REMOVABLE = "PROSTHODONTICS_REMOVABLE",
  RESTORATIVE = "RESTORATIVE",
}

export enum ItemType {
  CreditLine = "CreditLine",
  DebitLine = "DebitLine",
}

export enum PaymentStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REFUND_COMPLETED = "REFUND_COMPLETED",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REQUESTED = "REQUESTED",
  VOIDED = "VOIDED",
}

export enum PlanStatus {
  DRAFT = "DRAFT",
  LEGACY = "LEGACY",
  LIVE = "LIVE",
  TEMPLATE = "TEMPLATE",
}

export enum PlanSubscriptionStatus {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CANCEL_PENDING = "CANCEL_PENDING",
  EXPIRED = "EXPIRED",
  LEGACY = "LEGACY",
  MANUAL = "MANUAL",
  PENDING = "PENDING",
  PROPOSED = "PROPOSED",
  SUSPENDED = "SUSPENDED",
}

export enum PlanTerm {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum State {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UM = "UM",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum collectingMethod {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum contactPaymentAuditLogType {
  CANCEL = "CANCEL",
  REVERT = "REVERT",
  UPDATE = "UPDATE",
  VOID = "VOID",
}

export enum paymentStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REFUND_COMPLETED = "REFUND_COMPLETED",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REQUESTED = "REQUESTED",
  VOIDED = "VOIDED",
}

export enum paymentType {
  ADDITIONAL_FEE = "ADDITIONAL_FEE",
  OTHER = "OTHER",
  SUBS = "SUBS",
  VENDOR_FEE = "VENDOR_FEE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
