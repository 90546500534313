
import React from 'react'

import { Pane, Text } from 'evergreen-ui'

const ServiceContract = () => {

    return (
        <Pane>
            <Text>
                <p>
                    This TRUBLU DIRECT, LLC Services Agreement (“Agreement”), effective as of the date executed by Member (“Effective Date”), is by and between TruBlu Direct, LLC, a Delaware corporation, with an address for notice at 7804-C Fairview Road, Suite 190, Charlotte, North Carolina 28226 (“TruBlu”) and Member described in Exhibit “A” hereto (“Member”).  TruBlu and Member are also referred to herein each as a “Party” and collectively as the “Parties.”
                </p>
                <p>
                    RECITALS
                </p>
                <p>
                    WHEREAS, TruBlu operates a membership program (the “Program”) that provides independent dentists with access to industry leading benefits and support in partnership with dentistry’s leading providers (“Vendors”) of products, services, and solutions; and
                </p>
                <p>
                    WHEREAS, Member desires to participate in the Program under the terms and conditions set forth herein; and
                </p>
                <p>
                    NOW, THEREFORE, in consideration of the promises of the Parties contained herein, it is hereby agreed as follows:
                </p>
                <ol>
                    <li><b>Program Description</b>
                        <br />
                        <br />
                        Member hereby requests and TruBlu agrees that Member shall be a participant in the TruBlu Program subject to all the provisions and requirements as set forth herein.  TruBlu secures distinctive benefits and solutions for Members.  A list of Vendors and their offering under the TruBlu Program (“Vendor List”) shall be electronically provided by TruBlu.  The parties acknowledge that this Vendor List will be updated periodically as the TruBlu Program continuously evolves.
                    </li>
                    <br />
                    <li> <b>Necessary Information & Indemnification</b>
                        <br />
                        <br />
                        TruBlu will submit to the Vendors all relevant information received from Member to establish Member’s entitlement to the pricing benefits provided under the Program by the Vendor, but TruBlu does not guarantee Member’s acceptance by any Vendor. Member agrees to defend, indemnify, and hold TruBlu harmless as a result of any purchases made by Member from any Vendor; and/or Member’s failure to obtain/receive any such purchases or to obtain/receive any Program prices, or any discounted pricing or rebates; and/or Member’s receiving any mislabeled, mis-formulated, defective or otherwise non-conforming products or services purchased from any Vendor; and/or any and all other disputes between Member and a Vendor.
                    </li>
                    <br />
                    <li>
                        <b>TruBlu’s Responsibilities & Limitations</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li>    It is understood by Member that the intent of this Program, and Member's participation therein, is for TruBlu to obtain distinctive member benefits and solutions for Members.  TruBlu has no other obligations, responsibilities, nor any liabilities to Member except as provided in this Agreement.
                            </li>
                            <br />
                            <li>All transactions by Members for the purchase of products, services, and solutions from Vendors shall be conducted by Members through telephone or internet communications (e.g. on Vendor websites) directly by the Member with the Vendor.  TruBlu shall not have any involvement in such transactions and any communications regarding them.  Contact information for all Vendors shall be included on the TruBlu Vendor List which shall be electronically provided by TruBlu to Members, though a secure portal. This information will be periodically updated by TruBlu.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>Member’s Responsibilities</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li><u>Reports to TruBlu.</u> Member agrees that, upon reasonable request, it shall provide TruBlu with information and documentation, as requested by TruBlu, regarding Member’s purchases of products and services from Vendors under this Agreement, for TruBlu to verify the volume of business transacted by Member with Vendors pursuant to this Agreement and the Program
                            </li>
                            <br />
                            <li><u>Non-Exclusive Agreement.</u> Nothing in this Agreement shall require Member to purchase products, services, and solutions exclusively from Vendors on the TruBlu Vendor List to the exclusion of any other vendor of the same products, services, and solutions which are not on the TruBlu Vendor List.  Nothing in this Agreement shall require a Member to participate only in this TruBlu Program for purposes of purchasing products, services, and solutions from vendors.
                            </li>
                            <br />
                            <li><u>Compliance with Other TruBlu Agreements.</u>   To the extent Member participates in any other TruBlu programs or contracts, Member must comply with all terms and conditions of them.  Member’s material breach of any such other programs or contracts may result in Member’s termination from the TruBlu Program with cause.
                            </li>
                            <br />
                            <li> <u>Compliance with Laws and Regulations.</u> Member must comply with all Federal and State laws and regulations applicable to its profession and business (including, but not limited to, the Centers for Medicare and Medicaid, FDA rules and regulations, DEA rules and regulations, State Dental Licensing Boards, and all other applicable rules and regulations for each State in which Member provides dental services).  Member must maintain any and all required professional licenses/registrations, certificates and permits in all States in which Member provides dental services.  It is the responsibility of Member to abide by all Federal and State laws, as well as professional guidelines and standards in the provision of its dental services.
                            </li>
                            <br />
                            <li><u>Own Use.</u> Member expressly understands and agrees that each and every purchase made through the TruBlu Program is for Member’s ultimate consumption or Own Use (“Own Use”) as defined by the U.S. Supreme Court in Abbott Labs, et al. v. Portland Retail Druggist Association, Inc., (425 U.S. 1, 1976) and its successor line of cases and the Prescription Drug Marketing Act of 1987.  Member agrees to indemnify and hold TruBlu harmless from any liability including, but not limited to cost of litigation resulting from any breach by Member of this Own Use requirement.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>TruBlu Fees</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li><u>Payments to TruBlu.</u> Member acknowledges that TruBlu may receive payment of fees for administrative and other services provided by TruBlu from one or more Vendors based on the products, services, and solutions purchased, licensed or leased by Member (“TruBlu Fees”). The fees received by TruBlu are utilized to administer, market, and support the TruBlu Program with Members and Vendors.
                            </li>
                            <br />
                            <li><u>Compliance with Law.</u> Member shall, and shall cause its members, managers, directors, officers, employees, independent contractors, representatives and agents to conduct themselves in connection with this Agreement, including, without limitation, the use of the products, services, and solutions purchased pursuant hereto, in compliance with all applicable Federal, State and local laws, rules and regulations.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>Membership Dues</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li>The monthly membership dues (“Membership Dues”) to be a member of the TruBlu Program are listed in Exhibit A, which will be automatically charged by TruBlu electronically to the Member’s credit card on file with TruBlu.  The charge will be made by TruBlu on the first day of the month for that month.  A Member which has more than one practice location must establish a separate membership account for each practice location and each location is charged the monthly membership dues.  All Membership Dues payments are non-refundable.

                            </li>
                            <br />
                            <li>TruBlu reserves the right to increase the monthly membership dues upon thirty (30) days written advanced notice to the Member.  The Member will have the option to accept the dues increase by clicking “accept” on the TruBlu marketplace.  By clicking “accept,” the Member is authorizing TruBlu to automatically charge the Member’s credit card the increased membership dues.  Such “acceptance” must be made within the thirty (30) day notice period.</li>
                            <br />
                            <li>If a Member cancels its credit card authorization for TruBlu’s charging of the monthly Membership Dues to the card, or the Member does not accept any dues increase within the thirty (30) day notice period, then this Agreement, and the Member’s access to the Vendors through this TruBlu Program, shall immediately and automatically terminate; TruBlu shall notify the Vendors of such termination; and the provisions of Section 10, Term & Termination, shall apply to such termination.</li>

                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>Confidential Information</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li><u>Confidential Information</u> For purposes of this Agreement, “Confidential Information” means any non-public information, materials or items disclosed by either Party to the other Party or by behalf of either Party, including, but not limited to, this Agreement, any rates, fees, rebates, commissions or other amounts thereunder, the Vendor Agreements, the Vendor Lists or any vendor information, the Member lists or any Member information,  all business plans, trade secrets, intellectual property, patents, trademarks, copyrights, know-how, data, inventions, models and strategies, developments, concepts, processes, technical or engineering developments, marketing plans, contracts, leases, agreements, pricing and cost information, financial statements, balance sheets, tax records, third-party payor and other fee schedule information, claims, settlements, pending litigation of any nature, personnel history, referral sources, and any other information concerning the business or affairs of the Parties.  All information, whether transmitted by oral, written, electronic or any other means shall be protected Confidential Information under this Agreement.  Notwithstanding anything herein to the contrary, “Confidential Information” shall not include any information that a Party can show: (i) was rightfully known by or in the lawful possession of the Party at or prior to the time of disclosure by the other Party; (ii) is lawfully known or becomes publicly lawfully available other than as a result of a breach of this Agreement; (iii) was independently developed by a Party other than as a result of a breach of this Agreement; or (iv) was lawfully received by a Party from a third party without, to the other Party’s knowledge, a duty of confidentiality.</li>
                            <br />
                            <li><u> Title to Confidential Information.</u> The Parties acknowledges and agrees that all Confidential Information disclosed hereunder by a Party shall remain the sole and exclusive property of that Party.  This Agreement shall not confer upon a Party, or be a basis for implying, any license, interest or rights of any kind in or to the Confidential Information of the other Party.
                            </li>
                            <br />
                            <li><u>Representatives. </u> This Agreement shall apply to the Parties and all their officers, directors, employees, affiliates, agents and representatives (including, but not limited to, financial advisors, attorneys and accountants) who may have access to the Confidential Information (collectively, “Representatives”).  Notwithstanding anything herein to the contrary, the Parties acknowledge and agree that a Party shall be permitted to disclose the Confidential Information to its Representatives who: (i) need to know such Confidential Information for the purpose of complying with this Agreement; (ii) need to know such Confidential Information for purposes of Member participation in any other programs offered by TruBlu’s affiliates, and (iii) are informed of the terms and conditions of this Agreement and agree to comply with all its confidentiality obligations, as if such Representatives had been a party to this Agreement.  A breach of this Agreement by any such Representative shall be deemed to be a breach of this Agreement by the Party disclosing the information.</li>
                            <br />
                            <li><u>Non-Disclosure and Use; Notification.</u> TThe Parties shall and shall cause their Representatives to: (i) hold in strict confidence and safeguard the Parties’ Confidential Information, (ii) not disclose, disseminate or make available to any third party a Party’s Confidential Information without that Party’s prior written consent in each instance, and (iii) not use, copy or otherwise benefit from a Party’s Confidential Information without that Party’s prior written consent in each instance.  The Parties agree to promptly notify each other in writing of any misuse or misappropriation of a Party’s Confidential Information which may become known to a Party.</li>
                            <br />
                            <li><u>Disclosures Required by Law.  </u>If a Party becomes legally obligated to disclose any Confidential Information, that Party shall provide the other Party with prompt written notice so that Party may seek a protective order or other appropriate remedy.  If such protective order or other remedy is not obtained, or a Party waives compliance with the provisions of this Agreement, the other Party shall furnish only that portion of the Confidential Information which the Party is legally required to disclose.</li>
                            <br />
                            <li><u>Enforcement. </u> In light of the sensitive and proprietary nature of the Confidential Information protected hereunder, the Parties acknowledge that they shall be entitled to enforce this Agreement by seeking an injunction to enjoin and restrain the unauthorized disclosure or use of its Confidential Information.  Nothing herein shall be construed as limiting a Party’s right to seek any other remedies available for a Party’s breach or threatened breach of this Agreement, including, but not limited to, monetary damages.  In the event a Party initiates a legal proceeding to enforce its confidentiality rights under this Agreement and that Party prevails in any enforcement action, then the breaching Party shall be responsible for reimbursing the enforcing Party for all costs and expenses incurred in such enforcement action, including, without limitation, reasonable attorneys’ and experts’ fees and court costs.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>HIPAA & Other Privacy Compliance</b>
                        <br />
                        <br />
                        Member acknowledges that under this Agreement it shall not be disclosing to TruBlu any Protected Health Information as this term is defined by the Health Insurance Portability and Accountability Act of 1996, as set forth at 42 U.S.C. Section 1320d et seq. (“HIPAA”) and the Standards for Privacy of Individually Identifiable Health Information (the “Privacy Regulation”) published by the U.S. Department of Health and Human Services at 45 C.F.R. part 160, 162 and 164, subpart E and the Health Information Technology for Economic and Clinical Health Act (“HITECH”).
                        <br />
                        <br />
                        Member further acknowledges that under this Agreement it shall not be disclosing any Personally Identifiable Information as this term is defined by various Federal and State laws.
                    </li>
                    <br />
                    <li>
                        <b>General</b>
                        <br />
                        <br />
                        <ol type="a">
                            <li><u>Independent Parties. </u> Member, TruBlu and each Vendor with which TruBlu contracts are independent contractors in relation to each other. Member is solely responsible for operating and maintaining its dental practice and for providing dental services to its patients. All decisions regarding which types of products and services to purchase, from which manufacturer and vendor, and in what amounts, are made solely by Member and its personnel and not by TruBlu. </li>
                            <br />
                            <li><u>NO WARRANTY BY TRUBLU; LIMITATIONS ON DAMAGES.  </u>TRUBLU DOES NOT MAKE AND EXPRESSLY DISCLAIMS, ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY OTHER WARRANTY, EXPRESS OR IMPLIED, AS TO ANY PRODUCTS, SERVICES, AND SOLUTIONS SOLD BY ANY VENDOR; AND MEMBER HEREBY EXPRESSLY RELEASES TRUBLU AND ITS AFFILIATES FROM ANY AND ALL LIABILITY AND CLAIMS RELATING TO THE PRODUCTS, SERVICES AND SOLUTIONS, AND ANY BREACH OR ALLEGED BREACH OF WARRANTY IN CONNECTION WITH THE PRODUCTS, SERVICES AND SOLUTIONS.  IN NO EVENT SHALL TRUBLU BE LIABLE TO MEMBER FOR ANY DIRECT OR INDIRECT DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS OR INCIDENTAL, INDIRECT, COMPENSATORY, PUNITIIVES, LIQUIDATED, EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES, ARISING OUT OF THIS AGREEMENT OR ANY VENDOR AGREEMENT, EVEN IF TRUBLU HAS BEEN ADVISED OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE), CONTRACT, OR OTHER LEGAL THEORY.</li>
                            <br />
                            <li><u>Adverse Actions. </u> Member will comply with all federal and state laws and must immediately notify TruBlu if any license, certification, accreditation, permit or any other required governmental approvals for the operation of its practice are voluntarily or involuntarily surrendered, revoked, limited, suspended or otherwise adversely affected, or if any other restriction is imposed which may affect Member’s ability to meet its obligations or perform its duties or operate its practice.</li>
                            <br />
                            <li><u>Indemnification.  </u> During the term of this Agreement and at all times thereafter, Member shall, at Member’s sole expense, indemnify, defend and hold TruBlu and its members, managers, directors, officers, employees, contractors, owners, agents, and affiliates (collectively “Indemnified Party” or “Indemnified Parties”) harmless from and against all claims, actions, liabilities, losses, expenses, damages, judgments and costs (including without limitation reasonable legal expenses and attorneys’ fees) (collectively, “Losses”) that may at any time be incurred by TruBlu and that directly or indirectly arise out of, are related to or are connected-with, in whole or in part: (i) any alleged disputes or any alleged acts or omissions including, but not limited to, any alleged acts of professional malpractice; (ii) or alleged negligence or willful misconduct;  (iii) or alleged breach of this Agreement by Member, its members, owners, managers, directors, employees, officers, agents, contractors, or representatives; (iv) or any claims resulting from a failure of Member to pay for any products or services purchased by Member from any Vendor; or (v) any disputes of any nature or kind between Member or any Vendor, including, but not limited to, any disputes over any products, services or solutions provided or not provided by any Vender to Member.</li>
                            <br />
                            <li><u>Severability.  </u>The provisions of this Agreement shall be deemed severable, and the invalidity or unenforceability of any one or more of the provisions of this Agreement shall not affect the validity and enforceability of the other provisions.</li>
                            <br />
                            <li><u>Waiver of Breach.  </u> The waiver by TruBlu of a breach of any provision of this Agreement shall not operate or be construed as a waiver of any subsequent breach thereof.</li>
                            <br />
                            <li><u>Governing Law; Venue.  </u> The construction, interpretation and performance of this Agreement, and any and all other dealings or relationships between the Parties, shall be governed by the laws of the State of New Jersey, without regard to its conflict of laws rule.  Any controversy or claim arising out of, relating to or connected with, either directly or indirectly, and either in whole or in part, this Agreement, or the breach thereof, or any other business dealings or relationships between the Parties, shall be settled by litigation in the State or Federal courts of the State of New Jersey located in Essex County, New Jersey, before a judge sitting and hearing the matter without a jury.</li>
                            <br />
                            <li><u>WAIVER OF JURY TRIAL. </u> EACH OF THE PARTIES HERETO KNOWINGLY, VOLUNTARILY, AND IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY OR ANY OTHER BUSINESS DEALINGS OR RELATIONSHIPS BETWEEN THE PARTIES.</li>
                            <br />
                            <li><u>Remedies.  </u> Member acknowledges that the injury that would be suffered by TruBlu as a result of a breach of the provisions of this Agreement by Member or by Member’s members, managers, directors, officers, agents, representatives, servants, employees, independent contractors and/or any and all persons directly or indirectly acting for or on behalf of Member would be irreparable and that an award of monetary damages to TruBlu for such a breach would be an inadequate remedy.  Consequently, TruBlu will, at any time, have the right, in addition to any other rights it may have, to obtain injunctive relief to restrain any breach or threatened breach or otherwise to specifically enforce any provision of this Agreement, and TruBlu will not be obligated to post bond or other security in seeking such relief.  Any and all of TruBlu’s remedies for the breach of this Agreement shall be cumulative and the pursuit of one remedy shall not be deemed to exclude any and all other remedies with respect to the subject matter of this Agreement.</li>
                            <br />
                            <li><u>Limitation of Damages. </u> In any legal proceedings by Member against TruBlu which arises out of, is connected-with, or is related- to this Agreement or the transactions contemplated hereby or any other business dealings or relationships between the Parties, Member’s recovery of any monetary damages or other monetary remedy against TruBlu shall be limited to the total Membership Dues which Member has paid to TruBlu. </li>
                            <br />
                            <li><u>Notice.  </u> Any notice required to be given shall be sufficient if it is in writing and sent by certified mail or registered mail, return receipt requested, first class postage prepaid, to the appropriate address listed on the signature page hereto.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <b>Term & Termination</b>
                        <br />
                        <br />
                        The term of this Agreement begins on the Effective Date and automatically renews on a monthly basis unless terminated by either Party at any time.  Member may terminate this Agreement at any time by clicking the “Terminate My Agreement” button, provided however, that Member shall remain liable for all Member Fees due as of the termination date, as outlined in Exhibit A.
                        <br />
                        In addition to any other express termination right set forth in this agreement
                        <br />
                        <ol type="a">
                            <li>TruBlu may suspend or terminate this Agreement at its sole option and without cause effective immediately upon electronic written notice to Member if Member: (i) fails to pay any amount when due hereunder, and such failure continues more than ten (10) days after TruBlu’s delivery of electronic written notice thereof or (ii) breaches any of its obligations under this Agreement, and such failure continues more than ten (10) days after TruBlu’s delivery of electronic written notice thereof to the Member.</li>
                            <br />
                            <li>TruBlu may terminate this Agreement immediately if Member: (i) violates any Federal, State or local law applicable to Member’s business or to this Agreement; (ii) commits an act or omission that gives a Vendor the right to immediately terminate a Vendor Agreement; or (iii) breaches this Agreement and such breach is not curable in the reasonable determination of TruBlu.</li>
                            <br />
                            <li>  Upon any termination of this Agreement, TruBlu will notify all Vendors of such termination and that the terminated Member is no longer entitled to participate in the TruBlu Program as noted in the Vendor List.</li>
                            <br />
                            <li>Upon expiration or termination of this Agreement for any reason, Member will promptly discontinue use of TruBlu’s name, signs, symbols and marks, and will no longer be able to participate in or purchase products, services, and solutions under the TruBlu Vendor List. Member will immediately destroy all TruBlu Confidential Information, including any copies, will not retain any such Information, and will not share it with any third party. </li>
                        </ol>
                    </li>
                    <br />
                    <br />
                    <br />
                </ol>
                <p>
                    IN WITNESS WHEREOF, the parties have hereunto executed this Agreement on the Effective Date.
                </p>
            </Text>
        </Pane>
    )
}

export default ServiceContract
