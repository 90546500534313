import React, { useState } from 'react'
import styled from 'styled-components/macro'
import * as Types from 'types/graphql'
import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster, Switch, Checkbox, Tablist } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError, Tab } from '@pearly/lib'
import { useGlobal } from 'components/global-provider'
import UserDetails from 'components/user-profile'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id: string
    firstName: string
    lastName: string
    google: { email: string; role: Types.UserRole; verified: boolean }
    newsletterSubscription: boolean
  },
  initialPath?: 'profile' | 'email-Report'
}

const steps = [
  { path: 'profile' as const, component: UserDetails.UserDetails },
  { path: 'email-Report' as const, component: UserDetails.EmailReport }
]

const UserSheet = ({ isShown, setIsShown, user, initialPath = 'profile' }: Props) => {
  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM

  const [currentPath, setCurrentPath] = useState(initialPath)
  const CurrentView = steps.find(step => step.path === currentPath)!.component

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <SheetLayout>
        <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
          {user ? (
            <>
              <CardHeader gridArea="header">
                <Avatar name={`${user.firstName} ${user.lastName}`} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user.firstName} {user.lastName}
                  </Heading>
                  <Text size={400}>User Details</Text>
                </Pane>
              </CardHeader>
              {isSystemAccount &&
                <Pane display="flex" padding={8}>
                  <Tablist>
                    {steps.map(step => {
                      const { path } = step
                      return (
                        <Tab
                          key={path}
                          textTransform="uppercase"
                          isSelected={currentPath === path}
                          onSelect={() => setCurrentPath(path)}
                        >
                          {path}
                        </Tab>
                      )
                    })}
                  </Tablist>
                </Pane>
              }
            </>
          ) : (
            <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
              <Heading size={600}>Add User</Heading>
              <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph>
            </CardHeader>
          )}
        </CardHeader>

        <Pane gridArea="body" overflow="hidden">
          <CurrentView isShown={isShown} setIsShown={setIsShown} user={user} />
        </Pane>
      </SheetLayout>
    </SideSheet>
  )
}

export default UserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
