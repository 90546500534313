import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, FormError, Icon, Spinner } from "@pearly/lib";
import { useModal } from "components/modal-provider";
import { Avatar, Heading, Pane, SideSheet, Text, toaster } from "evergreen-ui";
import { Form, Formik } from "formik";
import styled from 'styled-components/macro';
import * as Types from 'types/graphql'
import * as Yup from 'yup'
import PDExistUserFields from "components/_fields/PDExistUser-fields";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Existing_PDAccount, UPDATE_PDACCOUNT } from "graphql/_pd-user-sheet";
import { GET_MY_PD_ACCOUNT } from "graphql/_pd-user";
import { GET_VENDORS, GET_VENDOR_BY_MAPPED } from "graphql/_vendor-table";
import { Checkbox } from 'evergreen-ui';
import pdfImg from "../../assets/img/pdf.png";


export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id: string,
    TBCustomerNumber: string,
    PDAccountNumber: string | null,
    PDAccountNumberReceived: string | null,
    NPI: string | null,
    PDAccountName: string | null,
    billingAddress: string | null,
    billingCity: string | null,
    billingState: Types.State | null,
    billingZip: number | null,
    PDPrimaryContactName: string | null,
    PDprimaryContactNumber: string | null
  }
}

const PattersonTerms = require('assets/download/Patterson_T&C.pdf')

const PDExistUserSheet = ({ isShown, setIsShown, user }: Props) => {
  const showConfirmDialog = useModal("confirm");

  const [readTerms, setReadTerms] = useState(false)
  const [readTermsError, setReadTermsError] = useState('')
  const [vendorMapped, setVendorMapped] = useState<Types.GetVendors_vendors>();

  const { loading, error, data: vendors } = useQuery<Types.GetVendors>(GET_VENDORS)

  useEffect(() => {
    if (vendors) {
      const vendorsMapped = vendors?.vendors.find((item: Types.GetVendors_vendors) => item.vendorMapped === "Patterson_Dental")
      if (vendorsMapped) {
        setVendorMapped(vendorsMapped)
      }
    }
  }, [vendors])


  const [addExistingPDAcc, addExistingStatus] = useMutation<Types.existingPDAccount, Types.existingPDAccountVariables>(Existing_PDAccount, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyPDAccount>({ query: GET_MY_PD_ACCOUNT })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_PD_ACCOUNT,
          data: { myPDAccount: data?.existingPDAccount }
        })
      }
    }
  });

  useEffect(() => {
    if (addExistingStatus.error) toaster.danger('Unable to add account details')
    else if (addExistingStatus.data && !addExistingStatus.loading) {
      setIsShown(false)
      toaster.success(`Account details added successfully!`)
      toaster.success(`Please allow 5 to 10 business days for your Patterson account to be fully updated.`)
    }
  }, [addExistingStatus, setIsShown])

  
  const [updatePDAcc, updateStatus] = useMutation<Types.updatePDAccount, Types.updatePDAccountVariables>(UPDATE_PDACCOUNT, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetMyPDAccount>({ query: GET_MY_PD_ACCOUNT })
      console.log("cachedData cachedData", cachedData);

      if (data && cachedData) {
        cache.writeQuery({
          query: GET_MY_PD_ACCOUNT,
          data: { myPDAccount: data?.updatePDAccount }
        })
      }
    }
  });

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update account details')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Account details successfully updated!`)
    }
  }, [updateStatus, setIsShown])


  useEffect(() => {
    if (readTerms) {
      if (readTermsError) {
        setReadTermsError('')
      }
    }
  }, [readTerms])

  useEffect(() => {
    console.log('vendor', vendorMapped)
  }, [vendorMapped])

  useEffect(() => {
    if (user) {
      setReadTerms(true)
    }
  }, [user])


  return (
    <>
      <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick= {false}>
        {loading ?
          <Spinner providerView />
          :
          <Formik
            initialValues={
              user
                ? user
                : {
                  PDAccountNumber: '',
                  PDAccountNumberReceived: '',
                  NPI: '',
                  PDAccountName: '',
                  billingAddress: '',
                  billingCity: '',
                  billingState: '',
                  billingZip: '',
                  PDPrimaryContactName: '',
                  PDprimaryContactNumber: '',
                }
            }
            onSubmit={
              ({ ...userFields }) => {
                if (readTerms) {

                  showConfirmDialog({
                    body: `Do you accept the terms and conditions?`,
                    confirm: () => {
                      if (user) {
                        updatePDAcc({
                          variables: {
                            id: user?.id,
                            PDAccountNumber: userFields.PDAccountNumber?.trim(),
                            PDAccountNumberReceived: userFields.PDAccountNumberReceived?.trim(),
                            NPI: userFields.NPI,
                            PDAccountName: userFields.PDAccountName,
                            billingAddress: userFields.billingAddress,
                            billingCity: userFields.billingCity,
                            billingState: userFields.billingState as Types.State,
                            billingZip: Number(userFields.billingZip),
                            PDPrimaryContactName: userFields.PDPrimaryContactName,
                            PDprimaryContactNumber: userFields.PDprimaryContactNumber,
                            vendorId: vendorMapped?.id ?? ''
                          }
                        })
                      } else {
                        addExistingPDAcc({
                          variables: {
                            PDAccountNumber: userFields.PDAccountNumber?.trim(),
                            NPI: userFields.NPI,
                            PDAccountName: userFields.PDAccountName,
                            billingAddress: userFields.billingAddress,
                            billingCity: userFields.billingCity,
                            billingState: userFields.billingState as Types.State,
                            billingZip: Number(userFields.billingZip),
                            PDPrimaryContactName: userFields.PDPrimaryContactName,
                            PDprimaryContactNumber: userFields.PDprimaryContactNumber,
                            vendorId: vendorMapped?.id ?? '',
                            fee: vendorMapped?.pricing ?? 0
                          }
                        })
                      }
                    }
                  })

                } else {
                  if (readTermsError === '') {
                    setReadTermsError("Please read the Patterson Dental Terms and Conditions and click on the check box")
                  }
                }
              }}
            validationSchema={Yup.object().shape({
              PDAccountNumber: Yup.string(),
              PDAccountNumberReceived: Yup.mixed(),
              PDAccountName: Yup.string().required("Account name is required"),
              billingAddress: Yup.string().required("Address is required"),
              billingCity: Yup.string().required("City is required"),
              NPI: Yup.string().required("NPI is required"),
              billingState: Yup.mixed().required("State is required")
                .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
              billingZip: Yup.number().required("Zip is required"),
              PDPrimaryContactName: Yup.string().required("Contact name is required"),
              PDprimaryContactNumber: Yup.string().required('Contact number is required')
                .matches(/^[0-9]\d{9}$/, 'Please provide the phone number in ten digit format.  For example 8007722160 not 800-772-2160'),
            })}
          >
            <Form style={{ height: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
              <SheetLayout>
                {user ? (
                  <CardHeader gridArea="header">
                    <Avatar name={"Patterson User"} size={40} />
                    <Pane marginLeft={16}>
                      <Heading size={600}>
                        Patterson User
                      </Heading>
                      <Text size={400}>PD Account Details</Text>
                    </Pane>
                  </CardHeader>
                ) : (
                  <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                    <Heading size={600}>Patterson customer</Heading>
                  </CardHeader>
                )}

                <Pane gridArea="body" overflow="scroll" background="blueTint">
                  <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                    <PDExistUserFields TBCustomerNumber={user?.TBCustomerNumber}/>
                    <Heading color={'#1070CA'} textTransform="uppercase" marginBottom={8}>
                      Patterson Addendum
                    </Heading>
                    <Pane>
                      <Text>
                        <ol type="1">
                          <li>
                            <p style={{ marginTop: '0px' }}>
                              Description of Services: TruBlu Direct - Patterson Program
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: '0px' }}>
                              Additional Patterson Program Member Fee: ${vendorMapped?.pricing}.00 per month
                            </p>
                          </li>
                          <li>
                            <p>
                              Renewal: Automatic monthly renewal unless terminated prior to the first of each calendar month
                            </p>
                          </li>
                          <li>
                            <p>
                              Patterson Terms and Conditions
                            </p>
                            {/* <div><Icon icon={['fad', 'file-plus']} /></div> */}
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                              <a href={PattersonTerms} target="_blank">
                              <img src={pdfImg} alt="" style={{height: '100px', width: '100px'}}/></a>
                              <small>Patterson Terms and Conditions.pdf</small>
                            </div>
                          </li>
                        </ol>
                        <Checkbox checked={readTerms} onChange={e => setReadTerms(e.target.checked)} label={<>I have read Patterson terms and conditions</>} name={""} />
                      </Text>
                    </Pane>
                    <FormError />
                    {readTermsError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{readTermsError}</Text></div>}
                  </Card>
                </Pane>
                <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
                  {user ? (
                    <Button
                      autoFocus
                      isLoading={updateStatus.loading || !!updateStatus.data}
                      type="submit"
                      appearance="primary"
                      height={48}
                      width="100%"
                      justifyContent="center"
                    >
                      Accept & Submit
                    </Button>
                  ) : (
                    <Button
                      isLoading={addExistingStatus.loading || !!addExistingStatus.data}
                      type="submit"
                      appearance="primary"
                      height={48}
                      width="100%"
                      justifyContent="center"
                      iconBefore={['fas', 'user-plus']}
                    >
                      Accept & Submit
                    </Button>
                  )}
                </Pane>
              </SheetLayout>
            </Form>
          </Formik>}
      </SideSheet>
    </>
  )
}

export default PDExistUserSheet;

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`